import React, { useEffect, useState } from "react"
import { Box, Button, Checkbox, Container, IconButton, Modal, Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder, dimtextcolor } from "../../../Utils/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountCircle, ArrowDownward, CheckCircle, CloudDownloadOutlined, Delete, ErrorOutline, FilterList, HttpsOutlined, KeyboardBackspace, NotificationsActive, People, PriceChange, PriceCheckOutlined, Report, Tune, Visibility, VisibilityOff } from "@mui/icons-material";
import { Customcheckbox, Customsectiontextinput, Customselectinput } from "../../../Components/textinputs.";
import Popupdialog from "../../../Components/popupcomponent";
import { Erroralert, Successalert } from "../../../Components/popups";
import Loadingspinner from "../../../Components/loading";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { areDatesEqual, convertTimestampToTime, displaydate, openurl, returnErrormessage } from "../../../Utils/functions";
import { Accountpreferencesurl, Changepasswordurl, Fetchpaymentplansurl, Getsubscriptionstatusurl, Initiatepaymenturl, Resetpassurl, Updateuserinfourl } from "../../../Utils/urls";
import { Billingsample, dummymanimage, subscriptionplans } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import { companysizes } from "../../../Utils/arrays";
import Editprofilephoto from "./editprofilephoto";
import { user_pic_placeholder } from "../../../Utils/defaults";
import Nodatacomponent from "../../../Components/nodatacomponent";
import errorlottie from "./../../../../assets/lotties/error.json"
import { Backgoundskeleton } from "../../../Components/skeletons";
import Successspinner from "../../../Components/successspinner";
import Lottie from "lottie-react";
import UsersTeams from "./users-teams";
import { Redeemcouponmodal } from "../Modals/redeemcoupon";


const MyclientaccountSettings = ({ ondeleteaccount }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const pre_section = location.state?.section
    const subscription_upgrade = location.state?.subscription_upgrade
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()


    const [current, setcurrent] = useState(pre_section || 0);




    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Account Settings' }))
        }
    }, []);



    const activebox = {
        cursor: "pointer",
        backgroundColor: 'rgb(221, 220, 220)'
    }
    const inactivebox = {
        cursor: "pointer"
    }




    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Account</Typography>
            </Stack>
            <Stack mt={1} flexDirection={"row"} width={"100%"} justifyContent={"space-between"} >
                <Box width={{ xs: "20%", lg: "30%" }} height={"80vh"} position={"relative"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack onClick={() => setcurrent(0)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 0 ? activebox : inactivebox}>
                        <IconButton size="medium"><AccountCircle /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>My Profile</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(1)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 1 ? activebox : inactivebox}>
                        <IconButton size="medium"><HttpsOutlined /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Security</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(2)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 2 ? activebox : inactivebox}>
                        <IconButton size="medium"><NotificationsActive /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Notifications</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(3)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 3 ? activebox : inactivebox}>
                        <IconButton size="medium"><Tune /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Preferences</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(4)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 4 ? activebox : inactivebox}>
                        <IconButton size="medium"><PriceChange /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Billing</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(5)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 5 ? activebox : inactivebox}>
                        <IconButton size="medium"><People /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Users & Teams</Typography>
                    </Stack>
                    <Stack bottom={20} onClick={() => ondeleteaccount()} position={"absolute"} flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }}>
                        <IconButton color="error" size="medium"><Delete /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} color={'error'} fontFamily={"boldfont"}>Delete Account</Typography>
                    </Stack>
                </Box>
                <Box width={{ xs: "78%", lg: "68%" }} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    {current === 0 && <Myprofile />}
                    {current === 1 && <Passwords />}
                    {current === 2 && <Notificationsbox />}
                    {current === 3 && <Preferences />}
                    {current === 4 && <Billingbox subscription_upgrade={subscription_upgrade} />}
                    {current === 5 && <UsersTeams />}
                </Box>
            </Stack >
        </Box >
    )
}


const Myprofile = () => {
    const axiosInstance = useAuthAxios()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);

    const [email, setemail] = useState(user?.email || '');
    const [fullname, setfullname] = useState(user?.name || '');
    const [companyname, setcompanyname] = useState(user?.companyname || '');
    const [companysize, setcompanysize] = useState(user?.companysize || '');
    const [companyemail, setcompanyemail] = useState(user?.companyemail || '');
    const [jobtitle, setjobtitle] = useState(user?.jobtitle || '');

    //booleans
    const [showeditimage, setshoweditimage] = useState(false);
    const [confirmsave, setconfirmsave] = useState(false);


    const handleSubmit = () => {
        setconfirmsave(false)
        seterror(null)
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            email: email,
            name: fullname,
            companyname: companyname,
            companysize: companysize,
            companyemail: companyemail,
            jobtitle: jobtitle
        }).then(function (response) {
            setloading(false)
            if (response.data?.success) {
                setsuccess('Details updated successfully')
                setTimeout(() => {
                    setsuccess('')
                    dispatch(setUser({ ...user, email, name: fullname, companyname, companysize, companyemail, jobtitle }))
                }, 2000);
            }
        }).catch(function (error) {
            setloading(false)
            seterror(error || "there was an error try again later")
        });

    }


    const subscroll = {
        overflowX: "scroll",
        '::-webkit-scrollbar': {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }

    return (
        <Box>
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Details!!"}
                    question={"Are you sure you want to update your details?"}
                    successtext={"update"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            {showeditimage && <Editprofilephoto ondismiss={() => setshoweditimage(false)} />}
            <Typography mb={1}>My Profile</Typography>
            <hr />
            <Box>
                <Stack flexDirection={{ xs: "column", lg: "row" }} mt={2} alignItems={{ xs: "flex-start", lg: "center" }}>
                    <img src={user?.profile_picture_url || user_pic_placeholder} className="homeprofileimage" />
                    <Box ml={{ xs: 0, lg: 2 }} mt={{ xs: 2, lg: 0 }}>
                        <Stack flexDirection={"row"}>
                            <Button onClick={() => setshoweditimage(true)} sx={{ textTransform: "none" }} variant="outlined" color="secondary">Change Photo</Button>&nbsp;
                            <Button variant="outlined" sx={{ textTransform: "none" }} color="error">Remove</Button>
                        </Stack>
                        <Typography mt={1} color={dimtextcolor} fontSize={13} fontFamily={'boldfont'}>PNG, JPG or GIF (max 2mb)</Typography>
                    </Box>
                </Stack>

                <Box mt={2} onSubmit={(e) => {
                    e.preventDefault()
                    setconfirmsave(true)
                }} component={'form'} >
                    <Box sx={subscroll} height={'40vh'}>
                        <Customsectiontextinput
                            fieldname={'Name'}
                            width={"80%"}
                            value={fullname}
                            required={true}
                            onChange={setfullname}
                        />
                        <Customsectiontextinput
                            fieldname={'Email'}
                            width={"80%"}
                            type={'email'}
                            required={true}
                            autoComplete={'email'}
                            value={email}
                            onChange={setemail}
                        />
                        <Typography mt={2} fontSize={13}>Company Details</Typography>
                        <Box mt={1}>
                            <Customsectiontextinput
                                fieldname={'Company Name'}
                                width={"80%"}
                                autoComplete={"organization"}
                                required={true}
                                type={'text'}
                                value={companyname}
                                onChange={setcompanyname}
                            />
                            <Customsectiontextinput
                                fieldname={'Job Title'}
                                width={"80%"}
                                autoComplete={"organization-title"}
                                required={true}
                                type={'text'}
                                value={jobtitle}
                                onChange={setjobtitle}
                            />
                            <Customsectiontextinput
                                fieldname={'Company Email'}
                                width={"80%"}
                                required={true}
                                type={'email'}
                                autoComplete={'email'}
                                value={companyemail}
                                onChange={setcompanyemail}
                            />
                            <Customselectinput
                                fieldname={'Company Size'}
                                width={"80%"}
                                options={companysizes}
                                required={true}
                                value={companysize}
                                onChange={setcompanysize}
                            />
                        </Box><br />
                    </Box>
                    <Button type="submit" variant="contained">Save</Button>
                </Box>

            </Box>
        </Box>
    )
}


const Passwords = () => {
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);

    const [oldpass, setoldpass] = useState('');
    const [newpass, setnewpass] = useState('');
    const [confirmpass, setconfirmpass] = useState('');

    const [showpasswords, setshowpasswords] = useState(false);

    //modals
    const [confirmsave, setconfirmsave] = useState(false);

    const handleSubmit = () => {
        setconfirmsave(false)
        if (confirmpass !== newpass) {
            seterror('the two passwords do not match')
        }
        setloading(true)
        axiosInstance.post(Changepasswordurl, {
            current_password: oldpass,
            new_password: newpass,
            new_password_confirmation: newpass
        }).then(function (response) {
            setloading(false)
            if (response.data.success) {
                setsuccess('Password changed successfully')
                setTimeout(() => {//navigate user after 2 seconds of display
                    setsuccess('')
                    seterror('')
                    setoldpass('')
                    setnewpass('')
                    setconfirmpass('')
                }, 2000);
            }
        }).catch(function (error) {
            setloading(false)
            seterror(error)
        });

    }


    // Regex for password validation
    const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    // Check if new password meets the rules
    const isValidNewPass = passwordRules.test(newpass);
    const isValidConfirmPass = passwordRules.test(confirmpass);



    return (
        <Box>
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Details!!"}
                    question={"Are you sure you want to change your password?"}
                    successtext={"Change"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Typography mb={1}>Change Password</Typography>
            <hr />
            <Box mt={2} component={'form'} onSubmit={(e) => {
                e.preventDefault()
                setconfirmsave(true)
            }}>
                <Customsectiontextinput
                    fieldname={'Current Password'}
                    value={oldpass}
                    onChange={setoldpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />
                <Customsectiontextinput
                    fieldname={'New Password'}
                    value={newpass}
                    onChange={setnewpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                    helperText={!isValidNewPass && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                /><br />
                <Customsectiontextinput
                    fieldname={'Confirm Password'}
                    value={confirmpass}
                    onChange={setconfirmpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                    helperText={!isValidConfirmPass && newpass && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                />
                <Stack flexDirection={"row"} justifyContent={"right"}>
                    <Button type="submit" disabled={!oldpass || !isValidConfirmPass || !isValidNewPass} variant="contained">Save</Button>
                </Stack>
            </Box>
        </Box>
    )
}


const Notificationsbox = () => {

    const [newcandidatematches, setnewcandidatematches] = useState(false);
    const [testcompletion, settestcompletion] = useState(true);
    const [interviewacceptance, setinterviewacceptance] = useState(false);
    const [candidatedecline, setcandidatedecline] = useState(true);

    return (
        <Box>
            <Typography mb={1}>Notifications</Typography>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>Manage when you'll receive Notifications</Typography>
            <Box mt={2}>
                <Stack flexDirection={"row"}>
                    <Customcheckbox value={newcandidatematches} onChange={() => setnewcandidatematches(!newcandidatematches)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>New Candidate Matches</Typography>
                        <Typography>A new candidate matches with the role requirements</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={testcompletion} onChange={() => settestcompletion(!testcompletion)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Test Completion</Typography>
                        <Typography>Receive notification when a candidate completes their assigned test</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={interviewacceptance} onChange={() => setinterviewacceptance(!interviewacceptance)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Interview Acceptance</Typography>
                        <Typography>A candidate accepts an interview invitation for a role</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={candidatedecline} onChange={() => setcandidatedecline(!candidatedecline)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Candidate Decline</Typography>
                        <Typography>A candidate declines the interview or role offer</Typography>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}


const Preferences = () => {
    const axiosInstance = useAuthAxios();
    const [preferences, setPreferences] = useState({
        viewPreference: false
    });


    useEffect(() => {
        updatepreference()
    }, [preferences]);


    const handleToggle = (key) => {
        setPreferences((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const updatepreference = () => {
        axiosInstance.post(Accountpreferencesurl, {
            list_view: preferences.viewPreference,
        }).catch(error => {

        })
    }

    return (
        <Box>
            <Typography mb={1}>Platform Preferences</Typography>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>
                Manage the Platform Preferences
            </Typography>
            <Box mt={2}>
                {[
                    { label: 'View Preference', description: 'Toggle On for list View', key: 'viewPreference' }
                ].map(({ label, description, key }, index) => (
                    <Stack key={index} mt={3} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box ml={1}>
                            <Typography fontSize={16}>{label}</Typography>
                            <Typography color={dimtextcolor} fontSize={13}>{description}</Typography>
                        </Box>
                        <Switch
                            checked={preferences[key]}
                            onChange={() => handleToggle(key)}
                        />
                    </Stack>
                ))}
            </Box>
        </Box>
    );
};



const Billingbox = ({ subscription_upgrade }) => {
    const axiosInstance = useAuthAxios();
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);


    const [templan, settemplan] = useState('');
    const [currentplan, setcurrentplan] = useState('');

    //arrays
    const [displayedbilling, setdisplayedbilling] = useState([]);
    const [selectedbilling, setselectedbilling] = useState([]);
    const [payment_plans, setpayment_plans] = useState([]);

    //booleans
    const [confirmplanchange, setconfirmplanchange] = useState(false);
    const [show_redeem_coupon, setshow_redeem_coupon] = useState(false);


    useEffect(() => {
        fetchpredata()
    }, []);

    useEffect(() => {
        if (subscription_upgrade && payment_plans?.length > 0) {
            handlePlanchange()
        }
    }, [payment_plans, subscription_upgrade]);


    const fetchpredata = async () => {
        const [paymentPlansresponse, subscriptionStatusResponse] = await Promise.all([
            axiosInstance.get(Fetchpaymentplansurl),
            axiosInstance.get(Getsubscriptionstatusurl),
        ])

        if (paymentPlansresponse.data.success) {
            setpayment_plans(paymentPlansresponse.data.data.plan_details)
        }

        if (subscriptionStatusResponse.data.success) {
            setcurrentplan(subscriptionStatusResponse.data?.data)
        }
    }


    const subscroll = {
        overflowX: "scroll",
        '::-webkit-scrollbar': {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }

    const handlePlanchange = () => {
        setloading(true)
        axiosInstance.post(Initiatepaymenturl, {
            plan_id: payment_plans[0].id
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                openurl(response.data.data.url, true)
            } else {
                seterror('Unexpected error occurred,try again later')
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })

    }


    //select all
    const onselectall = () => {
        if (selectedbilling.length === displayedbilling.length) {
            setselectedbilling([])
        } else {
            setselectedbilling(displayedbilling)
        }
    }

    const onsuccess = () => {
        setshow_redeem_coupon(false)
        fetchpredata()
    }

    //select or unselect billing
    const checkbilling = (isselected, billing, billingkey) => {
        if (isselected) {
            setselectedbilling(selectedbilling.filter((obj, key) => key !== billingkey))
        } else {
            setselectedbilling((prev) => [...prev, billing])
        }
    }

    const trial_period = areDatesEqual(new Date(currentplan?.created_at), new Date(currentplan?.updated_at))
    const subscription = subscriptionplans[0]
    return (
        <Box>
            {confirmplanchange &&
                <Popupdialog
                    toptext={"Confirm Cancellation!!"}
                    question={"Are you sure you want to cancel your subscription?"}
                    successtext={"Submit"}
                    oncancel={() => setconfirmplanchange(false)}
                    onsuccess={handlePlanchange}
                />
            }
            {show_redeem_coupon && <Redeemcouponmodal onDismiss={() => setshow_redeem_coupon(false)} onSuccess={onsuccess} />}
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Stack mb={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography mb={1}>Plans & Billing</Typography>
                <Stack flexDirection={"row"} alignItems={"center"}>
                    <Button onClick={() => setshow_redeem_coupon(true)} title="click to redeem coupon" variant="outlined" >Redeem Coupon</Button>
                    {trial_period && <Button title="click to upgrade your subscription" onClick={() => handlePlanchange()} color="secondary" sx={{ height: 35, ml: 2 }} variant="contained">Upgrade</Button>}
                </Stack>
            </Stack>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>Manage your plans and subscriptions</Typography>
            <Box mt={2}>
                {payment_plans?.length > 0 ?
                    <Stack flexDirection={"row"} sx={subscroll}>
                        <Box position={"relative"} sx={{ border: trial_period ? '2px solid orange' : '2px solid blue', cursor: "pointer" }} borderRadius={3} padding={2} mr={2}>
                            <Box sx={{ position: "absolute", right: 10, top: 4 }}>
                                {trial_period ?
                                    <Report titleAccess="You are currently on trial period" color="warning" /> :
                                    <CheckCircle fontSize="small" color="primary" />
                                }
                            </Box>
                            <Typography color={'primary'} fontFamily={"boldfont"} fontSize={14}>{subscription.plan_name}</Typography>
                            <Typography fontFamily={"boldfont"} fontSize={24}>{subscription.price}</Typography>
                            <Stack>
                                {subscription.features.map((feature, key) => {

                                    return (
                                        <Stack mb={1} flexDirection={"row"} key={key}>
                                            <CheckCircle fontSize="small" color="primary" />
                                            <Tooltip title={feature} placement="top">
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: '200px'
                                                    }}
                                                    whiteSpace={"nowrap"} ml={1} fontSize={13}>{feature}</Typography>
                                            </Tooltip>
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Box>
                    </Stack> :
                    <Skeleton width={300} height={300} />
                }
                <Box mt={2}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Billing History</Typography>
                        <Stack flexDirection={"row"}>
                            <Button sx={{ textTransform: 'none' }} variant="outlined" startIcon={<FilterList />}>FIlter</Button>&nbsp;
                            <Button sx={{ textTransform: 'none' }} variant="outlined" startIcon={<CloudDownloadOutlined />}>Download All</Button>
                        </Stack>
                    </Stack>
                    <Box mt={2} height={"50vh"} overflow={"scroll"}>
                        <Stack sx={muistyles.stackstyle} flexDirection={"row"}>
                            <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                                <Customcheckbox onChange={() => onselectall()} size={'small'} value={displayedbilling.length === selectedbilling.length} />
                                <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>Invoice</Typography>
                                <ArrowDownward fontSize="small" />
                            </Stack>
                            <Typography sx={muistyles.headtitle}>Amount</Typography>
                            <Typography sx={muistyles.headtitle} >Date</Typography>
                            <Typography sx={muistyles.headtitle} >Status</Typography>
                            <Typography sx={{ ...muistyles.headtitle, width: "10%" }} />
                        </Stack>
                        <Box mt={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            {displayedbilling.map((billing, key) => {

                                const isselected = selectedbilling.find((obj) => obj.id === billing.id)

                                return (
                                    <Stack sx={muistyles.contentstackstyle} flexDirection={"row"} key={key}>
                                        <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                                            <Customcheckbox onChange={() => checkbilling(isselected, billing, key)} size={'small'} value={isselected} />
                                            <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>{billing.invoice}</Typography>
                                        </Stack>
                                        <Typography sx={muistyles.contentheadtitle}>{billing.amount}</Typography>
                                        <Typography sx={muistyles.contentheadtitle} >{displaydate(billing.created_at)}</Typography>
                                        <Typography sx={muistyles.contentheadtitle} >
                                            {billing.status === 'paid' ?
                                                <Stack flexDirection={"row"}><CheckCircle fontSize="small" color="success" /> Paid</Stack> :
                                                <Stack flexDirection={"row"}><ErrorOutline fontSize="small" color="error" /> unpaid</Stack>
                                            }
                                        </Typography>
                                        <Typography sx={{ ...muistyles.contentheadtitle, width: "10%" }} ><CloudDownloadOutlined /></Typography>
                                    </Stack>
                                )
                            })}
                            {displayedbilling.length < 1 && <Nodatacomponent text={'You have no invoices'} />}
                        </Box>
                    </Box>
                    {trial_period &&
                        <Box>
                            <Typography mb={2} mt={2} fontFamily={"boldfont"}>Danger Zone</Typography>
                            <Button variant="outlined" color="error">Cancel Subscription</Button>
                        </Box>
                    }

                </Box>
            </Box>
        </Box>
    )
}



//succes billing
export const Successbilling = () => {
    const navigate = useNavigate()



    setTimeout(() => {
        navigate("/clientaccount/clienthome", { replace: true })
    }, 5000);


    return (
        <Box>
            {/* {loading && <Loadingspinner />} */}
            <Successspinner message={'Payment received successfully ✅✅'} />
            <Backgoundskeleton />
        </Box>
    )
}

export const Errorbilling = () => {
    const navigate = useNavigate()

    setTimeout(() => {
        navigate("/clientaccount/client-account-settings", { state: { section: 4 }, replace: true })
    }, 5000);

    return (
        <Box>
            <Backgoundskeleton />
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container maxWidth={"sm"} sx={{ height: '50vh', backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 2 }}>
                    <Lottie animationData={errorlottie} style={{ height: 200 }} loop={true} autoplay={true} />
                    <Typography mt={2} textAlign={"center"}>Payment not received😞😞. Try again</Typography>
                </Container>
            </Modal>

        </Box>
    )
}



export default MyclientaccountSettings