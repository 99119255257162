import { useState } from "react";
import { primarycolor } from "../../../Utils/colors";
import { Box, Stack, Typography, LinearProgress, Button, IconButton, TextField, MenuItem, CircularProgress } from "@mui/material";
import Feedbackbutton from "../../../Components/feedbackbutton";
import { useNavigate } from "react-router-dom";
import { Sitenamelogo } from "../../../Common/sitelogo";
import { useDispatch, useSelector } from "react-redux";
import muistyles from "../../../Utils/muistyles";
import { Custommuiautocomplete } from "../../../Components/textinputs.";
import { programmingLanguagesFrameworks, skilllevels, programminglanguagesarray, databaseTechnologiesArray, cloudPlatformsArray } from "../../../Utils/arrays";
import { Add, ClearOutlined, HelpOutline, Remove } from "@mui/icons-material";
import { Erroralert, Skillspopup } from "../../../Components/popups";
import { setUser } from "../../../../store/user";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Postcandidateskillsurl, Updateuserinfourl } from "../../../Utils/urls";

const Onboarding3 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const name = user?.name.split(' ')[0];

    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');

    const candidate_skills = user?.candidate_skills?.map((skill) =>
    ({
        ...skill,
        label: skill.skill_name,
        years: parseInt(skill.years_of_experience)
    }))

    // Inputs
    const [selectedItems, setSelectedItems] = useState({
        frameworks: candidate_skills?.filter((obj) => obj.skill_type === 'frameworks') || [],
        languages: candidate_skills?.filter((obj) => obj.skill_type === 'languages') || [],
        databaseTechnologies: candidate_skills?.filter((obj) => obj.skill_type === 'databaseTechnologies') || [],
        cloudTechnologies: candidate_skills?.filter((obj) => obj.skill_type === 'cloudTechnologies') || []
    });

    // Modals
    const [showskillspoopup, setShowsSkillspopup] = useState(false);

    const categoryOptions = {
        frameworks: programmingLanguagesFrameworks,
        languages: programminglanguagesarray,
        databaseTechnologies: databaseTechnologiesArray,
        cloudTechnologies: cloudPlatformsArray
    };

    const handleCategoryChange = (category, tempItem) => {
        setSelectedItems(prevState => {
            const updatedItems = [...prevState[category]];
            const index = updatedItems?.findIndex(item => item?.label === tempItem?.label);

            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push({ ...tempItem, skill_level: skilllevels[0].label });
            }

            return { ...prevState, [category]: updatedItems };
        });
    };

    //change years of experience
    const handleYearsChange = (category, index, operation) => {
        setSelectedItems(prevState => {
            const updatedItems = [...prevState[category]];
            if (operation === 'add') {
                updatedItems[index].years = (parseInt(updatedItems[index].years) || 0) + 1;
            } else if (operation === 'subtract') {
                updatedItems[index].years = Math.max((updatedItems[index].years || 0) - 1, 0);
            }

            return { ...prevState, [category]: updatedItems };
        });
    };

    //change skill level
    const handleSkillLevelChange = (category, index, level) => {
        setSelectedItems(prevState => {
            const updatedItems = [...prevState[category]];
            updatedItems[index].skill_level = level;

            return { ...prevState, [category]: updatedItems };
        });
    };

    //function to remove skill item
    const handleRemoveItem = (category, index) => {
        setSelectedItems(prevState => {
            const updatedItems = prevState[category].filter((_, key) => key !== index);
            return { ...prevState, [category]: updatedItems };
        });
    };



    const Postdata = () => {
        const spreaded_items = { ...selectedItems }

        const final_skills_array = [
            ...(spreaded_items.frameworks.map((val) => ({ ...val, skill_type: 'frameworks', skill_id: Math.random() }))),
            ...(spreaded_items.languages.map((val) => ({ ...val, skill_type: 'languages', skill_id: Math.random() }))),
            ...(spreaded_items.databaseTechnologies.map((val) => ({ ...val, skill_type: 'databaseTechnologies', skill_id: Math.random() }))),
            ...(spreaded_items.cloudTechnologies.map((val) => ({ ...val, skill_type: 'cloudTechnologies', skill_id: Math.random() }))),
        ]

        setloading(true)
        axiosInstance.post(Postcandidateskillsurl, {
            skills: final_skills_array
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                //fetch and update skills data
                UpdateSkillsData()
            } else {
                setloading(false)
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }

    //update skills data
    const UpdateSkillsData = () => {
        axiosInstance.get(Updateuserinfourl).then(response => {
            setloading(false)
            if (response.data.success) {
                const { profile_details } = response.data.data;
                const updateddata = { ...user, candidate_skills: profile_details.candidate_skills };
                dispatch(setUser(updateddata));
                navigate("/candidate-onboarding-4");
            }
        })
    }



    //go to next page
    const gotonext = () => {
        Postdata()
    };

    //check completion
    const checkcompletion = () => {
        // Check if any of the selected items are empty
        if (
            selectedItems.frameworks.length < 1 ||
            selectedItems.languages.length < 1 ||
            selectedItems.databaseTechnologies.length < 1 ||
            selectedItems.cloudTechnologies.length < 1
        ) {
            return true;
        }

        // Check if any item has less than 1 year or no year specified
        if (
            selectedItems.frameworks.filter((obj) => obj.years < 1 || !obj.years).length > 0 ||
            selectedItems.languages.filter((obj) => obj.years < 1 || !obj.years).length > 0 ||
            selectedItems.databaseTechnologies.filter((obj) => obj.years < 1 || !obj.years).length > 0 ||
            selectedItems.cloudTechnologies.filter((obj) => obj.years < 1 || !obj.years).length > 0
        ) {
            return true;
        }

        // If everything is valid, return false
        return false;
    };


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" };

    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {showskillspoopup && <Skillspopup ondismiss={() => setShowsSkillspopup(false)} />}
            <Stack flexDirection={"row"} height={"100vh"} sx={{ overflowY: 'hidden' }}>
                <Box width={muistyles.onboardingleft_box1} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"}>
                    <Box width={muistyles.onboardingleft_box2}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋</Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={50} />
                    </Box>
                    <Box width={{ xs: "100", lg: "90%" }} paddingRight={{ xs: "0%", lg: "10%" }} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mt={4} fontSize={30} fontFamily={"boldfont"}>What’s your tech Stack?</Typography>
                        <Typography mt={3} mb={5} fontSize={17} color={'gray'}>
                            Add all technologies and skills you're good at. You may be tested on these later to ensure a good fit with Kontorva
                        </Typography>

                        {Object.keys(categoryOptions).map((category, idx) => (
                            <Box key={idx} sx={muistyles.skillsbox}>
                                <Stack flexDirection={"row"} alignItems={"center"}>
                                    <Button sx={muistyles.ovalbutton} variant="outlined">Required</Button>
                                    <Typography color={'gray'} ml={2}>
                                        Add at least 1 {category.replace(/([A-Z])/g, ' $1').toLowerCase()}
                                    </Typography>
                                </Stack>
                                <Box sx={muistyles.frameworksbox}>
                                    <Typography fontFamily={'boldfont'}>{category.replace(/([A-Z])/g, ' $1').toUpperCase()}</Typography>
                                    <Box mt={2} display={"flex"} flexWrap={'wrap'} alignItems={"center"}>
                                        <Custommuiautocomplete
                                            onChange={(e) => handleCategoryChange(category, e)}
                                            options={categoryOptions[category]}
                                            width={"40%"}
                                        />&nbsp;&nbsp;
                                        {categoryOptions[category].slice(0, 5).map((val, key) => {
                                            const selected = selectedItems[category]?.findIndex(item => item?.label === val?.label) > -1;
                                            return (
                                                <Button
                                                    sx={{ borderRadius: 10, margin: "8px 10px 8px 0px", height: 40 }}
                                                    color={selected ? 'primary' : 'secondary'}
                                                    key={key}
                                                    onClick={() => handleCategoryChange(category, val)}
                                                    variant={selected ? 'contained' : "outlined"}
                                                >{val.label}</Button>
                                            );
                                        })}
                                    </Box>
                                    <Box mt={3} width={'100%'}>
                                        {selectedItems[category]?.length > 0 &&
                                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                                <Box width={{ xs: "0%", lg: "30%" }} />
                                                <Typography width={{ xs: "45%", lg: "25%" }} whiteSpace={'nowrap'}>Years of Experience</Typography>
                                                <Stack width={{ xs: "40%", lg: "20%" }} flexDirection={"row"}>
                                                    <Typography>Skill Level</Typography>&nbsp;
                                                    <HelpOutline onClick={() => setShowsSkillspopup(true)} style={{ cursor: "pointer" }} />
                                                </Stack>
                                                <Box width={{ xs: "0%", lg: "20%" }} />
                                            </Stack>
                                        }
                                        {selectedItems[category]?.map((item, key) => {
                                            const currentYears = item?.years;
                                            const options = skilllevels.map((val) => val.label);

                                            return (
                                                <Stack flex={1} key={key} mt={2} mb={2} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Typography width={"30%"} fontFamily={'boldfont'}>{item.label}</Typography>
                                                    <Stack justifyContent={"space-between"}
                                                        sx={{
                                                            backgroundColor: 'white',
                                                            borderRadius: 8,
                                                            border: '1px solid silver'
                                                        }}
                                                        flexDirection={"row"} width={"25%"} alignItems={"center"}>
                                                        <IconButton disabled={!currentYears || currentYears === 0} onClick={() => handleYearsChange(category, key, 'subtract')}><Remove /></IconButton>
                                                        <Typography>{currentYears || 0}</Typography>
                                                        <IconButton onClick={() => handleYearsChange(category, key, 'add')}><Add /></IconButton>
                                                    </Stack>
                                                    <TextField select id="outlined-basic"
                                                        sx={{ width: "35%" }}
                                                        variant="outlined" size="small"
                                                        value={item?.skill_level}
                                                        onChange={(e) => handleSkillLevelChange(category, key, e.target.value)} >
                                                        {options.map((option, index) => (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <IconButton onClick={() => handleRemoveItem(category, key)} color="primary"><ClearOutlined /></IconButton>
                                                </Stack>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Stack sx={{ backgroundColor: 'white' }} bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} sx={buttonstyle} disabled={checkcompletion()} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <Typography mt={3} fontFamily={'boldfont'} width={"80%"} color={"white"} fontSize={30} textAlign={"center"}>
                        Get matched with {user?.specialization} roles, tailored to your unique profile
                    </Typography>

                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default Onboarding3;
