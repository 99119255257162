import React from "react";
import { Container, IconButton, Modal, Stack, Typography, Button } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// upgrade pop up
export const Upgradepopup = ({ onDismiss }) => {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const goToSubscription = () => {
        onDismiss()
        navigate("/clientaccount/client-account-settings", { state: { section: 4, subscription_upgrade: true } })
    };

    return (
        <Modal
            open={true}
            onClose={onDismiss}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 2 }}>
                <Stack spacing={2}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontSize={20} fontWeight={"bold"}>Unlock full potential</Typography>
                        <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={onDismiss}>
                            <Clear />
                        </IconButton>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                        <img src={require("./../../../../assets/images/upgrade.jpeg")} style={{ maxHeight: 300, maxWidth: "80%" }} />

                    </Stack>
                    <Typography fontSize={16} textAlign={"center"} mb={2}>
                        Enhance your Kontorva experience! Upgrade now to access powerful features and make the most of your hiring process.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => goToSubscription()}
                        sx={{ backgroundColor: '#1976d2', color: 'white', '&:hover': { backgroundColor: '#155a8a' } }}
                    >
                        Upgrade
                    </Button>
                </Stack>
            </Container>
        </Modal>
    );
};
