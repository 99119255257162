import { useState } from "react";
import { Sitenamelogo } from "../../../Common/sitelogo"
import muistyles from "../../../Utils/muistyles"
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, Button, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../../store/user";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { commitmenttypes } from "../../../Utils/arrays";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Updateuserinfourl } from "../../../Utils/urls";
import { Erroralert } from "../../../Components/popups";

const Clientonboarding2 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);

    const [engagementtype, setengagementtype] = useState('');
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');



    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            commitment_type: engagementtype
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, engagementtype: engagementtype };
                dispatch(setUser(updateddata));
                navigate("/client-onboarding-3");
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }




    //go to next page
    const gotonext = () => {
        Postdata()
    };


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" };
    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack height={"100vh"} flexDirection={"row"} sx={{ overflowY: 'hidden' }}>
                <Box width={"40%"} sx={muistyles.onboardingleft}>
                    <Sitenamelogo type="transparentwhite" />
                    <Box mt={10}>
                        <Typography color={'white'} fontFamily={'boldfont'} fontSize={40}>Find your perfect <br /> Match</Typography>
                        <Typography color={'white'}>Answer some short questions to help us understand your needs.</Typography>
                    </Box>
                </Box>
                <Box width={{ xs: "100%", lg: "60%" }} padding={{ xs: 3, lg: 8 }} position={"relative"} height={"90%"}>
                    <Box display={{ xs: "block", lg: "none" }}>
                        <Sitenamelogo />
                    </Box>
                    <Box width={"95%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mb={5} fontStyle={18} fontSize={18} fontFamily={"boldfont"}>What kind of commitment do you need?</Typography>
                        <Box width={"100%"} mt={3} display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"}>
                            {commitmenttypes.map((type, key) => {
                                const selected = engagementtype === type
                                const onChange = () => {
                                    if (selected) {
                                        setengagementtype('')
                                    } else {
                                        setengagementtype(type)
                                    }
                                }
                                return (
                                    <Box width={{ xs: "100%", lg: "40%" }} onClick={onChange} key={key} sx={{ border: selected ? '1px solid blue' : '1px solid silver', cursor: "pointer" }} pl={2} pr={2} pt={3} pb={3} mb={2} borderRadius={2} >
                                        <Stack flexDirection={"row"} alignItems={"center"}>
                                            <Box sx={{ cursor: "pointer", alignItems: "center", display: "flex" }} mr={1}>
                                                {selected ?
                                                    <CheckBox color="primary" /> :
                                                    <CheckBoxOutlineBlank />
                                                }
                                            </Box>
                                            <Typography fontSize={{ lg: "16px", sm: "13px", xs: "13px" }}>{type}</Typography>&nbsp;
                                        </Stack>
                                    </Box>
                                )
                            })}
                        </Box>


                    </Box>
                    <Stack sx={{ backgroundColor: 'white' }} width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} sx={buttonstyle} disabled={!engagementtype} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
            </Stack>

        </Box>
    )
}

export default Clientonboarding2