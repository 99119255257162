import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { primarycolor, secondarycolor } from './pages/Utils/colors';
import Loadingspinner from './pages/Components/loading';
import { AccountCircle, DataObject, HelpOutline, HistoryEduOutlined, Home, HomeOutlined, MenuOpen, MenuOutlined, NotificationsActive, WorkspacePremium } from '@mui/icons-material';
import Popupdialog from './pages/Components/popupcomponent';
import { AppBar, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import useAuthAxios from './hooks/useAuthAxios';
import { useDispatch, useSelector } from 'react-redux';
import { Deleteaccounturl } from './pages/Utils/urls';
import { logoutUser } from './store/user';
import Candidatehome from './pages/Dashboards/Candidate/candidatehome';
import AccountMenu from './pages/Components/accountmenu';
import Candidateassessments from './pages/Dashboards/Candidate/Assessments/candidateassessments';
import Allmyassessments from './pages/Dashboards/Candidate/Assessments/allassessments';
import MycandidateaccountSettings from './pages/Dashboards/Candidate/Account/myaccount';
import Assessmentmodule from './pages/Dashboards/Candidate/Assessments/assessmentform';
import Receivefeedback from './pages/Dashboards/Candidate/Feedback/collectfeedback';
import Recommendedassessments from './pages/Dashboards/Candidate/Assessments/recommendedassessments';
import Viewinvitedrole from './pages/Dashboards/Candidate/Assessments/viewrole';



const drawerWidth = isMobile ? "70%" : "18%";
const iconsize = 'medium'
const outlinefontsize = 15

const iconstyle = {
  color: "white"
}
const subiconstyle = {
  color: primarycolor
}
const mainlinksstyle = {
  color: "white",
  fontSize: outlinefontsize
}
const mainlinksstyleactive = {
  whiteSpace: "nowrap",
  color: primarycolor,
  fontSize: outlinefontsize,
  fontFamily: 'boldfont'
}


const linksarray = [
  {
    name: "Dashboard",
    links: [
      "/candidateaccount/candidatehome"
    ],
    icon: <HomeOutlined fontSize={iconsize} />
  },
  {
    name: "Skill Assessments",
    links: [
      "/candidateaccount/candidateassessments",
      "/candidateaccount/all-my-assessments",
      "/candidateaccount/assessments-module",
      "/candidateaccount/recommended-assessments",
      "/candidateaccount/invited-role"
    ],
    icon: <DataObject fontSize={iconsize} />
  },
  {
    name: "Account",
    links: ["/candidateaccount/candidate-account-settings"],
    icon: <AccountCircle fontSize={iconsize} />
  },
  {
    name: "Feedback",
    links: ["/candidateaccount/feeback"],
    icon: <HelpOutline fontSize={iconsize} />
  }
]


const Candidatenavigation = () => {
  const axiosInstance = useAuthAxios()
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();


  const [error, seterror] = useState('');
  const [success, setsuccess] = useState('');


  const [refresh, setrefresh] = useState(false);
  const [loading, setloading] = useState(false);

  const [messagecounter, setmessagecounter] = useState(4);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showbackonline, setshowbackonline] = useState(false);
  const [showdrawer, setshowdrawer] = useState(true);
  const pathname = location?.pathname


  //modals
  const [logoutdialog, setlogoutdialog] = useState(false);
  const [showcloseaccount, setshowcloseaccount] = useState(false);




  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setshowbackonline(true)
      setTimeout(() => { setshowbackonline(false) }, 3000);
    };
    const handleOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);


  //logout from site
  const onlogout = () => {
    setlogoutdialog(false)
    setshowcloseaccount(false)
    setloading(true)
    setTimeout(() => {
      setrefresh(!refresh)
      setloading(false)
      dispatch(logoutUser())
      navigate("/login", { replace: true })
    }, 3000);
  }


  //close account
  const closeaccount = () => {
    setloading(true);
    axiosInstance.post(Deleteaccounturl)
      .then(function (response) {
        setloading(false);
        if (!response.data.error) {
          setsuccess("Account closed successfully");
          setTimeout(() => {
            setsuccess('')
            dispatch(logoutUser())
            navigate("/", { replace: true })
          }, 5000);
        } else {
          seterror(response.data.message);
        }
      }).catch(function (error) {
        setloading(false);
        seterror("There was an error. Please try again later.");
        console.log(error);
      });
  }



  return (
    <Box width={"100vw"}>
      <Box sx={{ display: 'flex' }}>
        {loading && <Loadingspinner />}
        {logoutdialog &&
          <Popupdialog
            toptext={"Confirm exit!"}
            question={"Are you sure you want to logout from this platform?"}
            successtext={"Logout"}
            oncancel={() => setlogoutdialog(false)}
            onsuccess={() => onlogout()}
          />}
        {showcloseaccount &&
          <Popupdialog
            toptext={"Confirm account closure!"}
            question={" Are you sure you want to close your account?"}
            successtext={"Close account"}
            oncancel={() => setshowcloseaccount(false)}
            onsuccess={() => closeaccount()}
          />}
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: {
              lg: `calc(100% - ${drawerWidth})`,
              md: '100%', sm: "100%", xs: "100%"
            },
            ml: {
              lg: `${drawerWidth}`, md: 0, sm: 0, xs: 0
            },
            backgroundColor: 'white'
          }}
        >
          <Toolbar >
            <Stack flex={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
              <Stack flexDirection={"row"} alignItems={"center"} color={'black'} fontSize={14}>
                <Box mr={1} sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' } }} onClick={() => setshowdrawer(true)}>
                  <MenuOutlined />
                </Box>
                {/* {user?.currenticon} */}
                <Typography fontFamily={'boldfont'} noWrap >{user?.currentpage || 'Dashboard'}</Typography>
              </Stack>
              <AccountMenu user={user} logout={() => setlogoutdialog(true)} counter={messagecounter} />
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: 'black',
              // borderRadius: 8,
              padding: 1
            },
          }}
          variant={isMobile ? "temporary" : "permanent"}
          anchor="left"
          color='primary'
          open={showdrawer}
          onClose={() => setshowdrawer(false)}
        >
          {!isOnline && <div className='offlinediv'>No internet Connection</div>}
          {showbackonline && <div className='onlinediv'>Back Online</div>}
          <Box>
            <img src={require('./assets/images/Logo/iconblack.png')} style={{ height: 60, borderRadius: 5 }} />
          </Box>
          <Divider />
          <List>
            {linksarray.map((val, key) => {
              const isactive = val.links?.includes(pathname)
              return (
                <ListItem key={key} disablePadding onClick={() => {
                  navigate(val.links[0])
                  setshowdrawer(false)
                }}>
                  <ListItemButton>
                    <ListItemIcon sx={{ color: isactive ? subiconstyle : iconstyle }}>  {val.icon} </ListItemIcon>
                    <ListItemText primary={val.name} primaryTypographyProps={isactive ? mainlinksstyleactive : mainlinksstyle} />
                  </ListItemButton>
                </ListItem>
              )
            })}

          </List>
          <Divider />
          <List>
            <div style={{ position: "fixed", bottom: 10 }}>
              <ListItem disablePadding onClick={() => setlogoutdialog(true)}>
                <ListItemButton>
                  <ListItemIcon>
                    <PowerSettingsNewIcon size={iconsize} sx={iconstyle} />
                  </ListItemIcon>
                  <ListItemText primary={"Signout"} primaryTypographyProps={mainlinksstyle} />
                </ListItemButton>
              </ListItem>
            </div>

          </List>
        </Drawer>
        <Box
          component="main"
          width={isMobile ? '100%' : '82%'}
          sx={{
            p: isMobile ? 0 : 3,
            maxWidth: isMobile ? '100%' : '82%',
            backgroundColor: '#f9f9f9',
            pt: 10
          }}
        >
          <Routes>
            <Route path="/*" exact element={<Navigate replace to="/candidateaccount/candidatehome" />} />
            <Route path="/candidatehome" element={<Candidatehome />} exact />
            {/**Assessments */}
            <Route path="/candidateassessments" element={<Candidateassessments />} exact />
            <Route path="/all-my-assessments" element={<Allmyassessments />} exact />
            <Route path="/assessments-module" element={<Assessmentmodule />} exact />
            <Route path="/recommended-assessments" element={<Recommendedassessments />} exact />
            <Route path="/invited-role" element={<Viewinvitedrole />} exact />



            {/**Feedback */}
            <Route path="/feeback" element={<Receivefeedback />} exact />


            {/**Account settings */}
            <Route path="/candidate-account-settings" element={<MycandidateaccountSettings ondeleteaccount={() => setshowcloseaccount(true)} />} exact />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}



export default Candidatenavigation;
