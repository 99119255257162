import React, { useState, useEffect } from "react"
import { Alert, AlertTitle, Box, Button, Skeleton, Stack, Tooltip, Typography } from "@mui/material"
import { areDatesEqual, Greetings, openurl, returnsurname } from "../../Utils/functions"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import muistyles from "../../Utils/muistyles"
import { boxborder, primarycolor } from "../../Utils/colors"
import { AddCircleOutline, ArrowForward, CheckCircle, ContentCopy, East, Report, TravelExplore, Upgrade } from "@mui/icons-material"
import CircularWithValueLabel from "../../Components/circularprogress"
import { subscriptionplans } from "../../Utils/dummy"
import { Contactusmodal } from "../../Common/contactus"
import useAuthAxios from "../../../hooks/useAuthAxios"
import { Fetchcandidaterolesurl, Fetchpaymentplansurl, Getsubscriptionstatusurl } from "../../Utils/urls"
import { Errornonpop } from "../../Components/popups"


const Clienthome = ({ onshowupgrade }) => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const axiosInstance = useAuthAxios()
    const dispatch = useDispatch()
    const [currentplan, setcurrentplan] = useState('');

    const [payment_plans, setpayment_plans] = useState([]);
    const [assessments_count, setassessments_count] = useState(0);
    const [candidates_count, setcandidates_count] = useState(0);

    useEffect(() => {
        fetchpredata()
    }, []);

    const fetchpredata = async () => {
        const [paymentPlansresponse, subscriptionStatusResponse, rolesResponse] = await Promise.all([
            axiosInstance.get(Fetchpaymentplansurl),
            axiosInstance.get(Getsubscriptionstatusurl),
            axiosInstance.get(Fetchcandidaterolesurl)
        ])

        if (paymentPlansresponse.data.success) {
            setpayment_plans(paymentPlansresponse.data.data.plan_details)
        }

        if (subscriptionStatusResponse.data.success) {
            setcurrentplan(subscriptionStatusResponse.data.data)
        }

        if (rolesResponse.data.success) {
            const all_assessments = rolesResponse.data?.data.roles.flatMap((obj) => obj.assessments)
            const all_candidates = rolesResponse.data?.data.roles.flatMap((obj) => obj.candidates)
            setassessments_count(all_assessments.length)
            setcandidates_count(all_candidates.length)
        }

    }


    const upgrade_account = () => {
        navigate("/clientaccount/client-account-settings", { state: { section: 4, subscription_upgrade: true } })
    }


    const trial_period = areDatesEqual(new Date(currentplan?.created_at), new Date(currentplan?.updated_at))
    const subscription = subscriptionplans[0]

    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            {payment_plans.length > 0 && trial_period &&
                <Box mb={2}>
                    <Alert severity="warning" >
                        <Typography>Trial</Typography>
                        <Stack width={"100%"} flexDirection={"row"} alignItems={"center"} >
                            <Typography fontSize={13}>  You are currently on a trial plan. Please upgrade your account to continue accessing all features.</Typography>
                            <Button onClick={() => upgrade_account()} sx={{ fontSize: 13 }}>Upgrade</Button>
                        </Stack>
                    </Alert>
                </Box>
            }
            <Box padding={{ xs: 1, lg: 3 }} borderRadius={3} mb={2} sx={{ background: `linear-gradient(to right, ${primarycolor}, #57a2df)`, }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Box>
                        <Typography fontSize={{ xs: 16, lg: 20 }} color={'white'} fontFamily={'boldfont'}>{Greetings()} {returnsurname(user?.name)}</Typography>
                        <Typography fontSize={{ xs: 13, lg: 15 }} color={'white'}>Welcome to your Client dashboard</Typography>
                    </Box>
                    <Button onClick={() => trial_period ? onshowupgrade() : navigate("/clientaccount/discover-candidates")} sx={muistyles?.whitebutton} startIcon={<TravelExplore />} variant="contained">Find Developers</Button>
                </Stack>
            </Box>
            <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                <Box width={{ xs: "100%", lg: "68%" }}>
                    <Box padding={{ xs: 1, lg: 3 }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        <Stack flexDirection={"row"}>
                            <Box width={{ xs: "95%", lg: "60%" }} padding={2}>
                                <Typography fontSize={30} fontFamily={'boldfont'}>
                                    Skills-based technical
                                    assessments
                                </Typography>
                                <Typography mb={2}>
                                    Efficient & effective hiring processes,
                                    through through skill-based technical
                                    assessments with integrity.
                                </Typography>
                                <Button onClick={() => navigate("/clientaccount/assessment-builder-1")} startIcon={<AddCircleOutline />} sx={{ textTransform: "none" }} variant="contained">New Assessment</Button>
                            </Box>
                            <Box display={{ xs: "none", lg: "block" }}>
                                <img className="client-home-illustration" src={require("./../../../assets/illustrations/mansitting.png")} />
                            </Box>
                        </Stack>
                    </Box>
                    <Stack mt={2} flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                        <Box padding={{ xs: 1, lg: 3 }} width={{ xs: "100%", lg: "48%" }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            <Typography mb={1} fontSize={{ xs: 14, lg: 18 }} fontFamily={'boldfont'}>View your current<br /> assessments</Typography>
                            <Button onClick={() => navigate("/clientaccount/clientassessments")} sx={{ textTransform: "none" }} endIcon={<East />} variant="outlined">Talent Asessments</Button>
                        </Box>
                        <Box mt={{ xs: 2, lg: 0 }} padding={{ xs: 1, lg: 3 }} width={{ xs: "100%", lg: "48%" }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            <Typography mb={1} fontSize={{ xs: 14, lg: 18 }} fontFamily={'boldfont'}>Browse pre-assessed<br /> candidates for your roles</Typography>
                            <Button onClick={() => trial_period ? onshowupgrade() : navigate("/clientaccount/discover-candidates")} sx={{ textTransform: "none" }} endIcon={<East />} variant="outlined">Candidates Catalogue</Button>
                        </Box>
                    </Stack>
                    <Box padding={3} mt={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        <Typography mb={1} fontSize={18} fontFamily={'boldfont'}>
                            Get help in assembling a team for your
                            project
                        </Typography>
                        <Button onClick={() => openurl("https://cal.com/kontorva/discovery-call")} sx={{ textTransform: "none" }} endIcon={<East />} variant="outlined">Consultation</Button>
                    </Box>

                </Box>
                {/* right section */}
                <Box mt={{ xs: 2, lg: 0 }} padding={3} width={{ xs: "100%", lg: "30%" }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack flexDirection={"row"}>
                        <Typography fontSize={18} fontFamily={'boldfont'}>Current Assessments Activity</Typography>
                        {/* <CircularWithValueLabel value={40} /> */}
                    </Stack>
                    <Stack mt={2} mb={2} flexDirection={"column"} justifyContent={"space-between"}>
                        <Stack mb={2} width={"100%"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/exam.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>My Assessments</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>{assessments_count}</Typography>
                            </Box>
                        </Stack>
                        <Stack mb={2} width={"100%"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/student.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Candidates</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>{candidates_count}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack mb={2} mt={2} alignItems={"center"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontSize={14} fontFamily={'boldfont'}>Subscription</Typography>
                        {trial_period && <Button onClick={() => upgrade_account()} variant="outlined" sx={{ height: 30 }} color="secondary" endIcon={<Upgrade />}>Upgrade</Button>}
                    </Stack>
                    {payment_plans?.length > 0 ?
                        <Box position={"relative"} sx={{ border: trial_period ? '2px solid orange' : '2px solid blue', cursor: "pointer" }} borderRadius={3} padding={2}>
                            <Box sx={{ position: "absolute", right: 10, top: 4 }}>
                                {trial_period ?
                                    <Report titleAccess="You are currently on trial period" color="warning" /> :
                                    <CheckCircle fontSize="small" color="primary" />
                                }
                            </Box>
                            <Typography color={'primary'} fontFamily={"boldfont"} fontSize={14}>{subscription?.plan_name}</Typography>
                            <Typography fontFamily={"boldfont"} fontSize={24}>{subscription?.price}</Typography>
                            <Stack>
                                {subscription?.features?.map((feature, key) => {

                                    return (
                                        <Stack mb={1} flexDirection={"row"} key={key}>
                                            <CheckCircle fontSize="small" color="primary" />
                                            <Tooltip title={feature} placement="top">
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: '100%'
                                                    }}
                                                    whiteSpace={"nowrap"} ml={1} fontSize={13}>{feature}</Typography>
                                            </Tooltip>
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Box> :
                        <Skeleton width={"100%"} height={300} />
                    }
                </Box>
            </Stack >
        </Box >
    )
}

export default Clienthome