import { useState } from "react";
import { Sitenamelogo } from "../../../Common/sitelogo"
import { Custommuiautocomplete } from "../../../Components/textinputs.";
import { cloudPlatformsArray, databaseTechnologiesArray, programminglanguagesarray, programmingLanguagesFrameworks } from "../../../Utils/arrays";
import muistyles from "../../../Utils/muistyles"
import { Remove } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, Button, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../../store/user";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Updateuserinfourl } from "../../../Utils/urls";
import { Erroralert } from "../../../Components/popups";

const Clientonboarding1 = () => {
    const axiosInstance = useAuthAxios();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);

    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');

    const technologies = [...programmingLanguagesFrameworks, ...programminglanguagesarray, ...databaseTechnologiesArray, ...cloudPlatformsArray];

    const [selectedtechnologies, setselectedtechnologies] = useState(user?.prefferredskills || []);




    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            skills: selectedtechnologies
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, prefferredskills: selectedtechnologies };
                dispatch(setUser(updateddata));
                navigate("/client-onboarding-2");
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }



    const handleselectionChange = (tempItem) => {
        setselectedtechnologies(prevState => {
            const updatedItems = [...prevState];
            const index = updatedItems?.findIndex(item => item.label === tempItem.label);

            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push(tempItem);
            }
            return updatedItems;
        });
    }


    //go to next page
    const gotonext = () => {
        Postdata()
    };


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" };
    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack height={"100vh"} flexDirection={"row"} sx={{ overflowY: 'hidden' }}>
                <Box width={"40%"} sx={muistyles.onboardingleft}>
                    <Sitenamelogo type="transparentwhite" />
                    <Box mt={10}>
                        <Typography color={'white'} fontFamily={'boldfont'} fontSize={40}>Find your perfect <br /> Match</Typography>
                        <Typography color={'white'}>Answer some short questions to help us understand your needs.</Typography>
                    </Box>
                </Box>
                <Box width={{ xs: "100%", lg: "60%" }} padding={{ xs: 3, lg: 8 }} position={"relative"} height={"90%"}>
                    <Box display={{ xs: "block", lg: "none" }}>
                        <Sitenamelogo />
                    </Box>
                    <Box width={"95%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mb={5} fontStyle={18} fontSize={18} fontFamily={"boldfont"}>Choose the skill you’re after</Typography>
                        <Custommuiautocomplete
                            onChange={(e) => handleselectionChange(e)}
                            options={technologies}
                            width={"100%"}
                        />
                        <Box mt={1} display={"flex"} flexWrap={'wrap'} alignItems={"center"}>
                            {selectedtechnologies.slice(0, 10).map((val, key) => {
                                return (
                                    <Button
                                        startIcon={<Remove />}
                                        sx={{ borderRadius: 10, margin: "8px 10px 8px 0px", height: 40 }}
                                        color={'primary'}
                                        key={key}
                                        onClick={() => handleselectionChange(val)}
                                        variant={'contained'}
                                    >{val.label}</Button>
                                );
                            })}
                        </Box>

                        <Box mt={5}>
                            <Typography fontFamily={'boldfont'} fontSize={16}>Based on our clients’ popular demand</Typography>
                            <Box mt={2} display={"flex"} flexWrap={'wrap'} alignItems={"center"}>
                                {programmingLanguagesFrameworks.slice(0, 15).map((val, key) => {
                                    const selected = selectedtechnologies?.findIndex(item => item.label === val.label) > -1;
                                    return (
                                        <Button
                                            sx={{ borderRadius: 10, margin: "8px 10px 8px 0px", height: 40 }}
                                            color={selected ? 'primary' : 'secondary'}
                                            key={key}
                                            onClick={() => handleselectionChange(val)}
                                            variant={selected ? 'contained' : "outlined"}
                                        >{val.label}</Button>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Box>
                    <Stack sx={{ backgroundColor: 'white' }} width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} sx={buttonstyle} disabled={selectedtechnologies?.length < 1} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
            </Stack>

        </Box>
    )
}

export default Clientonboarding1