import { Box, Button, Container, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Customsectiontextinput } from "../Components/textinputs.";
import { GitHub, Visibility, VisibilityOff } from "@mui/icons-material";
import Sitelogo from "./sitelogo";
import Popupdialog from "../Components/popupcomponent";
import Loadingspinner from "../Components/loading";
import { Erroralert, Successalert } from "../Components/popups";
import useAuthAxios from "../../hooks/useAuthAxios";
import { Loginwithgoogle, Loginwithlinkedinurl, Registeruserurl } from "../Utils/urls";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/user";
import { primarycolor } from "../Utils/colors";
import googleIcon from './../../assets/svgs/google.svg';
import githubIcon from './../../assets/svgs/github.svg';
import linkedinIcon from './../../assets/svgs/linkedin.svg';
import facebookIcon from './../../assets/svgs/facebook.svg';
import Verifyregistercode from "./verifyregistercode";
import { isMobile } from "react-device-detect";
import { Backbutton } from "../Components/buttons";
import { openurl } from "../Utils/functions";



const Candidatesignup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [fullname, setfullname] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const usertype = 'CANDIDATE'


    const [loading, setloading] = useState(false);
    const [showPassword, setshowPassword] = useState(false);

    //modals
    const [confirmsave, setconfirmsave] = useState(false);
    const [showentercode, setshowentercode] = useState(false);


    // Regex for password validation
    const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    // Check if new password meets the rules
    const isValidPass = passwordRules.test(password);


    const handleSubmit = () => {
        setconfirmsave(false)
        setloading(true)
        seterror(null)

        if (!isValidPass) {
            seterror('Password does not meet minimum requirements')
            return
        }

        const predata = {
            name: fullname,
            email: email,
            password: password,
            account_type: usertype
        }
        seterror(null)
        axiosInstance.post(Registeruserurl, predata).then(response => {
            setloading(false)
            if (response.data.success) {
                setshowentercode(true)
            }
        }).catch(error => {
            setloading(false)
            seterror(error || "Sorry an error occurred while submitting your details");
        });

    };

    const onsuccess = () => {
        setshowentercode(false)
        navigate('/login', { state: { usertype: usertype } })
    }



    const svgsize = isMobile ? "15px" : '20px'
    const mobilebuttonfont = "11px"

    return (
        <Box position={'relative'}>
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Details!!"}
                    question={"Are you sure the details are correct?"}
                    successtext={"Submit"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {showentercode && <Verifyregistercode useremail={email} onsuccess={onsuccess} ondismissmodals={() => setshowentercode(false)} />}
            <Stack flexDirection={"row"} height={"100%"}>
                <Box display={{ lg: "block", sm: "none", xs: "none" }} sx={{ backgroundColor: '#161616', width: "60%", height: "100vh", position: "relative", overflow: "hidden" }}>
                    <div className="floatingglobemessage">
                        <Sitelogo type={'dark'} height="40px" />
                        <Typography mt={{
                            lg: "200px",
                            sm: "100px"
                        }} fontSize={'25px'} color={"silver"}>Welcome to</Typography>
                        <Typography lineHeight={'100px'} fontSize={"35px"} fontFamily={"boldfont"} color={'white'}>Kontorva Community</Typography>
                        <Typography color={'silver'}>Home to 23 Million developers worldwide</Typography>
                        <Link color={primarycolor}>Know more</Link>
                    </div>
                    <video
                        autoPlay
                        muted
                        loop
                        style={{
                            position: "absolute",
                            opacity: 0.6,
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            zIndex: 5,
                        }}
                    >
                        <source src={require("./../../assets/videos/world.mp4")} type="video/mp4" />
                    </video>
                </Box>
                <Box width={{ lg: "40%", sm: "96%", xs: "96%" }} sx={{ backgroundColor: 'white', height: "100%", paddingLeft: "2%" }}>
                    <Container sx={{ mt: 2 }}>
                        {isMobile && <Backbutton onClick={() => navigate(-1)} />}
                        <Typography mt={1} fontSize={{ lg: 25, sm: 20, xs: 20 }} fontFamily={'boldfont'}>
                            Join Us<br />
                            Create a Kontorva Account
                        </Typography>
                        <Typography color={"gray"} fontSize={15} mt={1}>Be part of a 23 million-strong community of developers</Typography>
                        <Box component="form" onSubmit={(e) => {
                            e.preventDefault()
                            setconfirmsave(true)
                        }} width={"100%"} mt={2}>
                            <Stack >
                                <Customsectiontextinput
                                    fieldname={'Full Name'}
                                    value={fullname}
                                    onChange={setfullname}
                                    type={'text'}
                                    required={true}
                                    autoComplete={'name'}
                                    width={"100%"}
                                />
                                <Customsectiontextinput
                                    fieldname={'Email'}
                                    value={email}
                                    onChange={setemail}
                                    type={'email'}
                                    required={true}
                                    autoComplete={'email'}
                                    width={"100%"}
                                />
                                <Customsectiontextinput
                                    fieldname={'Password'}
                                    value={password}
                                    onChange={setpassword}
                                    required={true}
                                    width={"100%"}
                                    type={showPassword ? 'text' : 'password'}
                                    inputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setshowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        ),
                                    }}
                                    helperText={!isValidPass && password && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                                />
                            </Stack>
                            <br />
                            <Button type="submit" variant="contained" fullWidth>Sign Up</Button>
                            <Typography mt={1} fontSize={"12px"} fontFamily={"lightfont"} fontWeight={"100"}>
                                By signing up, you agree to our <Link>Data Privacy Policy </Link>  and <Link>Terms & Conditions </Link>
                            </Typography>
                            <Button onClick={() => openurl(Loginwithgoogle, true)} color="secondary" sx={{ marginTop: 3 }} variant="outlined" fullWidth startIcon={<img src={googleIcon} style={{ height: svgsize, width: svgsize }} />}>Continue with Google</Button>
                            <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <img style={{ cursor: "pointer", maxWidth: "48%" }} onClick={() => openurl(Loginwithlinkedinurl, true)} src={require("./../../assets/images/linkedin.png")} />
                                <Stack
                                    height={32}
                                    borderRadius={1}
                                    width={"48%"}
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    sx={{ backgroundColor: "black" ,cursor:"pointer"}}
                                >
                                    <GitHub sx={{ color: "white" }} />
                                    <Typography ml={2} sx={{ color: "white" }} fontSize={15}>Sign In with Github</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Container>
                </Box>

            </Stack>
        </Box>
    );
};

export default Candidatesignup



