import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { boxborder } from "../../../Utils/colors";
import CircularWithValueLabel from "../../../Components/circularprogress";
import muistyles from "../../../Utils/muistyles";
import { ArrowDownward, AssessmentOutlined, BookmarkAdded, CheckCircle, Clear, DeleteRounded, ErrorOutline, KeyboardBackspace, TravelExplore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateto, openurl } from "../../../Utils/functions";
import { Customcheckbox } from "../../../Components/textinputs.";
import Popupdialog from "../../../Components/popupcomponent";
import { Fetchcandidatesurl, Getmatchingcandidatesurl, Shortlistcandidatesurl } from "../../../Utils/urls";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Erroralert, Successalert } from "../../../Components/popups";
import Loadingspinner from "../../../Components/loading";
import { site_baseURL } from "../../../Utils/defaults";
import { ViewassessmentResults } from "./viewassessmentResults";
import Nodatacomponent from "../../../Components/nodatacomponent";



const Viewassessment = ({ trial_period, onshowupgrade }) => {

    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const current_role = location.state?.current_role
    const invited_candidates = current_role?.candidates


    const [displayedcandidates, setdisplayedcandidates] = useState([]);
    const [selectedcandidates, setselectedcandidates] = useState([]);
    const [success, setsuccess] = useState('');
    const [error, seterror] = useState('');
    const [loading, setloading] = useState(false);
    const [tempcandidate, settempcandidate] = useState('');

    //booleans
    const [confirmshortlist, setconfirmshortlist] = useState(false);
    const [confirmdeletion, setconfirmdeletion] = useState(false);
    const [showassessmeentresults, setshowassessmeentresults] = useState(false);

    useEffect(() => {
        fetchcandidates()
    }, []);



    const fetchcandidates = () => {
        setloading(true)
        axiosInstance.get(`${Getmatchingcandidatesurl}/${current_role?.id}`).then((response) => {
            setloading(false)
            if (response.data.success) {
                const gotten_data = response.data.details.filter((obj) => obj.candidate_profile).map((candidate) => ({
                    ...candidate,
                    profile: candidate.candidate_profile,
                    ...candidate.candidate_details
                }))
                setdisplayedcandidates(gotten_data.length > 0 ? gotten_data : [...invited_candidates])
            } else {
                seterror('No candidates yet')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }

    const handleShortlisting = () => {
        setconfirmshortlist(false)
        setloading(true)
        axiosInstance.post(Shortlistcandidatesurl, {
            role_id: current_role.id,
            candidate_id: selectedcandidates.map((val) => val.id)
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                setsuccess('Shortlisted successfully')
                setTimeout(() => {
                    setsuccess('')
                }, 3000);
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }

    const handleDeletion = () => {
        setconfirmdeletion(false)
    }


    const onselectall = () => {
        if (selectedcandidates.length === displayedcandidates.length) {
            setselectedcandidates([])
        } else {
            setselectedcandidates(displayedcandidates)
        }
    }


    //select or unselect candidate
    const checkcandidate = (isselected, candidate, candidatekey, isshortlisted) => {
        // if(isshortlisted){
        //     seterror('Already shortlisted')
        //     return
        // }
        if (isselected) {
            setselectedcandidates(selectedcandidates.filter((obj, key) => key !== candidatekey))
        } else {
            setselectedcandidates((prev) => [...prev, candidate])
        }
    }

    const gotoeditassessment = () => {
        // const updated_assessment = {
        //     role: current_role.position,
        //     city: current_role.location,
        //     commitmenttype: current_role.work_place_type,
        //     level: current_role.skill_level,
        //     role_id: current_role.id
        // }

        // dispatch(setNewassessment(updated_assessment))
        // navigate("/clientaccount/assessment-builder-3")
    }

    //go to candidate profile
    const gotocandidateprofile = (candidate_profile) => {
        openurl(`${site_baseURL}/candidate-profile/${candidate_profile.public_code}`)
    }




    return (
        <Box>
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {showassessmeentresults && <ViewassessmentResults candidate={tempcandidate} assessments={current_role.assessments} ondismiss={() => setshowassessmeentresults(false)} />}
            {confirmshortlist &&
                <Popupdialog
                    toptext={"Confirm shortlisting"}
                    question={"Are you sure you want to short list these candidates?"}
                    successtext={"Shortlist"}
                    oncancel={() => setconfirmshortlist(false)}
                    onsuccess={handleShortlisting}
                />
            }
            {confirmdeletion &&
                <Popupdialog
                    toptext={"Confirm Deletion!"}
                    question={"Are you sure you want to delete this assessment?"}
                    successtext={"Delete"}
                    oncancel={() => setconfirmdeletion(false)}
                    onsuccess={handleDeletion}
                />
            }
            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack flexDirection={"row"} alignItems={"center"}>
                    <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                    <Typography fontFamily={'boldfont'}>{current_role?.position}</Typography>
                </Stack>
                <Stack flexDirection={"row"}>
                    <Button color="warning" title="close this asessment" endIcon={<Clear />} variant="text">Close </Button>&nbsp;
                    <Button onClick={() => setconfirmdeletion(true)} title="delete assessment" endIcon={<DeleteRounded />} color="error">Delete</Button>
                </Stack>
            </Stack>
            <Box mt={2} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Box mt={2} width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                        <Box width={{ xs: "100%", lg: "23%" }}>
                            <Typography fontSize={14}>Role</Typography>
                            <Typography fontFamily={"boldfont"}>{current_role?.position}</Typography>
                        </Box>
                        <Box width={{ xs: "100%", lg: "23%" }}>
                            <Typography fontSize={14}>Skill Level</Typography>
                            <Typography fontFamily={"boldfont"}>{current_role?.skill_level}</Typography>
                        </Box>
                        <Box width={{ xs: "100%", lg: "23%" }}>
                            <Typography fontSize={14}>Commitment</Typography>
                            <Typography fontFamily={"boldfont"}>{current_role?.work_place_type}</Typography>
                        </Box>
                        <Box width={{ xs: "100%", lg: "23%" }}>
                            <Typography fontSize={14}>Location</Typography>
                            <Typography fontFamily={"boldfont"}>{current_role?.location}</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box>
                    <Typography mt={2} mb={1} fontSize={16} fontFamily={'boldfont'}>Tests</Typography>
                    <Stack flexDirection={"row"} width={"100%"} overflow={{ xs: "scroll", lg: "hidden" }} >
                        {current_role.assessments?.map((assessment, key) => {
                            const progresspercentage = Math.round((parseInt(assessment.solved) / parseInt(assessment.quizes)) * 100) || 10;

                            return (
                                <Box margin={"1%"} onClick={() => gotoeditassessment()} mr={{ xs: 2, lg: 0 }} key={key} width={{ xs: "48", lg: "30%" }} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, cursor: "pointer" }}>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.title}</Typography>
                                        <CircularWithValueLabel value={progresspercentage} />
                                    </Stack>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {assessment.description}
                                    </Typography>
                                    <hr color="silver" />
                                </Box>
                            )
                        })}
                    </Stack>

                </Box>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Typography mt={2} fontSize={16} fontFamily={'boldfont'}>Matched Candidates</Typography>
                    <Stack>
                        <Button variant="contained" onClick={() => setconfirmshortlist(true)} disabled={selectedcandidates?.length < 1} endIcon={<BookmarkAdded />} sx={{ textTransform: "none" }}>Invite</Button>
                    </Stack>
                </Stack>
                <Box mt={2}>
                    <Stack sx={muistyles.stackstyle} flexDirection={"row"}>
                        <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                            <Customcheckbox onChange={() => onselectall()} size={'small'} value={displayedcandidates.length === selectedcandidates.length} />
                            <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>Name</Typography>
                            <ArrowDownward fontSize="small" />
                        </Stack>
                        <Typography sx={muistyles.headtitle} />
                        <Typography sx={muistyles.headtitle} />
                        <Typography sx={muistyles.headtitle} />
                        <Typography sx={{ ...muistyles.headtitle, width: "10%" }} />
                    </Stack>

                    <Box mt={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        {displayedcandidates.map((candidate, key) => {
                            const candidate_profile = candidate.candidate_profile

                            const invited_candidate = invited_candidates.find((obj) => obj.id == candidate.id)
                            const isselected = selectedcandidates.find((obj) => obj.id === candidate.id)
                            const isshortlisted = invited_candidate?.id

                            return (
                                <Stack sx={muistyles.contentstackstyle} flexDirection={"row"} key={key}>
                                    <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                                        <Customcheckbox onChange={() => checkcandidate(isselected, candidate, key, isshortlisted)} size={'small'} value={isselected} />
                                        <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>{candidate.name}{candidate_profile?.specialization && `(${candidate_profile?.specialization})`}</Typography>
                                    </Stack>
                                    <Typography color={'primary'} sx={muistyles.contentheadtitle}>{candidate?.matching_percentage || 0}% Match</Typography>
                                    {isshortlisted ?
                                        <Typography sx={{ ...muistyles.contentheadtitle, cursor: "pointer" }} onClick={() => {
                                            setshowassessmeentresults(true)
                                            settempcandidate(invited_candidate)
                                        }}>
                                            {/* <Stack flexDirection={"row"}><CheckCircle titleAccess="Completed Assessment" fontSize="small" color="success" />Done</Stack> : */}
                                            <Stack flexDirection={"row"} alignItems={"center"}><AssessmentOutlined titleAccess="Results" fontSize="small" /> Assessment</Stack>
                                        </Typography> : <Typography sx={muistyles.contentheadtitle} />}
                                    <Typography onClick={() => gotocandidateprofile(candidate_profile)} sx={{ ...muistyles.contentheadtitle, cursor: "pointer", textDecorationLine: "underline" }} >View Profile</Typography>
                                    <Typography sx={{ ...muistyles.contentheadtitle, width: "10%" }} >{isshortlisted && <BookmarkAdded fontSize="small" color="primary" titleAccess="shortlisted" />}</Typography>
                                </Stack>
                            )
                        })}
                        {displayedcandidates.length < 1 &&
                            <Box pb={5}>
                                <Nodatacomponent height={100} text={'No candidates have been matched to this role'} />
                                <Stack mt={1} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                    <Button onClick={() => trial_period ? onshowupgrade() : navigate("/clientaccount/discover-candidates")} startIcon={<TravelExplore />} variant="contained">Explore Developer community</Button>
                                </Stack>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default Viewassessment

export const match_percentage = (candidate, role) => {
    let percentage = 10
    if (role.work_place_type == candidate.commitment_type) { percentage += 10 }

    return percentage
}