
export const user_pic_placeholder = require("./../../assets/images/user.png")
//google map api
export const GOOGLE_MAPS_APIKEY = ""
export const OPEN_ROUTE_SERVICE_KEY = '5b3ce3597851110001cf624856ed0b0aafbc41668c353fe149f5ee2c'
export const reduxsecretKey = '3gz5PQtuafF2vzX'
// export const rapidapikey = '53c5d6d1dcmsh87ed8969aa73430p18580djsn5adade0291d9'
export const rapidapikey = 'a37df13a78msh3ad09c6dfdc4684p14a238jsn1cff0fe1c9ec'

export const companiesemail = ["sales@kortova.com", "support@kontorva.com"];
export const phoneNumbers = ["+254 722 222 222", "254 732 222 222"];

export const site_baseURL = `${window.location.protocol}//${window.location.host}`;


//font sizes
export const textfontsize = 15