import { useMemo, useState } from "react"
import { primarycolor } from "../../../Utils/colors"
import { Box, Stack, Typography, LinearProgress, Button, Card, CircularProgress } from "@mui/material"
import Feedbackbutton from "../../../Components/feedbackbutton"
import { useNavigate } from "react-router-dom"
import { Sitenamelogo } from "../../../Common/sitelogo"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../../../store/user"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import muistyles from "../../../Utils/muistyles"
import { Erroralert } from "../../../Components/popups"
import { Updateuserinfourl } from "../../../Utils/urls"
import useAuthAxios from "../../../../hooks/useAuthAxios"


const Onboarding6 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const name = user?.name.split(' ')[0]

    const [commitment_type, setcommitment_type] = useState(user?.commitment_type || '');

    const [loading, setloading] = useState();
    const [error, seterror] = useState('');



    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            commitment_type: commitment_type
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, commitment_type }
                dispatch(setUser(updateddata))
                navigate("/candidate-onboarding-7")
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }



    const gotonext = () => {
        Postdata()
    }

    const commitmenttypes = [
        'Full-time', 'Part-time', 'As Needed', "I'd prefer to start part-time at a company then ramp up to full-time if it feels good"
    ]


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }

    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} height={"100vh"}>
                <Box width={muistyles.onboardingleft_box1} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"} sx={{ overflowY: "scroll" }}>
                    <Box width={muistyles.onboardingleft_box2}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋</Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={80} />
                    </Box>
                    <Box width={"90%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mt={4} mb={4} fontSize={30} fontFamily={"boldfont"}>
                            What type of commitment do you prefer?
                        </Typography>
                        <Box mt={3}>
                            {commitmenttypes.map((type, key) => {
                                const selected = commitment_type === type
                                const onChange = () => {
                                    if (selected) {
                                        setcommitment_type('')
                                    } else {
                                        setcommitment_type(type)
                                    }
                                }
                                return (
                                    <Box onClick={onChange} key={key} sx={{ border: selected ? '1px solid blue' : '1px solid silver', cursor: "pointer" }} padding={2} mb={2} borderRadius={2} >
                                        <Stack flexDirection={"row"} alignItems={"center"}>
                                            <Box sx={{ cursor: "pointer", alignItems: "center", display: "flex" }} mr={1}>
                                                {selected ?
                                                    <CheckBox color="primary" /> :
                                                    <CheckBoxOutlineBlank />
                                                }
                                            </Box>
                                            <Typography fontSize={{ lg: "16px", sm: "13px", xs: "13px" }}>{type}</Typography>&nbsp;
                                        </Stack>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Stack bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} sx={buttonstyle} disabled={!commitment_type} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <img style={{ maxHeight: 150 }} src={require("./../../../../assets/illustrations/commitment.png")} />
                    <Typography mt={3} fontFamily={'boldfont'} width={"80%"} color={"white"} fontSize={30} textAlign={"center"}>
                        Create more impact with a full-time commitment
                    </Typography>
                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Onboarding6