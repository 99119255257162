import { createSlice } from '@reduxjs/toolkit';
import { removeFromStore, setToStore } from '../pages/Utils/functions';
import { reduxconstants } from '../pages/Utils/arrays';

export const candidatesSlice = createSlice({
    name: reduxconstants.SAVED_CANDIDATES,
    initialState: {
        saved_candidates: null,
    },
    reducers: {
        saveCandidates: function (state, action) {
            state.saved_candidates = action.payload;
            setToStore(reduxconstants.SAVED_CANDIDATES, action.payload, false);
        },
        removeSavedCandidates: function (state) {
            state.saved_candidates = null;
            removeFromStore(reduxconstants.SAVED_CANDIDATES)
        },
    },
});

export const { saveCandidates, removeSavedCandidates } = candidatesSlice.actions;
export default candidatesSlice.reducer;
