import { useMemo, useState } from "react"
import { primarycolor } from "../../../Utils/colors"
import { Box, Stack, Typography, LinearProgress, Button, Card, CircularProgress } from "@mui/material"
import Feedbackbutton from "../../../Components/feedbackbutton"
import { useNavigate } from "react-router-dom"
import { Sitenamelogo } from "../../../Common/sitelogo"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../../../store/user"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { englishLevels } from "../../../Utils/arrays"
import muistyles from "../../../Utils/muistyles"
import useAuthAxios from "../../../../hooks/useAuthAxios"
import { Updateuserinfourl } from "../../../Utils/urls"
import { Erroralert } from "../../../Components/popups"


const Onboarding7 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const name = user?.name.split(' ')[0]

    const [loading, setloading] = useState();
    const [error, seterror] = useState('');


    const [english_level, setenglish_level] = useState(user?.english_level || '');



    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            english_level: english_level
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, english_level }
                dispatch(setUser(updateddata))
                navigate("/candidate-onboarding-8")
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }



    const gotonext = () => {
        Postdata()
    }


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }

    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} height={"100vh"}>
                <Box width={muistyles.onboardingleft_box1} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"} sx={{ overflowY: "scroll" }}>
                    <Box width={muistyles.onboardingleft_box2}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋</Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={80} />
                    </Box>
                    <Box width={"90%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mt={4} mb={4} fontSize={30} fontFamily={"boldfont"}>
                            What’s your English level?
                        </Typography>
                        <Box mt={3}>
                            {englishLevels.map((level, key) => {
                                const selected = english_level === level.label
                                const onChange = () => {
                                    if (selected) {
                                        setenglish_level('')
                                    } else {
                                        setenglish_level(level.label)
                                    }
                                }
                                return (
                                    <Box onClick={onChange} key={key} sx={{ border: selected ? '1px solid blue' : '1px solid silver', cursor: "pointer" }} padding={2} mb={2} borderRadius={2} >
                                        <Stack flexDirection={"row"}>
                                            <Box sx={{ cursor: "pointer" }} mr={1}>
                                                {selected ?
                                                    <CheckBox color="primary" /> :
                                                    <CheckBoxOutlineBlank />
                                                }
                                            </Box>
                                            <Box>
                                                <Typography color={'var(--dimtextcolor)'} fontFamily={'boldfont'} fontSize={{ lg: "18px", sm: "14px", xs: "14px" }}>{level.label}</Typography>
                                                <Typography color={'var(--dimtextcolor)'} lineHeight={'30px'} fontSize={{ lg: "16px", sm: "13px", xs: "13px" }}>{level.description}</Typography>
                                            </Box>

                                        </Stack>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Stack bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} sx={buttonstyle} disabled={!english_level} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <img style={{ height: 150, width: 150, borderRadius: "100%" }} src={require("./../../../../assets/images/manimage2.jpeg")} />
                    <Typography mt={4} width={"80%"} fontFamily={"italiclightfont"} color={"white"} fontSize={20} textAlign={"center"}>
                        "Kontorva's value extends beyond just engagements;
                        it also shines in the learning sphere. Code challenges
                        are a valuable resource for developers."
                        <br /><br />
                        Jairus &#127828;
                    </Typography>
                    <Typography color={'silver'} fontSize={15}>Fullstack Developer</Typography>
                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Onboarding7