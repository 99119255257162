import React, { useEffect, useState } from "react"
import { Box, Button, Container, Divider, IconButton, Menu, MenuItem, Modal, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder, columncolor, columncoloralt } from "../../../Utils/colors";
import { useNavigate } from "react-router-dom";
import { a11yProps, checknumber, convertTimestampToTime, handleMenuItemClick } from "../../../Utils/functions";
import { ApartmentOutlined, Clear, DeleteOutline, MoreHorizOutlined, PersonOutlineOutlined, RsvpOutlined } from "@mui/icons-material";
import muistyles from "../../../Utils/muistyles";
import { Basictableskeleton } from "../../../Components/skeletons";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import Popupdialog from "../../../Components/popupcomponent";
import { Erroralert, Successalert } from "../../../Components/popups";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Customsectiontextinput, Customselectinput } from "../../../Components/textinputs.";
import { team_roles } from "../../../Utils/arrays";
import Loadingspinner from "../../../Components/loading";
import { Deletemanageduserinviteurl, Fetchmanagedusersinvitesurl, Fetchmanagedusersurl, Postmanagedusersurl } from "../../../Utils/urls";



const UsersTeams = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [current_section, setcurrent_section] = useState(0);
    const [show_add_users, setshow_add_users] = useState(false);

    const [refresh, setrefresh] = useState(false);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Settings' }))
        }
    }, []);


    const onsuccess = () => {
        setrefresh(!refresh)
        setshow_add_users(false)

    }



    const tabTextStyle = { fontSize: 15, textTransform: "none", fontFamily: "boldfont" }

    return (
        <Box>
            {show_add_users && <Addusersmodal onsuccess={onsuccess} ondismiss={() => setshow_add_users(false)} />}
            <Box margin={"auto"} padding={2} width={"100%"} sx={{ border: boxborder, backgroundColor: 'white' }}>
                <Stack flexDirection={"row"}>
                    <Typography fontFamily={'boldfont'}>Users & Teams</Typography>
                </Stack>
            </Box >
            <Box mt={2} padding={2} sx={{ border: boxborder }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={current_section} onChange={(e, newValue) => setcurrent_section(newValue)}>
                            <Tab sx={tabTextStyle} iconPosition="start" icon={<PersonOutlineOutlined fontSize="small" />} label="Users" {...a11yProps(0)} />
                            <Tab sx={tabTextStyle} iconPosition="start" icon={<RsvpOutlined fontSize="small" />} label="Invites" {...a11yProps(1)} />
                            <Tab sx={tabTextStyle} iconPosition="start" icon={<ApartmentOutlined fontSize="small" />} label="Teams" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <Button onClick={() => setshow_add_users(true)} sx={{ maxHeight: 35 }} variant="contained" color="primary">Add Users</Button>
                </Stack>
                {current_section === 0 && <Allusers refresh={refresh} />}
                {current_section === 1 && <Invitedusers refresh={refresh} />}
            </Box>
        </Box >
    )
}

const Allusers = ({ refresh }) => {
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(true);
    const [temp_account, settemp_account] = useState('');

    const [all_users, setall_users] = useState([]);
    const [displayed_users, setdisplayed_users] = useState([]);

    //modals
    const [confirm_deletion, setconfirm_deletion] = useState(false);

    useEffect(() => {
        fetchusers()
    }, [refresh]);

    const fetchusers = () => {
        setloading(true)
        axiosInstance.get(Fetchmanagedusersurl).then((response) => {
            setloading(false)
            if (response.data.success) {
                setall_users(response.data.data.managed_users)
                setdisplayed_users(response.data.data.managed_users)
            } else {
                seterror(response.data.message)
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })
    }



    const handleDeletion = () => {
        setconfirm_deletion(false)
        setloading(true)
        axiosInstance.post('', {
            user_ids: [temp_account.id]
        }).then((response) => {
            setloading(false)
            if (!response.data.error) {
                setdisplayed_users([])
                setall_users([])
                setsuccess(response.data.message)
                setTimeout(() => {
                    fetchusers()
                }, 3000);
            } else {
                seterror(response.data.message)
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })
    }


    return (
        <Box mt={2}>
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {confirm_deletion &&
                <Popupdialog
                    toptext={"Confirm Deletion"}
                    question={"Are you sure you want remove this user from your team?"}
                    successtext={"Unlink"}
                    oncancel={() => setconfirm_deletion(false)}
                    onsuccess={handleDeletion}
                />
            }
            <TableContainer component={Paper} sx={muistyles.tablecontainerstyle} id="userstable">
                <Table stickyHeader sx={muistyles.tablestyle} size="small" aria-label="custom pagination table">
                    <TableHead sx={muistyles.tableheadstyle}>
                        <TableRow>
                            <TableCell sx={muistyles.tableheadrowstyle} >Name</TableCell>
                            <TableCell sx={muistyles.tableheadrowstyle} >User Role</TableCell>
                            <TableCell sx={muistyles.tableheadrowstyle} >Last Login</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && displayed_users.map((row, key) => {

                            return (
                                <TableRow key={key} style={{ background: checknumber(key) ? columncolor : columncoloralt }}>
                                    <TableCell style={muistyles.tablecelltyle}>
                                        <Typography fontWeight={"bolder"}>{row.name}</Typography>
                                        {row.email}
                                    </TableCell>
                                    <TableCell sx={muistyles.tablecelltyle}>{row.role || 'Owner'} </TableCell>
                                    <TableCell sx={muistyles.tablecelltyle}>  {convertTimestampToTime(row.last_login)} </TableCell>
                                    <TableCell sx={{ cursor: "pointer" }}>
                                        <PopupState variant="popover" popupId="demo-popup-menu">
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <MoreHorizOutlined {...bindTrigger(popupState)} />
                                                    <Menu {...bindMenu(popupState)}>
                                                        <MenuItem onClick={() => handleMenuItemClick(popupState, () => {
                                                            settemp_account(row)
                                                            setconfirm_deletion(true)
                                                        })}>
                                                            <Stack flexDirection={"row"} alignItems={"center"}>
                                                                <DeleteOutline fontSize="small" color="error" />
                                                                <Typography fontSize={15} ml={1}>Delete</Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                    </Menu>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {loading &&
                Array.from({ length: 10 }, (_, index) => (
                    <Basictableskeleton key={index} />
                ))
            }
            {!loading && displayed_users?.length < 1 && <Nodatacomponent text={'No users associated with your account'} />}
        </Box>
    )
}



const Invitedusers = ({ refresh }) => {
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(true);
    const [temp_account, settemp_account] = useState('');

    const [all_users, setall_users] = useState([]);
    const [displayed_users, setdisplayed_users] = useState([]);

    //modals
    const [confirm_deletion, setconfirm_deletion] = useState(false);

    useEffect(() => {
        fetchusers()
    }, [refresh]);

    const fetchusers = () => {
        setloading(true)
        axiosInstance.get(Fetchmanagedusersinvitesurl).then((response) => {
            setloading(false)
            if (response.data.success) {
                setall_users(response.data.data.managed_user_invites)
                setdisplayed_users(response.data.data.managed_user_invites)
            } else {
                seterror(response.data.message)
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })
    }



    const handleDeletion = () => {
        setconfirm_deletion(false)
        setloading(true)
        axiosInstance.delete(`${Deletemanageduserinviteurl}/${temp_account.id}`).then((response) => {
            setloading(false)
            if (response.data.success) {
                setdisplayed_users([])
                setall_users([])
                setsuccess('Deleted successfully')
                setTimeout(() => {
                    fetchusers()
                }, 3000);
            } else {
                seterror('An error occurred while deleting your record')
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })
    }


    return (
        <Box mt={2}>
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {confirm_deletion &&
                <Popupdialog
                    toptext={"Confirm Deletion"}
                    question={"Are you sure you want to delete this invite?"}
                    successtext={"Delete"}
                    oncancel={() => setconfirm_deletion(false)}
                    onsuccess={handleDeletion}
                />
            }
            <TableContainer component={Paper} sx={muistyles.tablecontainerstyle} id="userstable">
                <Table stickyHeader sx={muistyles.tablestyle} size="small" aria-label="custom pagination table">
                    <TableHead sx={muistyles.tableheadstyle}>
                        <TableRow>
                            <TableCell sx={muistyles.tableheadrowstyle} >Id</TableCell>
                            <TableCell sx={muistyles.tableheadrowstyle} >Email</TableCell>
                            <TableCell sx={muistyles.tableheadrowstyle} >Status</TableCell>
                            <TableCell sx={muistyles.tableheadrowstyle} >invite at</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && displayed_users.map((row, key) => {

                            return (
                                <TableRow key={key} style={{ background: checknumber(key) ? columncolor : columncoloralt }}>
                                    <TableCell sx={muistyles.tablecelltyle}>{key + 1} </TableCell>
                                    <TableCell sx={muistyles.tablecelltyle}>{row.email} </TableCell>
                                    <TableCell sx={muistyles.tablecelltyle}>{row.status} </TableCell>
                                    <TableCell sx={muistyles.tablecelltyle}>  {convertTimestampToTime(row.created_at)} </TableCell>
                                    <TableCell sx={{ cursor: "pointer" }}>
                                        <PopupState variant="popover" popupId="demo-popup-menu">
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <MoreHorizOutlined {...bindTrigger(popupState)} />
                                                    <Menu {...bindMenu(popupState)}>
                                                        <MenuItem onClick={() => handleMenuItemClick(popupState, () => {
                                                            settemp_account(row)
                                                            setconfirm_deletion(true)
                                                        })}>
                                                            <Stack flexDirection={"row"} alignItems={"center"}>
                                                                <DeleteOutline fontSize="small" color="error" />
                                                                <Typography fontSize={15} ml={1}>Delete</Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                    </Menu>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {loading &&
                Array.from({ length: 10 }, (_, index) => (
                    <Basictableskeleton key={index} />
                ))
            }
            {!loading && displayed_users?.length < 1 && <Nodatacomponent text={'No users have been invited'} />}
        </Box>
    )
}





const Addusersmodal = ({ ondismiss, onsuccess }) => {
    const axiosInstance = useAuthAxios()
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [name, setname] = useState('');
    const [email, setemail] = useState('');

    const [confirm_addition, setconfirm_addition] = useState(false);



    const handleAddition = () => {
        setconfirm_addition(false)
        setloading(true)
        axiosInstance.post(Postmanagedusersurl, {
            name: name,
            email: email
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                setsuccess('invited successfully')
                setTimeout(() => {
                    onsuccess()
                }, 3000);
            } else {
                seterror(response.data.message)
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })
    }

    return (
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 1 }}>
                {loading && <Loadingspinner />}
                {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
                {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
                {confirm_addition &&
                    <Popupdialog
                        toptext={"Confirm Invite"}
                        question={"Are you sure you want add these user to your team?"}
                        successtext={"Add user"}
                        oncancel={() => setconfirm_addition(false)}
                        onsuccess={handleAddition}
                    />
                }
                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Typography fontFamily={"boldfont"} fontSize={18}>Invite Users</Typography>
                    <IconButton size="small" sx={{ backgroundColor: 'silver', ml: 2 }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Divider sx={{ mt: 1 }} />
                <Box mt={2} component={'form'} onSubmit={(event) => {
                    event.preventDefault()
                    setconfirm_addition(true)
                }}>
                    <Customsectiontextinput
                        fieldname={'Full Name'}
                        hint={'i.e john doe'}
                        value={name}
                        onChange={setname}
                        width={"100%"}
                        required={true}
                    />
                    <Customsectiontextinput
                        fieldname={'Email'}
                        hint={'i.e john@example.com'}
                        value={email}
                        onChange={setemail}
                        width={"100%"}
                        required={true}
                    />
                    <Button sx={{ mt: 5 }} type="submit" variant="contained" fullWidth>Add User</Button>
                </Box>
            </Container>
        </Modal>
    )
}





export default UsersTeams