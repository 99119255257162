import React, { useState } from "react";
import { Box, Button, CircularProgress, Container, IconButton, MenuItem, Modal, Popover, Stack, TextField, Typography } from "@mui/material"
import { Custommuiautocomplete, Customreactselectinput, Customsectiontextinput } from "../../../Components/textinputs.";
import { Add, Clear, ClearOutlined, HelpOutline, Remove } from "@mui/icons-material";
import Popupdialog from "../../../Components/popupcomponent";
import muistyles from "../../../Utils/muistyles";
import { Postcandidateskillsurl } from "../../../Utils/urls";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Erroralert, Skillspopup, Successalert } from "../../../Components/popups";
import { useDispatch, useSelector } from "react-redux";
import { cloudPlatformsArray, databaseTechnologiesArray, programminglanguagesarray, programmingLanguagesFrameworks, skilllevels } from "../../../Utils/arrays";
import { setUser } from "../../../../store/user";

//add skills modal modal
export const Addskillsmodal = ({ ondismiss }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);

    const [confirmsave, setconfirmsave] = useState(false);
    const axiosInstance = useAuthAxios()

    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const candidate_skills = user?.candidate_skills

    // Inputs
    const [selectedItems, setSelectedItems] = useState({
        frameworks: candidate_skills?.filter((obj) => obj.skill_type === 'frameworks') || [],
        languages: candidate_skills?.filter((obj) => obj.skill_type === 'languages') || [],
        databaseTechnologies: candidate_skills?.filter((obj) => obj.skill_type === 'databaseTechnologies') || [],
        cloudTechnologies: candidate_skills?.filter((obj) => obj.skill_type === 'cloudTechnologies') || []
    });

    // Modals
    const [showskillspoopup, setShowsSkillspopup] = useState(false);

    const categoryOptions = {
        frameworks: programmingLanguagesFrameworks,
        languages: programminglanguagesarray,
        databaseTechnologies: databaseTechnologiesArray,
        cloudTechnologies: cloudPlatformsArray
    };

    const handleCategoryChange = (category, tempItem) => {
        setSelectedItems(prevState => {
            const updatedItems = [...prevState[category]];
            const index = updatedItems?.findIndex(item => item.label === tempItem.label);

            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push({ ...tempItem, skill_level: skilllevels[0].label });
            }

            return { ...prevState, [category]: updatedItems };
        });
    };

    //change years of experience
    const handleYearsChange = (category, index, operation) => {
        setSelectedItems(prevState => {
            const updatedItems = [...prevState[category]];
            if (operation === 'add') {
                updatedItems[index].years = (updatedItems[index].years || 0) + 1;
            } else if (operation === 'subtract') {
                updatedItems[index].years = Math.max((updatedItems[index].years || 0) - 1, 0);
            }

            return { ...prevState, [category]: updatedItems };
        });
    };

    //change skill level
    const handleSkillLevelChange = (category, index, level) => {
        setSelectedItems(prevState => {
            const updatedItems = [...prevState[category]];
            updatedItems[index].skill_level = level;

            return { ...prevState, [category]: updatedItems };
        });
    };

    //function to remove skill item
    const handleRemoveItem = (category, index) => {
        setSelectedItems(prevState => {
            const updatedItems = prevState[category].filter((_, key) => key !== index);
            return { ...prevState, [category]: updatedItems };
        });
    };



    const Postdata = () => {
        const spreaded_items = { ...selectedItems }

        const final_skills_array = [
            ...(spreaded_items.frameworks.map((val) => ({ ...val, skill_type: 'frameworks', skill_id: Math.random() }))),
            ...(spreaded_items.languages.map((val) => ({ ...val, skill_type: 'languages', skill_id: Math.random() }))),
            ...(spreaded_items.databaseTechnologies.map((val) => ({ ...val, skill_type: 'databaseTechnologies', skill_id: Math.random() }))),
            ...(spreaded_items.cloudTechnologies.map((val) => ({ ...val, skill_type: 'cloudTechnologies', skill_id: Math.random() }))),
        ]

        setloading(true)
        axiosInstance.post(Postcandidateskillsurl, {
            skills: final_skills_array
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, candidate_skills: final_skills_array.map((val) => ({ ...val, skill_name: val.label })) };
                dispatch(setUser(updateddata));
                setsuccess("Updated successfully")
                setTimeout(() => {
                    setsuccess('')
                    ondismiss()
                }, 3000);
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }



    //check completion
    const checkcompletion = () => {
        // Check if any of the selected items are empty
        if (
            selectedItems.frameworks.length < 1 ||
            selectedItems.languages.length < 1 ||
            selectedItems.databaseTechnologies.length < 1 ||
            selectedItems.cloudTechnologies.length < 1
        ) {
            return true;
        }

        // Check if any item has less than 1 year or no year specified
        if (
            selectedItems.frameworks.filter((obj) => obj.years < 1 || !obj.years).length > 0 ||
            selectedItems.languages.filter((obj) => obj.years < 1 || !obj.years).length > 0 ||
            selectedItems.databaseTechnologies.filter((obj) => obj.years < 1 || !obj.years).length > 0 ||
            selectedItems.cloudTechnologies.filter((obj) => obj.years < 1 || !obj.years).length > 0
        ) {
            return true;
        }

        // If everything is valid, return false
        return false;
    };


    const handleSubmit = () => {
        setconfirmsave(false)
        Postdata()
    }


    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"lg"} sx={{ backgroundColor: 'white', mt: 3, borderRadius: 2, pb: 3, pt: 1 }}>
                {confirmsave &&
                    <Popupdialog
                        toptext={"Confirm Details!!"}
                        question={"Are you sure you want to update your details?"}
                        successtext={"Save skills"}
                        oncancel={() => setconfirmsave(false)}
                        onsuccess={handleSubmit}
                    />
                }
                {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
                {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
                {showskillspoopup && <Skillspopup ondismiss={() => setShowsSkillspopup(false)} />}
                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Typography fontSize={16} fontFamily={"boldfont"}>Add your Skills</Typography>
                    <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Box paddingTop={3} position={"relative"} height={"80vh"}>
                    <Box width={"100%"} sx={{ overflowY: 'scroll' }} pb={10} height={"80%"}>
                        <Typography mt={4} fontSize={30} fontFamily={"boldfont"}>What’s your tech Stack?</Typography>
                        <Typography mt={3} mb={5} fontSize={17} color={'gray'}>
                            Add all technologies and skills you're good at. You may be tested on these later to ensure a good fit with Kontorva
                        </Typography>

                        {Object.keys(categoryOptions).map((category, idx) => (
                            <Box key={idx} sx={muistyles.skillsbox}>
                                <Stack flexDirection={"row"} alignItems={"center"}>
                                    <Button sx={muistyles.ovalbutton} variant="outlined">Required</Button>
                                    <Typography color={'gray'} ml={2}>
                                        Add at least 1 {category.replace(/([A-Z])/g, ' $1').toLowerCase()}
                                    </Typography>
                                </Stack>
                                <Box sx={muistyles.frameworksbox}>
                                    <Typography fontFamily={'boldfont'}>{category.replace(/([A-Z])/g, ' $1').toUpperCase()}</Typography>
                                    <Box mt={2} display={"flex"} flexWrap={'wrap'} alignItems={"center"}>
                                        <Custommuiautocomplete
                                            onChange={(e) => handleCategoryChange(category, e)}
                                            options={categoryOptions[category]}
                                            width={"40%"}
                                        />&nbsp;&nbsp;
                                        {categoryOptions[category].slice(0, 5).map((val, key) => {
                                            const selected = selectedItems[category]?.findIndex(item => item.label === val.label) > -1;
                                            return (
                                                <Button
                                                    sx={{ borderRadius: 10, margin: "8px 10px 8px 0px", height: 40 }}
                                                    color={selected ? 'primary' : 'secondary'}
                                                    key={key}
                                                    onClick={() => handleCategoryChange(category, val)}
                                                    variant={selected ? 'contained' : "outlined"}
                                                >{val.label}</Button>
                                            );
                                        })}
                                    </Box>
                                    <Box mt={3} width={'100%'}>
                                        {selectedItems[category]?.length > 0 &&
                                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                                <Box width={{ xs: "0%", lg: "30%" }} />
                                                <Typography width={{ xs: "45%", lg: "25%" }} whiteSpace={'nowrap'}>Years of Experience</Typography>
                                                <Stack width={{ xs: "40%", lg: "20%" }} flexDirection={"row"}>
                                                    <Typography>Skill Level</Typography>&nbsp;
                                                    <HelpOutline onClick={() => setShowsSkillspopup(true)} style={{ cursor: "pointer" }} />
                                                </Stack>
                                                <Box width={{ xs: "0%", lg: "20%" }} />
                                            </Stack>
                                        }
                                        {selectedItems[category]?.map((item, key) => {
                                            const currentYears = item?.years;
                                            const options = skilllevels.map((val) => val.label);

                                            return (
                                                <Stack flex={1} key={key} mt={2} mb={2} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Typography width={"30%"} fontFamily={'boldfont'}>{item.label || item.skill_name}</Typography>
                                                    <Stack justifyContent={"space-between"}
                                                        sx={{
                                                            backgroundColor: 'white',
                                                            borderRadius: 8,
                                                            border: '1px solid silver'
                                                        }}
                                                        flexDirection={"row"} width={"25%"} alignItems={"center"}>
                                                        <IconButton disabled={!currentYears || currentYears === 0} onClick={() => handleYearsChange(category, key, 'subtract')}><Remove /></IconButton>
                                                        <Typography>{currentYears || 0}</Typography>
                                                        <IconButton onClick={() => handleYearsChange(category, key, 'add')}><Add /></IconButton>
                                                    </Stack>
                                                    <TextField select id="outlined-basic"
                                                        sx={{ width: "35%" }}
                                                        variant="outlined" size="small"
                                                        value={item?.skill_level}
                                                        onChange={(e) => handleSkillLevelChange(category, key, e.target.value)} >
                                                        {options.map((option, index) => (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <IconButton onClick={() => handleRemoveItem(category, key)} color="primary"><ClearOutlined /></IconButton>
                                                </Stack>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Stack sx={{ backgroundColor: 'white' }} bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"right"}>
                        <Button onClick={() => setconfirmsave(true)} disabled={checkcompletion()} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Save'}</Button>
                    </Stack>
                </Box>

            </Container>
        </Modal>
    )
}

