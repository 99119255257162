import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Sitelogo from "./sitelogo";
import { LoginOutlined } from "@mui/icons-material";
import { isMobile } from "react-device-detect";



const Landingscreen = () => {
    const navigate = useNavigate()

    const [usertype, setusertype] = useState('');

    const [imageWidth, setImageWidth] = useState(0);

    const handleImageLoad = (event) => {
        const imgWidth = event.target.width;
        setImageWidth(imgWidth);
    };

    //got to login page
    const gotologin = () => {
        navigate('/login', { state: { usertype: usertype } })
    }


    //go to sign up
    const gottosignup = () => {
        if (usertype === 'CLIENT') {
            navigate('/clientsignup')
        }
        if (usertype === 'CANDIDATE') {
            navigate("/candidatesignup")
        }
    }


    return (
        <Box position={'relative'}>
            {usertype &&
                <Box
                    position={"absolute"}
                    right={"2%"}
                    top={'1%'}
                    zIndex={5}
                >
                    <Button onClick={() => gotologin()} endIcon={<LoginOutlined />} variant="contained">Login</Button>
                </Box>
            }
            <Stack flexDirection={"row"}  >
                <Box width={{
                    lg: "70%",
                    md: "40%",
                    sm: "100%"
                }} sx={{ backgroundColor: 'white', height: "130vh" }}>
                    <Container>
                        <Sitelogo height={isMobile && "50px"} type={'white'} />
                        <Typography mt={5} fontSize={{ lg: 35, xs: 25, sm: 25 }} fontFamily={'boldfont'}>How do you want to<br /> use Kontorva?</Typography>
                        <Typography color={"gray"} fontSize={{ lg: 18, sm: 14, xs: 14 }} mt={1}>We’ll personalize your setup experience accordingly.</Typography>
                        <br />
                        <Stack padding={2} width={{
                            sm: "100%",
                            md: "60%",
                            lg: "50%"
                        }} sx={{ cursor: "pointer", borderRadius: 2, border: usertype === 'CLIENT' && "1px solid black" }} onClick={() => setusertype('CLIENT')} flexDirection={"row"} alignItems={"center"}>
                            <img className="usertypeicon" src={require("./../../assets/images/search.png")} />
                            <Box ml={2}>
                                <Typography fontFamily={"boldfont"}>I'm here to hire tech talent</Typography>
                                <Typography>Evaluate tech skills at scale</Typography>
                            </Box>
                        </Stack><br />
                        <Stack padding={2} width={{
                            sm: "100%",
                            md: "60%",
                            lg: "50%"
                        }} sx={{ cursor: "pointer", borderRadius: 2, border: usertype === 'CANDIDATE' && "1px solid black" }} onClick={() => setusertype('CANDIDATE')} flexDirection={"row"} alignItems={"center"}>
                            <img className="usertypeicon" src={require("./../../assets/images/code.png")} />
                            <Box ml={2}>
                                <Typography fontFamily={"boldfont"}>I'm here to practice and prepare</Typography>
                                <Typography>Sharpen my skills and showcase my expertise</Typography>
                            </Box>
                        </Stack>
                        <br /><br />
                        {usertype && <Button variant="contained" onClick={() => gottosignup()} > Create Account</Button>}
                    </Container>
                </Box>
                <Box display={{
                    xs: "none",
                    md: "block",
                    lg: "block"
                }} width={"404px"} sx={{ backgroundColor: '#161616', height: "130vh", right: 0, position: "absolute" }}>
                    {usertype === 'CLIENT' &&
                        <img onLoad={handleImageLoad} className="floatinglandingimage" src={require('./../../assets/images/hire.png')} />
                    }
                    {usertype === 'CLIENT' &&
                        <img className="floatinglandingimage2" src={require('./../../assets/images/coding.png')} />
                    }
                    {usertype === 'CANDIDATE' &&
                        <img className="floatinglandingimage" src={require('./../../assets/images/practice.png')} />
                    }
                </Box>
            </Stack>
        </Box>
    );
};

export default Landingscreen;



