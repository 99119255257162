import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import userprofilesReducer from "./userprofiles"
import newassessmentReducer from "./newassessment"
import saved_candidates from './saved_candidates';

export default configureStore({
	reducer: {
		user: userReducer,
		profiles: userprofilesReducer,
		newassessment: newassessmentReducer,
		saved_candidates: saved_candidates
	},
});
