import React, { useState, useEffect, useRef } from "react";
import Popupdialog from "../../../Components/popupcomponent";
import { Box, Button, Container, Modal, Stack, Typography } from "@mui/material";
import { base64tofile, checkimageextension, getBase64 } from "../../../Utils/functions";
import { Cropper } from "react-cropper";
import 'cropperjs/dist/cropper.css';
import Loadingspinner from "../../../Components/loading";
import { Erroralert, Successalert } from "../../../Components/popups";
import { useDispatch, useSelector } from "react-redux";
import { DriveFileRenameOutline, UploadFile } from "@mui/icons-material";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Postimageurl } from "../../../Utils/urls";
import { setUser } from "../../../../store/user";


const Editprofilephoto = ({ ondismiss }) => {
    const axiosInstance = useAuthAxios()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);

    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    //booleans
    const [loading, setloading] = useState(false);
    const [confirmsave, setconfirmsave] = useState(false);

    const [profileurl, setprofileurl] = useState(user?.profile_picture_url);
    const [profilepic, setprofilepic] = useState('');


    const [profilepicname, setprofilepicname] = useState('');
    const [picturestringtocrop, setpicturestringtocrop] = useState('');
    const [temppicturestring, settemppicturestring] = useState('');

    const cropperRef = useRef(null);
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        settemppicturestring(cropper.getCroppedCanvas().toDataURL());
    };


    const handleSubmit = () => {
        setconfirmsave(false);
        seterror(null);
        setloading(true);

        // Create a new FormData instance
        const formData = new FormData();
        formData.append("profile_picture", profilepic);

        axiosInstance.post(Postimageurl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(function (response) {
            setloading(false);
            if (response.data?.success) {
                setsuccess('Profile pic updated successfully');
                setTimeout(() => {
                    setsuccess('');
                    dispatch(setUser({ ...user, profile_picture_url: response.data.data.profile_picture_url }));
                    ondismiss()
                }, 2000);
            }
        }).catch(function (error) {
            setloading(false);
            seterror(error?.message || "There was an error, please try again later.");
        });
    };




    //set profile pic url
    const onchangeprofilepic = (pic) => {
        if (pic) {
            if (!checkimageextension(pic.name)) {
                seterror("Unsupported Format")
                setTimeout(() => { seterror() }, 2000);
            } else {
                setprofilepicname(pic.name)
                getBase64(pic, (result) => {
                    setpicturestringtocrop(result)
                })
            }
        }
    }
    //finish profile pic croppings
    const finishcropping = () => {
        setprofilepic(base64tofile(temppicturestring, profilepicname))
        setprofileurl(temppicturestring)
        setpicturestringtocrop()
        settemppicturestring()
    }


    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 1 }}>
                {confirmsave &&
                    <Popupdialog
                        toptext={"Confirm Update"}
                        question={"Are you sure you want to set this image as your profile pic?"}
                        successtext={"Set image"}
                        oncancel={() => setconfirmsave(false)}
                        onsuccess={handleSubmit}
                    />
                }
                {loading && <Loadingspinner />}
                {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
                {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
                <Box>
                    <Typography >Profile Pic</Typography>
                    <Box>
                        {!picturestringtocrop &&
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <img className="imageavatar" src={profileurl || require('./../../../../assets/images/user.png')} alt="Profile" />
                                {profilepic && <Button sx={{ height: 40 }} onClick={() => setconfirmsave(true)} variant="contained" startIcon={<UploadFile />}>upload</Button>}
                            </Stack>}
                        <div style={{ display: "flex", marginLeft: 20 }}>
                            <div>
                                {picturestringtocrop &&
                                    <Cropper
                                        src={picturestringtocrop}
                                        style={{ height: 300, width: "100%" }}
                                        aspectRatio={12 / 12}
                                        guides={false}
                                        crop={onCrop}
                                        ref={cropperRef}
                                    />
                                }
                                {picturestringtocrop && <Button sx={{ mt: 2, mb: 2, mr: 1 }} onClick={() => finishcropping()} variant="contained" >DONE</Button>}
                                <label htmlFor="file-input" style={{ display: 'inline-block' }}>
                                    <input
                                        id="file-input"
                                        type="file"
                                        accept="image/*" // Specify accepted file types if needed
                                        style={{ display: 'none' }}
                                        onChange={(e) => onchangeprofilepic(e.target.files[0])}
                                    />
                                    <Button startIcon={<DriveFileRenameOutline />} sx={{ textTransform: "none" }} variant="text" component="span">
                                        Choose image
                                    </Button>
                                </label>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Container>
        </Modal>
    )
}

export default Editprofilephoto