import { useMemo, useState } from "react"
import { primarycolor } from "../../../Utils/colors"
import { Box, Stack, Typography, LinearProgress, Button, Card, IconButton, CircularProgress } from "@mui/material"
import Feedbackbutton from "../../../Components/feedbackbutton"
import { useNavigate } from "react-router-dom"
import { Sitenamelogo } from "../../../Common/sitelogo"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../../../store/user"
import { Customsectiontextinput } from "../../../Components/textinputs."
import { CheckOutlined, Clear, CloudUpload } from "@mui/icons-material"
import { compressImage } from "../../../Utils/functions"
import { Erroralert } from "../../../Components/popups"
import muistyles from "../../../Utils/muistyles"
import Successspinner from "../../../Components/successspinner"
import useAuthAxios from "../../../../hooks/useAuthAxios"
import { Updateuserinfourl } from "../../../Utils/urls"


const Onboarding8 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const name = user?.name.split(' ')[0]

    const [error, seterror] = useState('')
    const [loading, setloading] = useState(false);
    const [showsuccess, setshowsuccess] = useState(false);

    const [linkdin, setlinkdin] = useState(user?.linkdin || '');
    const [github, setgithub] = useState(user?.github || '');
    const [custom_link, setcustom_link] = useState(user?.custom_link || '');

    const [files, setfiles] = useState([]);




    //pick file
    const onpickfile = (file) => {
        if (files.length > 5) {
            seterror('Maximum number of attachments reached')
            setTimeout(() => { seterror('') }, 3000);
        } else {
            if (file) {//check if a file has been picked
                var filename = file.name;
                var fileindex = files.findIndex(obj => obj?.name == filename);
                if (fileindex > -1) {//check if the file has already been selected
                    seterror('file already selected')
                    setTimeout(() => { seterror('') }, 3000);
                } else {
                    const object = {
                        name: file.name,
                        value: file,
                        url: URL.createObjectURL(file),
                        type: 'file',
                    }
                    setfiles([...files, object])
                }
            }
        }
    }


    //remove file
    const removefile = (index) => {
        setfiles(files.filter((obj, key) => key !== index))
    }



    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            linkdin: linkdin,
            github: github,
            custom_link: custom_link,
            files: files
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, linkdin, github, custom_link, files }
                dispatch(setUser(updateddata))
                setshowsuccess(true)
                setTimeout(() => {
                    setshowsuccess(false)
                    navigate("/candidateaccount")
                }, 4000);
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }



    const gotonext = () => {
        Postdata()
    }


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }

    return (
        <Box>
            {showsuccess && < Successspinner message={"Congratulations. You're all set"} />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <input
                accept=".pdf,.doc,.docx"
                id="anyfile"
                type="file"
                name={"anyfile"}
                onInput={(e) => onpickfile(e.target.files[0])}
                style={{ display: "none" }}
            />
            <Stack flexDirection={"row"} height={"100vh"}>
                <Box width={muistyles.onboardingleft_box1} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"} sx={{ overflowY: "scroll" }}>
                    <Box width={muistyles.onboardingleft_box2}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋</Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={90} />
                    </Box>
                    <Box width={"90%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mt={4} mb={2} fontSize={30} fontFamily={"boldfont"}>
                            Can you add your links or files?
                        </Typography>
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <CheckOutlined color="success" />&nbsp;
                            <Typography fontSize={13}>Improves your chances of being accepted to Kontorva</Typography>
                        </Stack>
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <CheckOutlined color="success" />&nbsp;
                            <Typography fontSize={13}> Your application won’t be visible to your network.s</Typography>
                        </Stack>
                        <Box mt={3}>
                            <Customsectiontextinput
                                fieldname={'LinkedIn *'}
                                value={linkdin}
                                onChange={setlinkdin}
                                width={{ xs: "95%", lg: "70%" }}
                                autoComplete={'linkedin'}
                            />
                            <Customsectiontextinput
                                fieldname={'GitHub, GitLab or Atlassian Community link'}
                                value={github}
                                onChange={setgithub}
                                width={{ xs: "95%", lg: "70%" }}
                            />
                            <Typography fontSize={13} fontFamily={'boldfont'}>Custom file</Typography>
                            <label htmlFor="anyfile"  >
                                <Stack mb={2} border={'1px solid gray'} width={{ xs: "95%", lg: "70%" }} minHeight={40} borderRadius={2} sx={{ cursor: "pointer" }} position={'relative'}>
                                    {files?.length === 0 && <IconButton sx={{ position: "absolute", right: 0 }}><CloudUpload /></IconButton>}
                                    <Stack display={'flex'} flexWrap={'wrap'}>
                                        {files?.map((file, key) => {
                                            return (
                                                <Stack sx={{ backgroundColor: '#f5f5ff' }} flexDirection={"row"} key={key} margin={"3px 5px"} alignItems={'center'}>
                                                    <Typography>{file.name}</Typography>&nbsp;
                                                    <Clear color="primary" fontSize="15px" onClick={(e) => {
                                                        e.stopPropagation(); // Prevent triggering file pick on remove
                                                        removefile(key);
                                                    }} />
                                                </Stack>
                                            )
                                        })}
                                    </Stack>
                                </Stack>
                            </label>
                            <Customsectiontextinput
                                fieldname={'Custom Link'}
                                value={custom_link}
                                onChange={setcustom_link}
                                width={{ xs: "95%", lg: "70%" }}
                            />

                        </Box>
                    </Box>
                    <Stack bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} disabled={!linkdin || !github} sx={buttonstyle} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <img style={{ height: 150, width: 150, borderRadius: "100%" }} src={require("./../../../../assets/images/counter.png")} />
                    <Typography mt={4} width={"80%"} fontFamily={"italiclightfont"} color={"white"} fontSize={25} textAlign={"center"}>
                        Open clients available in Kontorva in 2024
                    </Typography>
                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Onboarding8