import React from "react";

export const base_url = "https://api.kontorva.com/api/";
export const idebase_url = "https://ide-engine.kontorva.com/"

// export const base_url = "http://192.168.0.107:8000/api/";

//common links
//linkedin login
export const Loginwithlinkedinurl = base_url + "linkedin-auth/redirect"
//login with google
export const Loginwithgoogle = base_url + "auth/redirect"
//user Create account url
export const Registeruserurl = base_url + "create-account";
//verify regoster code
export const Verifyregistercodeurl = base_url + "verify-email"
//resend register code
export const Resendregistercodeurl = base_url + "send-verification"
//user login url
export const Loginuserurl = base_url + "sign-in";
//lpgout user
export const Logoutuserurl = base_url + "account-logout"
//user updateuserinfo url
export const Updateuserinfourl = base_url + "account-profile";
//sync user data
export const Syncuserdataurl = base_url + "syncuserdetails";
//send reset pass request
export const Sendrequestpassurl = base_url + "initiate-reset-password";
//validate reset passcode
export const Validatepasscodeurl = base_url + "validateresetcode"
//validate reset passcode
export const Resetpassurl = base_url + "complete-reset-password"
//change password
export const Changepasswordurl = base_url + "user/update-password"
//delete account
export const Deleteaccounturl = base_url + "deleteaccount"
//restoreaccount
export const Restoreaccounturl = base_url + "restoreuser"
//post image
export const Postimageurl = base_url + "upload-profile"
//fetch assets url
export const Fetchassetsurl = base_url + "assets/"
//get account profile
export const Fetchaccountprofileurl = base_url + "user"
//send feedback url
export const Sendfeedbackurl = base_url + "feedback"
//fetch notifications
export const Fetchnotificationsurl = base_url + "notification"
//mark notification as read
export const Readnotificationurl = base_url + "mark-notification"


//CANDIDATES
//candidate education
export const CandidateEducationurl = base_url + "candidate-education"
//post user skills
export const Postcandidateskillsurl = base_url + "candidate-skill"
//delete candidate skills
export const Deletecandidatesskillsurl = base_url + "user-skills/batch"
//fetch candidates
export const Fetchcandidatesurl = base_url + "fetch-candidates"
//fetch candidae profile
export const Fetchcandidateprofileurl = base_url + "profiles/"
//get results
export const Fetchassessmentresultsurl = base_url + "get-results"
//get maching candidates
export const Getmatchingcandidatesurl = base_url + "get-role-matching-candidates"



//assessments
//assessment candidate role
export const Createassessmentcandidateroleurl = base_url + "assessment-candidate-role"
//execute code url
export const Executecodeurl = idebase_url + "submissions"
//post assessment url
export const Postassessmenturl = base_url + "add_assessment"
//fetch assessments
export const Fetchassesmentsurl = base_url + "assessment"
//post assessment questions
export const Postassessmentquestionsurl = base_url + "test-question"
//role invites
export const Fetchrolesinvitesurl = base_url + "assessment-invite"
//fetch assessment questions
export const Fetchassessmentsquestionsurl = base_url + "assessments"
//post question answer
export const Postquestionanswerurl = base_url + "candidate-submissions"
//grade assessment
export const Gradeassessmenturl = base_url + "grade-assessment"
//fetch assessments done
export const Fetchassessmentsdoneurl = base_url + "check-assessment-score"



//client
//post client skills
export const Postclientskillsurl = base_url + "assessment_candidate_skill"
//fetch roles
export const Fetchcandidaterolesurl = base_url + "assessment-candidate-role"
//shortlist candidates
export const Shortlistcandidatesurl = base_url + "shortlist-candidate"
//fetch shortlisted candidates 
export const Fetchshortlistedcandidatesurl = base_url + "get-shortlisted-candidates"
//invite candidates to a role
export const Invitecandidateurl = base_url + "assessment-invite"
//link assessment with role
export const Linkroleassessmenturl = base_url + "create-role-assessments"
//get candidate assessments
export const Getcandidateassessmentsurl = base_url + "get-candidate-assessments"
//submit review
export const Submitreviewurl = base_url + "submit-review"
//fetch payment plans
export const Fetchpaymentplansurl = base_url + "payment-plan"
//initiate payment 
export const Initiatepaymenturl = base_url + "payments/stripe/create-checkout-session"
//get subscription status
export const Getsubscriptionstatusurl = base_url + "subscription-status"
//account preferences
export const Accountpreferencesurl = base_url + "user-preference"
//get managed users
export const Fetchmanagedusersurl = base_url + "managed-users"
//poat managed users
export const Postmanagedusersurl = base_url + "managed-user/invite"
//invites
export const Fetchmanagedusersinvitesurl = base_url + "managed-user/invites"
//delete managed user invite
export const Deletemanageduserinviteurl = base_url + "managed-invite/delete"
//managed user accept invitation
export const Manageduseracceptinviteurl = base_url + "managed-user/accept"
//redeem coupon
export const Redeemcouponurl = base_url + "coupon-redeem"














