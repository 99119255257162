import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import { useNavigate } from "react-router-dom";
import { AccessAlarms, ArrowRightAlt, BookmarkOutlined, KeyboardBackspace, LocationOn } from "@mui/icons-material";
import { convertTimestampToTime } from "../../../Utils/functions";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Fetchrolesinvitesurl } from "../../../Utils/urls";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Assessmentskeleton } from "../../../Components/skeletons";

const Allmyassessments = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [loading, setloading] = useState(true);

    const [invited_roles, setinvited_roles] = useState([]);


    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Roles' }))
        }
        fetchpredata()
    }, []);

    const fetchpredata = async () => {
        setloading(true)
        const [rolesResponse] = await Promise.all([
            axiosInstance.get(Fetchrolesinvitesurl)
        ])

        setloading(false)
        if (rolesResponse.data) {
            const received_roles = rolesResponse.data.data.invites
            setinvited_roles(received_roles)
        }
    }






    const gottorole = (role) => {
        navigate("/candidateaccount/invited-role", { state: { invited_role: role } })

    }

    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Invited Roles</Typography>
            </Stack>
            <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                {!loading && invited_roles?.map((invite, key) => {
                    const role = invite.role

                    return (
                        <Box position={"relative"} margin={"1%"} key={key} width={{ xs: "48", lg: "30%" }} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, cursor: "pointer" }}>
                            <IconButton sx={{ right: 0, top: 0, position: "absolute" }} size="small"><BookmarkOutlined /></IconButton>
                            <Stack flexDirection={"row"} justifyContent={"space-between"} >
                                <Typography width={"60%"} fontFamily={"boldfont"}>{role.position}({role.skill_level})</Typography>
                            </Stack>
                            <hr color="silver" />
                            <Stack mt={1} justifyContent={"space-between"}>
                                <Stack flexDirection={"row"} alignItems={"center"}>
                                    <LocationOn color="primary" fontSize="13px" />
                                    <Typography ml={1} fontSize={13}>{role.location}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"}>
                                    <AccessAlarms color="primary" fontSize="13px" />
                                    <Typography ml={1} fontSize={13}>{role.work_place_type}</Typography>
                                </Stack>
                            </Stack>
                            <Stack mt={1} alignItems={"center"} flexDirection={"row"} justifyContent={"space-between"}>
                                <Button onClick={() => gottorole(invite)} endIcon={<ArrowRightAlt />} color="secondary" variant="outlined" sx={{ textTransform: "none", height: 25 }}>View Role</Button>
                                <Typography fontFamily={"italiclightfont"} fontSize={12}>{convertTimestampToTime(role.created_at)}</Typography>
                            </Stack>
                        </Box>
                    )
                })}
                {loading && Array.from({ length: 10 }, (_, index) => (
                    <Assessmentskeleton key={index} />
                ))}
                {!loading && invited_roles.length < 1 &&
                    <Box width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        <Nodatacomponent height={200} text={"You have no invited roles yet"} />
                    </Box>
                }
            </Stack >

        </Box >
    )
}

export default Allmyassessments