import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import CircularWithValueLabel from "../../../Components/circularprogress";
import muistyles from "../../../Utils/muistyles";
import { AddCircleOutline, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { convertTimestampToTime, navigateto, openurl } from "../../../Utils/functions";
import { Customsectiontextinput } from "../../../Components/textinputs.";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Fetchcandidaterolesurl, Fetchrolesinvitesurl } from "../../../Utils/urls";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Erroralert, Successalert } from "../../../Components/popups";
import { Assessmentskeleton } from "../../../Components/skeletons";


const Clientassessments = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');

    const [all_assessments, setall_assessments] = useState([]);
    const [displayedassessments, setdisplayedassessments] = useState([]);


    const [hint, sethint] = useState('');




    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Discover' }))
        }
        fetchroles()
    }, []);


    const fetchroles = () => {
        setloading(true)
        axiosInstance.get(Fetchcandidaterolesurl).then((response) => {
            setloading(false)
            if (response.data.success) {
                const received_roles = response.data.data.roles
                setdisplayedassessments(received_roles)
                setall_assessments(received_roles)
            } else {
                seterror('No assessments yet')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }




    const handlehintchange = (temphint) => {
        sethint(temphint)
        if (!temphint) { setdisplayedassessments(all_assessments) }

        const formattedhint = temphint?.toLowerCase().replace(/\s+/g, '');

        const filteredassemments = all_assessments.filter((element) => {
            const formattedname = element.position?.toLowerCase().replace(/\s+/g, '');
            const formatteddescription = element.skill_level?.toLowerCase().replace(/\s+/g, '');

            return (
                formattedname?.includes(formattedhint?.toLowerCase().replace(/\s+/g, ''))) ||
                formatteddescription?.includes(formattedhint?.toLowerCase().replace(/\s+/g, '')
                );
        });

        setdisplayedassessments(filteredassemments)
    }


    const gotoassessments = (assessment) => {
        navigate("/clientaccount/client-view-assessment", { state: { current_role: assessment } })
    }




    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography fontFamily={'boldfont'}>My Assessment</Typography>
            </Stack>
            <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Customsectiontextinput
                    fieldname={"Search"}
                    width={"60%"}
                    value={hint}
                    inputProps={{
                        endAdornment: (
                            <IconButton><Search /></IconButton>
                        ),
                    }}
                    placeholder={"Search for assessment"}
                    onChange={handlehintchange}
                />
                <Button sx={{ display: { xs: "none", lg: "flex" } }} onClick={() => navigate("/clientaccount/assessment-builder-1")} variant="contained" startIcon={<AddCircleOutline />} >New Assessment</Button>
            </Stack>
            <Stack mt={1}  >
                <Box width={"100%"} >
                    <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                        {!loading && displayedassessments.length < 1 && <Nodatacomponent text={'No assessments match the search criteria'} />}
                        {loading && Array.from({ length: 10 }, (_, index) => (
                            <Assessmentskeleton key={index} />
                        ))}
                        {!loading && displayedassessments?.map((role, key) => {
                            // const role = invite.role
                            const progresspercentage = 10

                            return (
                                <Box onClick={() => gotoassessments(role)} position={"relative"} height={190} key={key} width={{ xs: "48%", lg: "23%" }} margin={"1%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, cursor: "pointer" }}>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{role.position}</Typography>
                                        <CircularWithValueLabel value={progresspercentage} />
                                    </Stack>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {role.skill_level} || {role.location} || {role.work_place_type}
                                    </Typography>
                                    <hr color="silver" />
                                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"}>
                                        <Box>
                                            <Typography fontSize={14}>Tests</Typography>
                                            <Typography fontFamily={"boldfont"}>{role.assessments?.length || 0}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography fontSize={14}>Candidates</Typography>
                                            <Typography fontFamily={"boldfont"}>{role.candidates?.length || 0}</Typography>
                                        </Box>
                                    </Stack>
                                    <Typography style={{
                                        position: "absolute",
                                        right: 2,
                                        bottom: 2
                                    }} fontFamily={"italiclightfont"} fontSize={12}>{convertTimestampToTime(role.created_at)}</Typography>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Clientassessments

