import React, { useEffect, useState } from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import CircularWithValueLabel from "../../../Components/circularprogress";
import { assessmentsdummy, systemassessments } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import { useNavigate } from "react-router-dom";
import { KeyboardBackspace, NewReleases, Verified } from "@mui/icons-material";
import { convertTimestampToTime } from "../../../Utils/functions";
import { Assessmentskeleton } from "../../../Components/skeletons";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Fetchassesmentsurl } from "../../../Utils/urls";
import useAuthAxios from "../../../../hooks/useAuthAxios";

const Recommendedassessments = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [error, seterror] = useState('');

    const [loading, setloading] = useState(true);


    const [displayedassessments, setdisplayedassessments] = useState([]);
    const [allassessments, setallassessments] = useState([]);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessments' }))
        }
        fetchassessments()
    }, []);


    const fetchassessments = () => {
        setloading(true)
        axiosInstance.get(Fetchassesmentsurl).then((response) => {
            setloading(false)
            if (response.data.success) {
                const gotten_assessments = response.data.data.assessments
                setallassessments(gotten_assessments)
                setdisplayedassessments(gotten_assessments)
            } else {
                seterror('No assessments yet')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }



    const gotoassessments = (assessment) => {
        navigate("/candidateaccount/assessments-module", { state: { assessmentinfo: assessment } })
    }


    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Recommended for you</Typography>
            </Stack>
            <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                {!loading && displayedassessments?.map((assessment, key) => {
                    const status = 'Not started'

                    return (
                        <Box margin={"1%"} key={key} width={{ xs: "100%", lg: "30%" }} height={180} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.title}</Typography>
                                {status === "Failed" && <NewReleases titleAccess="You Failed" color="error" />}
                                {status === "Passed" && <Verified titleAccess="Passed" color="success" />}
                            </Stack>
                            <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                {assessment.description}
                            </Typography>
                            <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration} Minutes</Typography>
                            </Box>
                            <Stack mt={2}>
                                {(status === 'Not started' || status === 'Failed') &&
                                    <Button onClick={() => gotoassessments(assessment)} variant="outlined" color="secondary" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>
                                        {status === 'Not started' ? 'Start Assessment' : 'Re-take Assessment'}
                                    </Button>
                                }
                                {status === 'Passed' && <Button variant="contained" color="success" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>Passed</Button>}
                            </Stack>
                        </Box>
                    )
                })}
                {!loading && displayedassessments.length < 1 &&
                    <Box width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        <Nodatacomponent text={'You have no recommended assessments yet'} />
                    </Box>
                }
                {loading && Array.from({ length: 10 }, (_, index) => (
                    <Assessmentskeleton key={index} />
                ))}
            </Stack >

        </Box >
    )
}

export default Recommendedassessments