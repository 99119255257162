import { useMemo, useState } from "react"
import { primarycolor } from "../../../Utils/colors"
import { Box, Stack, Typography, LinearProgress, Button, CircularProgress } from "@mui/material"
import Feedbackbutton from "../../../Components/feedbackbutton"
import { useNavigate } from "react-router-dom"
import { developerroles } from "../../../Utils/arrays"
import { Customsectiontextinput } from "../../../Components/textinputs."
import { Sitenamelogo } from "../../../Common/sitelogo"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../../../store/user"
import muistyles from "../../../Utils/muistyles"
import useAuthAxios from "../../../../hooks/useAuthAxios"
import { Updateuserinfourl } from "../../../Utils/urls"
import { Erroralert } from "../../../Components/popups"

const Onboarding2 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const name = user?.name.split(' ')[0]
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');

    const [role, setrole] = useState(user?.specialization || '');
    const [otherrole, setotherrole] = useState('');

    const Postdata = () => {
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            specialization: role
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                const updateddata = { ...user, specialization: role }
                dispatch(setUser(updateddata))
                navigate("/candidate-onboarding-3")
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }


    const gotonext = () => {
        Postdata()
    }


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }

    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} height={"100vh"}>
                <Box width={muistyles.onboardingleft_box1} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"} sx={{ overflowY: "scroll" }}>
                    <Box width={muistyles.onboardingleft_box2}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋 </Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={30} />
                        <Typography mt={4} mb={5} fontSize={30} fontFamily={"boldfont"}>What’s your main role?</Typography>
                        <Box height={{ xs: "40vh", sm: "40vw", lg: "auto" }} overflow={{ sm: "scroll", xs: "scroll", lg: "hidden" }}>
                            {developerroles.map((drole, key) => {
                                const selected = drole === role
                                return (
                                    <Button
                                        sx={{ borderRadius: 10, margin: "8px 10px 8px 0px", height: 45 }}
                                        color={selected ? 'primary' : 'secondary'}
                                        key={key}
                                        onClick={() => setrole(drole)}
                                        variant={selected ? 'contained' : "outlined"}
                                    >{drole}</Button>
                                )
                            })}
                        </Box>
                        {role === 'Other' &&
                            <Customsectiontextinput
                                fieldname={'Other'}
                                width={"90%"}
                                value={otherrole}
                                onChange={setotherrole}
                            />
                        }

                    </Box>
                    <Stack bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} disabled={!role || (role === 'Other' && !otherrole)} sx={buttonstyle} variant="contained">{loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : 'Next'}</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <img style={{ maxHeight: 150 }} src={require("./../../../../assets/illustrations/tailor.png")} />
                    <Typography mt={3} fontFamily={'boldfont'} width={"80%"} color={"white"} fontSize={30} textAlign={"center"}>
                        Roles tailored for <br /> you
                    </Typography>
                    <Typography mt={2} width={"80%"} fontFamily={"italiclightfont"} color={'white'} fontSize={15} textAlign={"center"}>
                        After joining the network, you gain access to top jobs that match your skills and experience.
                    </Typography>
                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Onboarding2