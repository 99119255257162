import React, { useState } from "react";
import { Box, Button, CircularProgress, Container, IconButton, Modal, Popover, Stack, Typography } from "@mui/material"
import { Customreactselectinput, Customsectiontextinput } from "../../../Components/textinputs.";
import { Clear } from "@mui/icons-material";
import { ITDegrees, ITPrograms } from "../../../Utils/arrays";
import Popupdialog from "../../../Components/popupcomponent";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { CandidateEducationurl } from "../../../Utils/urls";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";

//add education modal
export const Addeducationmodal = ({ ondismiss, onsuccess }) => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const axiosInstance = useAuthAxios();
    const [error, seterror] = useState('');
    const [loading, setloading] = useState(false);

    const [school, setschool] = useState('');
    const [program, setprogram] = useState('');
    const [degree, setdegree] = useState('');
    const [startdate, setstartdate] = useState('');
    const [enddate, setenddate] = useState('');
    const [description, setdescription] = useState('');

    const [confirmsave, setconfirmsave] = useState(false);

    const handleSubmit = () => {
        setloading(true)
        setconfirmsave(false)
        const predata = {
            level: program?.value,
            institution: school,
            type: program.value,
            field: degree?.value,
            start_date: startdate,
            end_date: enddate,
            certification: degree?.value,
            activities: degree?.value,
            description: description
        }

        axiosInstance.post(CandidateEducationurl, predata).then((response) => {
            setloading(true)
            if (response.data.success) {
                const education_details = user.education_details || []
                dispatch(setUser({ ...user, education_details: [...education_details, response.data.data.education_details] }))
                onsuccess()
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }




    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 0, borderRadius: 2, pb: 3, pt: 1 }}>
                {confirmsave &&
                    <Popupdialog
                        toptext={"Confirm Details!!"}
                        question={"Are you sure the details are correct?"}
                        successtext={"Add education"}
                        oncancel={() => setconfirmsave(false)}
                        onsuccess={handleSubmit}
                    />
                }
                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Typography fontSize={16} fontFamily={"boldfont"}>Add your education details</Typography>
                    <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Box mt={2} onSubmit={(e) => {
                    e.preventDefault()
                    setconfirmsave(true)
                }} component={'form'}>
                    <Customsectiontextinput
                        width={"100%"}
                        value={school}
                        required={true}
                        onChange={setschool}
                        fieldname={'School'}
                        placeholder={"Which school have you studied at?"}
                        hintcolor="red"
                        hint={"*"}
                    />
                    <Customreactselectinput
                        onChange={setprogram}
                        value={program}
                        required={true}
                        width={"100%"}
                        options={ITPrograms}
                        fieldname={'Program'}
                        hint={"*"}
                    /><br />
                    <Customreactselectinput
                        onChange={setdegree}
                        value={degree}
                        required={true}
                        width={"100%"}
                        options={ITDegrees}
                        fieldname={'Degree'}
                        hint={"*"}
                    />
                    <Stack width={"100%"} mt={4} flexDirection={"row"} justifyContent={"space-between"}>
                        <Customsectiontextinput
                            width={"48%"}
                            value={startdate}
                            required={true}
                            type={'date'}
                            onChange={setstartdate}
                            fieldname={'Starting From'}
                            hintcolor="red"
                            hint={"*"}
                        />
                        <Customsectiontextinput
                            width={"48%"}
                            value={enddate}
                            required={true}
                            type={'date'}
                            onChange={setenddate}
                            fieldname={'Ending In'}
                            hintcolor="red"
                            hint={"*"}
                        />
                    </Stack>
                    <Customsectiontextinput
                        width={"100%"}
                        placeholder={'Write about this education'}
                        value={description}
                        required={true}
                        type={'text'}
                        onChange={setdescription}
                        fieldname={'Description'}
                        hintcolor="red"
                        multiline={true}
                        hint={"*"}
                    />
                    <Stack mt={3} flexDirection={"row"} justifyContent={"right"} alignItems={'flex-end'}>
                        <Button onClick={() => ondismiss()} variant="text">Cancel</Button>&nbsp;
                        <Button type="submit" variant="contained">{loading ? <CircularProgress sx={{ color: "white" }} size={15} /> : 'Save'}</Button>
                    </Stack>
                </Box>

            </Container>
        </Modal>
    )
}

