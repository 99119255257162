import React, { useEffect, useMemo, useState } from "react"
import { Alert, AlertTitle, Box, Button, IconButton, InputAdornment, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { East, KeyboardBackspace, LocationOnOutlined, Search, Tune } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes, skilltypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import { Customsectiontextinput, Customselectinput } from "../../../../Components/textinputs.";
import { systemassessments } from "../../../../Utils/dummy";
import muistyles from "../../../../Utils/muistyles";
import { setNewassessment } from "../../../../../store/newassessment";
import Skilltypefilter from "../../../Candidate/Modals/skilltypeFilter";
import Nodatacomponent from "../../../../Components/nodatacomponent";
import { Assessmentskeleton } from "../../../../Components/skeletons";
import useAuthAxios from "../../../../../hooks/useAuthAxios";
import { Fetchassesmentsurl } from "../../../../Utils/urls";
import { Erroralert } from "../../../../Components/popups";



const Asssessmentbuilder2 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const { newassessment } = useSelector((state) => state.newassessment);
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('')


    const [all_assessments, setall_assessments] = useState([]);
    const [displayedassessments, setdisplayedassessments] = useState([]);
    const [selectedasessments, setselectedasessments] = useState(newassessment?.selectedasessments || []);


    const [hint, sethint] = useState('');
    const [skilltype, setskilltype] = useState(skilltypes[0]);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
        fetchassessments()
    }, []);

    const fetchassessments = () => {
        setloading(true)
        axiosInstance.get(Fetchassesmentsurl).then((response) => {
            setloading(false)
            if (response.data.success) {
                const gotten_assessments = response.data.data.assessments
                setall_assessments(gotten_assessments)
                setdisplayedassessments(gotten_assessments)
            } else {
                seterror('No assessments yet')
            }
        }).catch((error) => {
            setloading(false)
            seterror(error)
        })
    }



    const handlehintchange = (temphint) => {
        sethint(temphint)
        if (!temphint) { setdisplayedassessments(all_assessments) }

        const formattedhint = temphint?.toLowerCase().replace(/\s+/g, '');

        const filteredassemments = all_assessments.filter((element) => {
            const formattedname = element.title?.toLowerCase().replace(/\s+/g, '');
            const formatteddescription = element.description?.toLowerCase().replace(/\s+/g, '');

            return (
                formattedname?.includes(formattedhint?.toLowerCase().replace(/\s+/g, ''))) ||
                formatteddescription?.includes(formattedhint?.toLowerCase().replace(/\s+/g, '')
                );
        });

        setdisplayedassessments(filteredassemments)

    }


    const onselectassessment = (assessment, isselected) => {
        if (isselected) {
            setselectedasessments(selectedasessments.filter((obj) => obj.id !== assessment.id))
        } else {
            setselectedasessments([...selectedasessments, assessment])
        }
    }


    const gotonext = () => {
        const updatedassessment = { ...newassessment, selectedasessments }
        dispatch(setNewassessment(updatedassessment))
        navigate("/clientaccount/assessment-builder-3")
    }




    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Choose Tests</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={1} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} padding={{ xs: 2, lg: 4 }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Alert severity="info" >
                    <AlertTitle>Note*</AlertTitle>
                    You have the option to select system assessments (recommended) for your role or click 'next' to create your own custom assessment.
                </Alert>
                <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"} padding={{ xs: 0, lg: 2 }}>
                    <Customsectiontextinput
                        fieldname={"Search"}
                        width={{ xs: "35%", lg: "70%" }}
                        value={hint}
                        inputProps={{
                            endAdornment: (
                                <IconButton><Search /></IconButton>
                            ),
                        }}
                        placeholder={"Search for assessment"}
                        onChange={(text) => handlehintchange(text)}
                    />
                    <Skilltypefilter skilltype={skilltype} onSelect={setskilltype} width={{ xs: "40", lg: "20%" }} />
                    <Button variant="contained"  >Search</Button>
                </Stack>
                <Typography mb={2} fontSize={13} color={'primary'}>{selectedasessments.length} Selected</Typography>
                <Stack flexDirection={"row"} flexWrap={"wrap"}>
                    {!loading && displayedassessments.length < 1 && <Nodatacomponent text={'No assessments match the search criteria'} />}
                    {loading && Array.from({ length: 10 }, (_, index) => (
                        <Assessmentskeleton key={index} />
                    ))}
                    {!loading && displayedassessments.slice(0, 6).map((assessment, key) => {
                        const isselected = (selectedasessments.findIndex((obj) => obj.id === assessment.id)) > -1

                        return (
                            <Box onClick={() => onselectassessment(assessment, isselected)} key={key} width={{ xs: "48%", lg: "30%" }} margin={"1%"} padding={2} sx={{ backgroundColor: 'white', border: isselected ? '2px solid blue' : boxborder, borderRadius: 2, cursor: "pointer" }}>
                                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                    <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.title}</Typography>
                                </Stack>
                                <Tooltip title={assessment.description}>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {assessment.description}
                                    </Typography>
                                </Tooltip>
                                <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                    <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration} Minutes</Typography>
                                </Box>
                            </Box>
                        )
                    })}

                </Stack>
                <Stack flexDirection={"row"} justifyContent={"space-between"} >
                    <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                    <Button onClick={gotonext} sx={buttonstyle} endIcon={<East />} variant="contained">Next</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Asssessmentbuilder2