import React, { useState } from "react";
import { Box, Button, Menu, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { skilltypes } from "../../../Utils/arrays";
import { ExpandMore, Tune } from "@mui/icons-material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { handleMenuItemClick } from "../../../Utils/functions";

const Skilltypefilter = ({ skilltype, onSelect, width }) => {


    return (
        <Box width={width}>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Stack alignItems={"center"} flexDirection={"row"} justifyContent={"space-between"} padding={1}
                            sx={{
                                cursor: "pointer",
                                border: "1px solid silver",
                                borderRadius: 3
                            }}
                            {...bindTrigger(popupState)}>
                            <Typography sx={{
                                WebkitLineClamp: 1,
                                textOverflow: 'ellipsis',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                display: '-webkit-box',
                            }} fontSize={14}>{skilltype}</Typography>
                            <ExpandMore />
                        </Stack>
                        <Menu {...bindMenu(popupState)}>
                            <Box>
                                {skilltypes.map((val) => {

                                    return (
                                        <MenuItem onClick={() => handleMenuItemClick(popupState, () => onSelect(val))}>
                                            <Typography  fontSize={14} padding={1}>{val}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Box>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>

        </Box>
    )
}

export default Skilltypefilter