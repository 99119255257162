import React, { useEffect, useState } from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import muistyles from "../../../Utils/muistyles";
import { useLocation, useNavigate } from "react-router-dom";
import { KeyboardBackspace, NewReleases, Verified } from "@mui/icons-material";
import { systemassessments } from "../../../Utils/dummy";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Fetchassessmentresultsurl } from "../../../Utils/urls";



const Viewinvitedrole = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const location = useLocation()

    const invited_role = location.state?.invited_role?.role
    const assessments = invited_role?.assessments

    const [results, setresults] = useState([]);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'My Assessments' }))
        }
        fetchresults()
    }, []);

    //fetch all assessments results
    const fetchresults = () => {
        axiosInstance.get(Fetchassessmentresultsurl).then(response => {
            if (response.data.success) {
                setresults(response.data.data.assessments)
            }
        }).catch(error => {

        })
    }

   



    const gotoassessments = (assessment) => {
        navigate("/candidateaccount/assessments-module", { state: { assessmentinfo: assessment } })
    }
    

    const returnstatus = (assessmentresult) => {
        if (assessmentresult?.passed == 1) {
            return "Passed"
        }
        if (assessmentresult?.score && assessmentresult !== 1) {
            return "Failed"
        }
        return 'Not started'

    }
   

    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Role Assessments</Typography>
            </Stack>
            <Box mt={2} width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                    <Box width={{ xs: "100%", lg: "23%" }}>
                        <Typography fontSize={14}>Role</Typography>
                        <Typography fontFamily={"boldfont"}>{invited_role?.position}</Typography>
                    </Box>
                    <Box width={{ xs: "100%", lg: "23%" }}>
                        <Typography fontSize={14}>Skill Level</Typography>
                        <Typography fontFamily={"boldfont"}>{invited_role?.skill_level}</Typography>
                    </Box>
                    <Box width={{ xs: "100%", lg: "23%" }}>
                        <Typography fontSize={14}>Commitment</Typography>
                        <Typography fontFamily={"boldfont"}>{invited_role?.work_place_type}</Typography>
                    </Box>
                    <Box width={{ xs: "100%", lg: "23%" }}>
                        <Typography fontSize={14}>Location</Typography>
                        <Typography fontFamily={"boldfont"}>{invited_role?.location}</Typography>
                    </Box>
                </Stack>
            </Box>

            <Typography mt={2} mb={2} fontFamily={"boldfont"} >Tests</Typography>
            <Box mt={2} width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                    {assessments?.map((assessment, key) => {
                        const assessmentresult = results.find((obj) => obj.assessment_id == assessment.id)
                        const status = returnstatus(assessmentresult)

                        return (
                            <Box margin={"1%"} key={key} width={{ xs: "100%", lg: "30%" }} position={"relative"} height={180} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                    <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.title}</Typography>
                                    {status === "Failed" && <NewReleases titleAccess="You Failed" color="error" />}
                                    {status === "Passed" && <Verified titleAccess="Passed" color="success" />}
                                </Stack>
                                <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                    {assessment.description}
                                </Typography>
                                <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                    <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration} Minutes</Typography>
                                </Box>
                                <Stack mt={2} position={"absolute"} bottom={15} width={"95%"}>
                                    {(status === 'Not started' || status === 'Failed') &&
                                        <Button fullWidth onClick={() => gotoassessments(assessment)} variant="outlined" color="secondary" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>
                                            {status === 'Not started' ? 'Start Assessment' : 'Re-take Assessment'}
                                        </Button>
                                    }
                                    {status === 'Passed' && <Button fullWidth variant="contained" color="success" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>Passed</Button>}
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack >

            </Box>

        </Box >
    )
}

export default Viewinvitedrole