import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { boxborder } from "../../../Utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../../store/user";
import { Customsectiontextinput, Customselectinput } from "../../../Components/textinputs.";
import { feedbackaffectedFeatures } from "../../../Utils/arrays";
import { Stars } from "../../../Components/ratingcomp";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Sendfeedbackurl } from "../../../Utils/urls";
import Loadingspinner from "../../../Components/loading";
import { Erroralert, Successalert } from "../../../Components/popups";


const Receivefeedback = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [success, setsuccess] = useState('');
    const [error, seterror] = useState('');
    const [loading, setloading] = useState(false);

    const [feedbacktype, setfeedbacktype] = useState('');
    const [affected_feature, setaffected_feature] = useState('');
    const [description, setdescription] = useState('');
    const [rating, setrating] = useState('');

    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Feedback' }))
        }
    }, []);

    const handleSubmission = (e) => {
        e.preventDefault()
        setloading(true)
        axiosInstance.post(Sendfeedbackurl, {
            feedback_type: feedbacktype,
            affected_feature,
            description
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                setsuccess('Feedback received successfully')
                setTimeout(() => {
                    setsuccess('')
                    navigate("/")
                }, 3000);
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }



    const feedbacktypes = ['Feedback', 'Feature Request', 'Support Ticket', 'Bug Report']

    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }} position={"relative"} padding={{ xs: 2, lg: 6 }} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Typography fontSize={25} fontFamily={'boldfont'}>We Value Your Feedback!</Typography>
            <Typography>
                Please take a moment to share your feedback with us. Your input will help us make kontorva even better!
            </Typography>
            <Box mt={3} component={'form'} onSubmit={handleSubmission}>
                <Customselectinput
                    required={true}
                    fieldname={'Type Of Feedback*'}
                    width={"100%"}
                    options={feedbacktypes}
                    value={feedbacktype}
                    onChange={setfeedbacktype}
                /><br /><br />
                <Customselectinput
                    required={true}
                    fieldname={'Affected feature*'}
                    width={"100%"}
                    options={feedbackaffectedFeatures}
                    value={affected_feature}
                    onChange={setaffected_feature}
                /><br /><br />
                <Customsectiontextinput
                    required={true}
                    fieldname={'Briefly Describe Your Experience*'}
                    placeholder={'Give a brief description about your experience'}
                    width={"100%"}
                    multiline={true}
                    rows={5}
                    value={description}
                    onChange={setdescription}
                    helperText={'10 Characters Min'}
                />
                <Stack flexDirection={"row"} justifyContent={"right"} width={"100%"}>
                    <Button variant="contained" type="submit">Submit</Button>
                </Stack>

            </Box>
        </Box>
    )
}

export default Receivefeedback