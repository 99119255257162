import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Divider, IconButton, InputAdornment, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { DataObject, ExpandLess, ExpandMore, KeyboardBackspace, LocationOn, LocationOnOutlined, RsvpOutlined, Search, TravelExplore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder, dimtextcolor } from "../../../Utils/colors";
import { Customcheckbox, Customreactselectinput, Customsectiontextinput } from "../../../Components/textinputs.";
import { experiencelevels } from "../../../Utils/arrays";
import countryList from 'react-select-country-list'
import { dummymanimage } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Assessmentskeleton } from "../../../Components/skeletons";
import { Erroralert, Successalert } from "../../../Components/popups";
import { site_baseURL } from "../../../Utils/defaults";
import { openurl } from "../../../Utils/functions";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Fetchcandidaterolesurl, Fetchshortlistedcandidatesurl, Shortlistcandidatesurl } from "../../../Utils/urls";



const Shortlistedcandidates = () => {
    const axiosInstance = useAuthAxios();
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const countryoptions = useMemo(() => countryList().getData(), [])
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [all_candidates, setall_candidates] = useState([]);
    const [displayedcandidates, setdisplayedcandidates] = useState([]);


    const [hint, sethint] = useState(Array.isArray(user?.prefferredskills) ? user?.prefferredskills[0].value : '');

    //filters
    const [commitmenttype, setcommitmenttype] = useState('');
    const [experiencelevel, setexperiencelevel] = useState('');
    const [minexperience, setminexperience] = useState(0);
    const [maxexperience, setmaxexperience] = useState(200);


    const [showjobtypefilter, setshowjobtypefilter] = useState(true);
    const [showexperiencefilter, setshowexperiencefilter] = useState(true);

    const [roles, setroles] = useState([]);
    const [current_role, setcurrent_role] = useState('');


    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Shortlisted Candidates' }))
        }
    }, []);


    const commitmenttypes = [
        'Full-time', 'Part-time', 'Contract', "Internship"
    ]


    useEffect(() => {
        fetchpredata()
    }, []);

    useEffect(() => {
        if (current_role) {
            fetchrolecandidates()
        }
    }, [current_role]);


    const fetchpredata = async () => {
        setloading(true)
        const [rolesResponse] = await Promise.all([
            axiosInstance.get(Fetchcandidaterolesurl),
        ])
        setloading(false)
        if (rolesResponse.data.success) {
            const received_roles = rolesResponse.data.data?.roles.map((val) => ({
                label: `${val.position}(${val.skill_level})`,
                value: val.id
            }))
            setcurrent_role(received_roles[0])
            setroles(received_roles)
        }
    }


    const fetchrolecandidates = async () => {
        setloading(true)
        const [shortlistedResponse] = await Promise.all([
            axiosInstance.get(`${Shortlistcandidatesurl}/${current_role.value}`)
        ])
        setloading(false)

        if (shortlistedResponse.data.success) {
            const received_candidates = shortlistedResponse.data?.data?.role_details?.map((val) => val?.candidate)
            setall_candidates(received_candidates)
            setdisplayedcandidates(received_candidates)
        }
    }


    //change expericen level
    const onchangeexperience = (iscurrent, experience) => {
        if (iscurrent) {
            setexperiencelevel('')
            setminexperience(0)
            setmaxexperience(100)
            setdisplayedcandidates(all_candidates)
        } else {
            const filtered_candidates = all_candidates.filter((candidate) => parseInt(candidate.profile.proffessional_experience) < experience.max && candidate.profile.proffessional_experience >= experience.min)
            setdisplayedcandidates(filtered_candidates)
            setexperiencelevel(experience.label)
            setminexperience(experience.min)
            setmaxexperience(experience.max)
        }

    }

    //on change commitment type
    const onchangecommitment = (iscurrent, type) => {
        if (iscurrent) {
            setcommitmenttype('')
            setdisplayedcandidates(all_candidates)
        } else {
            setcommitmenttype(type)
            setdisplayedcandidates(all_candidates.filter((obj) => obj.profile?.commitment_type == type))
        }
    }





    const handlesearch = () => {
        if (hint) {
            const cleanedHint = hint.toLowerCase().replace(/\s+/g, '');
            const filtered_candidates = all_candidates?.filter(candidate => (
                candidate?.name?.toLowerCase().replace(/\s+/g, '').includes(cleanedHint) ||
                candidate?.email?.toLowerCase().replace(/\s+/g, '').includes(cleanedHint)
            ));

            setdisplayedcandidates(filtered_candidates)
        } else {
            setdisplayedcandidates(all_candidates)
        }
    }

    //go to candidate profile
    const gotocandidateprofile = (candidate) => {
        openurl(`${site_baseURL}/candidate-profile/${candidate?.public_code}`)
    }




    return (
        <Box>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"}>
                    <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                    <Typography fontFamily={'boldfont'}>Shorlisted Candidates</Typography>
                </Stack>
                <Button onClick={() => navigate("/clientaccount/discover-candidates")} sx={muistyles?.whitebutton} startIcon={<TravelExplore />} variant="contained">Find Developers</Button>
            </Stack>
            <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                <Box mt={2} width={"30%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Filter</Typography>
                        <Button color="primary" variant="text" sx={{ fontSize: 13, fontFamily: 'boldfont' }}>Clear All</Button>
                    </Stack>
                    <hr />
                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Job Type</Typography>
                        <IconButton onClick={() => setshowjobtypefilter(!showjobtypefilter)}>
                            {showjobtypefilter ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Stack>
                    {showjobtypefilter &&
                        <Box>
                            {commitmenttypes.map((type, key) => {
                                const iscurrent = type === commitmenttype

                                return (
                                    <Stack mb={1} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Customcheckbox onChange={() => onchangecommitment(iscurrent, type)} value={iscurrent} />
                                        <Typography fontSize={14} ml={2}>{type}</Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    }

                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Experience</Typography>
                        <IconButton onClick={() => setshowexperiencefilter(!showexperiencefilter)}>
                            {showexperiencefilter ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Stack>
                    {showexperiencefilter &&
                        <Box>
                            {experiencelevels.map((experience, key) => {
                                const iscurrent = experience.label === experiencelevel

                                return (
                                    <Stack mb={1} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Customcheckbox onChange={() => onchangeexperience(iscurrent, experience)} value={iscurrent} />
                                        <Typography fontSize={14} ml={2}>{experience.label}</Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    }
                </Box>
                <Box mt={2} width={"68%"}  >
                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                        <Customsectiontextinput
                            width={"40%"}
                            value={hint}
                            onChange={sethint}
                            inputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Customreactselectinput
                            onChange={setcurrent_role}
                            value={current_role}
                            width={{ xs: "30%", lg: "40%" }}
                            options={roles}
                        />
                        <Button onClick={() => handlesearch()} sx={{ height: 40 }} variant="contained">Search</Button>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        {displayedcandidates.length > 0 && !loading &&
                            <Stack flexDirection={"row"}>
                                <Typography fontSize={13} color={'dimtext'}>Showing </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{displayedcandidates.length} </Typography>
                                <Typography ml={1} fontSize={13}>Candidates </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{hint} </Typography>
                                <Typography ml={1} fontSize={13} >On </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>Saved candidates</Typography>
                            </Stack>}
                        <DataObject sx={{ cursor: "pointer" }} titleAccess="Filter technology" color="primary" />
                    </Stack>
                    <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                        {!loading && displayedcandidates.length < 1 && <Nodatacomponent text={'No candidates match the search criteria'} />}
                        {loading && Array.from({ length: 10 }, (_, index) => (
                            <Assessmentskeleton key={index} />
                        ))}
                        {!loading && displayedcandidates?.map((candidate, key) => {
                            const candidate_profile = candidate.profile

                            const candidate_description = `Experienced ${candidate_profile?.specialization} developer. Skilled in ${candidate?.userskill?.map((val) => val.skill_name).join(', ')}`;


                            return (
                                <Box position={"relative"} key={key} width={"30%"} margin={"1%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                    <Stack flexDirection={"row"}>
                                        <img src={dummymanimage} className="candidate-card-image" />
                                        <Box ml={2}>
                                            <Typography fontSize={14} fontFamily={'boldfont'}>{candidate.name}</Typography>
                                            <Typography sx={{ ...muistyles.twolinetextlimit, WebkitLineClamp: 1 }} fontSize={13} fontFamily={'boldfont'} color={dimtextcolor}>({candidate_profile?.specialization})</Typography>
                                        </Box>
                                    </Stack>
                                    <Tooltip title={candidate_description} placement="top">
                                        <Typography mt={1} mb={1} fontSize={13} sx={muistyles.twolinetextlimit}>
                                            {candidate_description}
                                        </Typography>
                                    </Tooltip>
                                    <Typography
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%'
                                        }}
                                        fontSize={13} fontFamily={"boldfont"} color={dimtextcolor}>{candidate.email}</Typography>
                                    <Typography fontSize={13} fontFamily={"boldfont"} color={dimtextcolor}>3 years Experience</Typography>
                                    <Button onClick={() => gotocandidateprofile(candidate_profile)} sx={{ height: 30, textTransform: "none" }} color="secondary" variant="contained">View Profile</Button>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}


export default Shortlistedcandidates