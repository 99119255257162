import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { BookmarkAdded, BookmarkAddedOutlined, SaveOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Badge, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { areDatesEqual, getDateDifference, imgCheck, timeDifference } from '../Utils/functions';
import Notificationsmodal from '../Dashboards/Candidate/Account/notifications';
import { user_pic_placeholder } from '../Utils/defaults';
import { Getsubscriptionstatusurl } from '../Utils/urls';
import useAuthAxios from '../../hooks/useAuthAxios';
import { Erroralert } from './popups';



export default function AccountMenu({ logout, counter, trial_period, onshowupgrade }) {
    const axiosInstance = useAuthAxios();
    const { user } = useSelector((state) => state.user);
    const { saved_candidates } = useSelector((state) => state.saved_candidates);

    const [profileurl, setprofileurl] = React.useState(null);
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState('')
    const letter = user?.name?.substring(0, 1);
    const open = Boolean(anchorEl);


    const [error, seterror] = React.useState('');
    const [expiry_date, setexpiry_date] = React.useState('');

    React.useEffect(() => {
        imgCheck(user?.profile_picture_url, user_pic_placeholder).then((src) => setprofileurl(src));
    }, [user]);

    React.useEffect(() => {
        fetchpredata()
    }, []);

    const fetchpredata = async () => {
        const [subscriptionStatusResponse] = await Promise.all([
            axiosInstance.get(Getsubscriptionstatusurl),
        ])

        if (subscriptionStatusResponse.data.success) {
            const currentplan = subscriptionStatusResponse.data.data
            const trial_period = areDatesEqual(new Date(currentplan?.created_at), new Date(currentplan?.updated_at))
            if (trial_period) {
                setexpiry_date(subscriptionStatusResponse.data.data.next_billing_date)
            }
        }
    }



    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const days_left = getDateDifference(new Date(), expiry_date)

    const shortlisted_message = trial_period ? 'Upgrade your account to access shortlisted candidates' : 'Shortlisted candidates'
    const saved_message = trial_period ? 'Upgrade your account to access saved candidates' : saved_candidates?.length > 0 ? `You have ${saved_candidates.length} saved Candidates` : 'You have no saved candidates'

    const avatarstyle = { width: 32, height: 32, backgroundColor: 'black' }
    return (
        <React.Fragment>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {user?.account_type === 'CLIENT' && expiry_date &&
                <Tooltip title={`Your trial period ends in ${days_left}. Please Upgrade your account`}>
                    <Typography onClick={() => navigate("/clientaccount/client-account-settings", { state: { section: 4 } })} sx={{ position: "absolute", right: 25, top: 2, color: "red", fontSize: 13, cursor: "pointer" }}>{days_left} left</Typography>
                </Tooltip>}
            <Stack mt={1} flexDirection={"row"} alignItems={'center'}>
                {user?.account_type === 'CLIENT' &&
                    <IconButton
                        title={shortlisted_message}
                        color={saved_candidates?.length > 0 ? "primary" : "secondary"}
                        onClick={() => trial_period ? onshowupgrade() : navigate("/clientaccount/shortlisted-candidates")}
                    >
                        {/* {saved_candidates?.length > 0 &&
                            <Typography
                                height={20}
                                width={20}
                                borderRadius={"100%"}
                                sx={{
                                    position: "absolute", right: -1, top: -2, zIndex: 5,
                                    backgroundColor: "black"
                                }}
                                fontSize='small' fontFamily={"boldfont"} color={'white'} >
                                {saved_candidates.length}
                            </Typography>} */}
                        <BookmarkAddedOutlined />
                    </IconButton>
                }
                &nbsp;&nbsp;
                {user?.account_type === 'CLIENT' &&
                    <IconButton
                        title={saved_message}
                        color={saved_candidates?.length > 0 ? "primary" : "secondary"}
                        onClick={() => trial_period ? onshowupgrade() : navigate("/clientaccount/saved-candidates")}
                    >
                        {saved_candidates?.length > 0 &&
                            <Typography
                                height={20}
                                width={20}
                                borderRadius={"100%"}
                                sx={{
                                    position: "absolute", right: -1, top: -2, zIndex: 5,
                                    backgroundColor: "black"
                                }}
                                fontSize='small' fontFamily={"boldfont"} color={'white'} >
                                {saved_candidates.length}
                            </Typography>}
                        <SaveOutlined />
                    </IconButton>
                }
                &nbsp;&nbsp;
                <Box mr={1}>
                    <Notificationsmodal />
                </Box>
                <Tooltip title="Account settings"
                    onClick={handleClick}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Stack flexDirection={'row'} alignItems={"center"}>
                        <IconButton size="small" >
                            {profileurl ? <img className="usertopheaderimg" src={profileurl} />
                                : <Avatar sx={avatarstyle}>{letter}</Avatar>
                            }
                        </IconButton>
                        <Box display={{ lg: "block", sm: 'none', xs: 'none' }} >
                            <Typography fontSize={13} fontFamily={'boldfont'} color={'black'}>{user?.name}</Typography>
                            <Typography fontSize={12} color={'var(--dimtextcolor)'}>{user?.role}</Typography>
                        </Box>
                    </Stack>

                </Tooltip>
            </Stack>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                </MenuItem> */}
                <MenuItem onClick={() => {
                    handleClose()
                    user?.account_type === 'CLIENT' ? navigate("/clientaccount/client-account-settings") : navigate("/candidateaccount/candidate-account-settings")
                }}>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                        {profileurl ?
                            <img className="usertopheaderimg" src={profileurl} /> :
                            <Avatar sx={avatarstyle}>{letter}</Avatar>
                        } &nbsp;
                        <Typography>My account</Typography>
                    </Stack>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {
                    handleClose()
                    user?.account_type === 'CLIENT' ? navigate("/clientaccount/client-account-settings") : navigate("/candidateaccount/candidate-account-settings")
                }}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    logout()
                }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}