import React, { useEffect, useState } from "react"
import { Box, Button, Checkbox, IconButton, Stack, Switch, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder, dimtextcolor } from "../../../Utils/colors";
import { useNavigate } from "react-router-dom";
import { Delete, HttpsOutlined, KeyboardBackspace, NotificationsActive, Tune, Visibility, VisibilityOff } from "@mui/icons-material";
import { Customcheckbox, Customsectiontextinput } from "../../../Components/textinputs.";
import Popupdialog from "../../../Components/popupcomponent";
import { Erroralert, Successalert } from "../../../Components/popups";
import Loadingspinner from "../../../Components/loading";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { returnErrormessage } from "../../../Utils/functions";
import { Accountpreferencesurl, Changepasswordurl, Resetpassurl } from "../../../Utils/urls";


const MycandidateaccountSettings = ({ ondeleteaccount }) => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()


    const [current, setcurrent] = useState('security');




    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Account Settings' }))
        }
    }, []);



    const activebox = {
        cursor: "pointer",
        backgroundColor: 'rgb(221, 220, 220)'
    }
    const inactivebox = {
        cursor: "pointer"
    }




    return (
        <Box margin={"auto"} width={{ xs: "95%", lg: "100%" }}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Account</Typography>
            </Stack>
            <Stack mt={1} flexDirection={"row"} width={"100%"} justifyContent={"space-between"} >
                <Box width={{ xs: "20%", lg: "30%" }} height={"80vh"} position={"relative"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack onClick={() => setcurrent('security')} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 'security' ? activebox : inactivebox}>
                        <IconButton size="medium"><HttpsOutlined /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Security</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent('notifications')} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 'notifications' ? activebox : inactivebox}>
                        <IconButton size="medium"><NotificationsActive /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Notifications</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent('preferences')} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 'preferences' ? activebox : inactivebox}>
                        <IconButton size="medium"><Tune /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} fontFamily={"boldfont"}>Preferences</Typography>
                    </Stack>
                    <Stack bottom={20} onClick={() => ondeleteaccount()} position={"absolute"} flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }}>
                        <IconButton color="error" size="medium"><Delete /></IconButton>
                        <Typography display={{ xs: "none", lg: "block" }} color={'error'} fontFamily={"boldfont"}>Delete Account</Typography>
                    </Stack>
                </Box>
                <Box width={{ xs: "78%", lg: "68%" }} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    {current === 'security' && <Passwords />}
                    {current === 'notifications' && <Notificationsbox />}
                    {current === 'preferences' && <Preferences />}
                </Box>
            </Stack >
        </Box >
    )
}


const Passwords = () => {
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);

    const [oldpass, setoldpass] = useState('');
    const [newpass, setnewpass] = useState('');
    const [confirmpass, setconfirmpass] = useState('');

    const [showpasswords, setshowpasswords] = useState(false);

    //modals
    const [confirmsave, setconfirmsave] = useState(false);

    const handleSubmit = () => {
        setconfirmsave(false)
        if (confirmpass !== newpass) {
            seterror('the two passwords do not match')
        }
        setloading(true)
        axiosInstance.post(Changepasswordurl, {
            current_password: oldpass,
            new_password: newpass,
            new_password_confirmation: newpass
        }).then(function (response) {
            setloading(false)
            if (response.data.success) {
                setsuccess('Password changed successfully')
                setTimeout(() => {//navigate user after 2 seconds of display
                    setsuccess('')
                    seterror('')
                    setoldpass('')
                    setnewpass('')
                    setconfirmpass('')
                }, 2000);
            }
        }).catch(function (error) {
            setloading(false)
            seterror(error)
        });

    }

    // Regex for password validation
    const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    // Check if new password meets the rules
    const isValidNewPass = passwordRules.test(newpass);
    const isValidConfirmPass = passwordRules.test(confirmpass);



    return (
        <Box>
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Details!!"}
                    question={"Are you sure you want to change your password?"}
                    successtext={"Change"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Typography mb={1}>Change Password</Typography>
            <hr />
            <Box mt={2} component={'form'} onSubmit={(e) => {
                e.preventDefault()
                setconfirmsave(true)
            }}>
                <Customsectiontextinput
                    fieldname={'Current Password'}
                    value={oldpass}
                    onChange={setoldpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />
                <Customsectiontextinput
                    fieldname={'New Password'}
                    value={newpass}
                    onChange={setnewpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                    helperText={!isValidNewPass && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                /><br />
                <Customsectiontextinput
                    fieldname={'Confirm Password'}
                    value={confirmpass}
                    onChange={setconfirmpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                    helperText={!isValidConfirmPass && newpass && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                />
                <Stack flexDirection={"row"} justifyContent={"right"}>
                    <Button type="submit" disabled={!oldpass || !isValidConfirmPass || !isValidNewPass} variant="contained">Save</Button>
                </Stack>
            </Box>
        </Box>
    )
}


const Notificationsbox = () => {

    const [newmatchedroles, setnewmatchedroles] = useState(false);
    const [retakerequest, setretakerequest] = useState(true);
    const [interviewrequest, setinterviewrequest] = useState(false);
    const [interviewrejected, setinterviewrejected] = useState(true);

    return (
        <Box>
            <Typography mb={1}>Notifications</Typography>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>Manage when you'll receive Notifications</Typography>
            <Box mt={2}>
                <Stack flexDirection={"row"}>
                    <Customcheckbox value={newmatchedroles} onChange={() => setnewmatchedroles(!newmatchedroles)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>New Matched Roles</Typography>
                        <Typography>A new role matches with my profile and skills</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={retakerequest} onChange={() => setretakerequest(!retakerequest)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Test retake request</Typography>
                        <Typography>A recruiter requests a retake of a previously done test</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={interviewrequest} onChange={() => setinterviewrequest(!interviewrequest)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Interview request received</Typography>
                        <Typography>A recruiter requests an interview with me for a role</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={interviewrejected} onChange={() => setinterviewrejected(!interviewrejected)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Interview request rejected</Typography>
                        <Typography>A recruiter rejects my request to be considered for a role</Typography>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}


const Preferences = () => {
    const axiosInstance = useAuthAxios();
    const [preferences, setPreferences] = useState({
        viewPreference: true,
        emailPublic: true,
        dobPublic: true,
        phonePublic: true,
        profile_public: true,
        github_public: true
    });


    useEffect(() => {
        updatepreference()
    }, [preferences]);


    const handleToggle = (key) => {
        setPreferences((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const updatepreference = () => {
        axiosInstance.post(Accountpreferencesurl, {
            email_public: preferences.emailPublic,
            list_view: preferences.viewPreference,
            dob_public: preferences.dobPublic,
            phone_public: preferences.phonePublic,
            profile_public: preferences.phonePublic,
            github_public: preferences.github_public
        }).catch(error => {

        })
    }


    return (
        <Box>
            <Typography mb={1}>Platform Preferences</Typography>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>
                Manage the Platform Preferences
            </Typography>
            <Box mt={2}>
                {[
                    { label: 'View Preference', description: 'Toggle On for list View', key: 'viewPreference' },
                    { label: 'Make Email Address Public', description: 'email address public for users to view', key: 'emailPublic' },
                    { label: 'Make Date Of Birth Public', description: 'date of birth public for users to view', key: 'dobPublic' },
                    { label: 'Make Phone Number Public', description: 'phone public for users to view', key: 'phonePublic' },
                ].map(({ label, description, key }, index) => (
                    <Stack key={index} mt={3} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box ml={1}>
                            <Typography fontSize={16}>{label}</Typography>
                            <Typography color={dimtextcolor} fontSize={13}>{description}</Typography>
                        </Box>
                        <Switch
                            checked={preferences[key]}
                            onChange={() => handleToggle(key)}
                        />
                    </Stack>
                ))}
            </Box>
        </Box>
    );
};


export default MycandidateaccountSettings