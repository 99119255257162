import React, { useState } from "react";
import { Container, IconButton, Modal, Stack, Typography, Button, Box } from "@mui/material";
import { Clear } from "@mui/icons-material";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import Loadingspinner from "../../../Components/loading";
import { Erroralert, Successalert } from "../../../Components/popups";
import { Customsectiontextinput } from "../../../Components/textinputs.";
import { Redeemcouponurl } from "../../../Utils/urls";

// upgrade pop up
export const Redeemcouponmodal = ({ onDismiss, onSuccess }) => {
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);

    const [code, setcode] = useState('');


    const handleSubmit = () => {
        setloading(true)
        axiosInstance.post(Redeemcouponurl, {
            coupon_code: code
        }).then((response) => {
            setloading(true)
            if (response.data.success) {
                setsuccess("Redeemed successfull")
                setTimeout(() => {
                    onSuccess()
                }, 3000);
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }
    return (
        <Modal
            open={true}
            onClose={onDismiss}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 2 }}>
                {loading && <Loadingspinner />}
                {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
                {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
                <Stack spacing={2}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontSize={20} fontWeight={"bold"}>Redeem coupon</Typography>
                        <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={onDismiss}>
                            <Clear />
                        </IconButton>
                    </Stack>
                    <Box mt={2} onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }} component={'form'}>
                        <Customsectiontextinput
                            width={"100%"}
                            value={code}
                            required={true}
                            onChange={setcode}
                            fieldname={'Code'}
                            placeholder={"enter coupon code"}
                            hintcolor="red"
                            hint={"i.e JKG12U3Y"}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ backgroundColor: '#1976d2', color: 'white', '&:hover': { backgroundColor: '#155a8a' } }}
                        >
                            Redeem
                        </Button>
                    </Box>
                </Stack>
            </Container>
        </Modal>
    );
};
