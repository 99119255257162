import React, { useState } from "react";
import { Box, Button, Container, IconButton, Modal, Stack, Typography } from "@mui/material"
import { Clear, NewReleases, Verified } from "@mui/icons-material";
import muistyles from "../../../Utils/muistyles";
import { boxborder } from "../../../Utils/colors";
import { useNavigate } from "react-router-dom";
import { Questiontypes } from "../../../Utils/arrays";


//add education modal
export const ViewassessmentResults = ({ ondismiss, assessments, candidate }) => {
    const navigate = useNavigate()


    const scores = candidate?.scores



    const gotoreviewassessment = (assessment) => {
        if (assessment?.user_id && assessment.type !== Questiontypes[0]) {
            navigate("/clientaccount/review-assessment", { state: { assessmentinfo: assessment, candidate: candidate } })
        }
    }




    const returnstatus = (assessmentresult) => {
        if (assessmentresult?.passed == 1) {
            return "Passed"
        }
        if (assessmentresult?.score && assessmentresult !== 1) {
            return "Failed"
        }
        return 'Not started'

    }

    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"md"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 1 }}>
                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Typography fontSize={16} fontFamily={"boldfont"}>Assessment Results</Typography>
                    <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                    {assessments?.map((assessment, key) => {
                        const status = returnstatus(scores?.find((obj) => obj.assessment_id == assessment.id))

                        return (
                            <Box mb={2} key={key} width={{ xs: "100%", lg: "30%" }} margin={"1%"} height={180} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                    <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.title}</Typography>
                                    {status === "Failed" && <NewReleases titleAccess="Candidate Failed" color="error" />}
                                    {status === "Passed" && <Verified titleAccess="Passed" color="success" />}
                                </Stack>
                                <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                    {assessment.description}
                                </Typography>
                                <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                    <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration} Minutes</Typography>
                                </Box>
                                <Stack mt={2}>
                                    {status === 'Failed' &&
                                        <Button onClick={() => gotoreviewassessment(assessment)} variant="contained" color="error" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>
                                            {status}
                                        </Button>
                                    }
                                    {status === 'Not started' &&
                                        <Button variant="outlined" color="secondary" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>
                                            {status}
                                        </Button>
                                    }
                                    {status === 'Passed' && <Button variant="contained" color="success" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>Passed</Button>}
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack >

            </Container>
        </Modal>
    )
}

