import React, { useState } from "react";
import { Box, Container, IconButton, Modal, Stack, Typography } from "@mui/material"
import { Clear, ExpandMore, InsertEmoticon, LocalPhone, LocationOnOutlined, MoodBad, Place, PlaceOutlined } from "@mui/icons-material";
import Sitelogo from "./sitelogo";
import { boxborder } from "../Utils/colors";
import { companiesemail, phoneNumbers } from "../Utils/defaults";
import { site_faqs } from "../Utils/arrays";
import muistyles from "../Utils/muistyles";
import { openurl } from "../Utils/functions";

//contact us modal
export const Contactusmodal = ({ ondismiss }) => {
    const [faqs_to_display, setfaqs_to_display] = useState(site_faqs);


    // Handlers for each section
    const handleChatToSales = () => {
        window.location.href = `mailto:${companiesemail[0]}`;
    };

    const handleChatToSupport = () => {
        window.location.href = `mailto:${companiesemail[1]}`;
    };

    const handleVisitUs = () => {
        window.open('https://maps.google.com', '_blank');
    };

    const handleCallUs = () => {
        openurl("https://cal.com/kontorva/discovery-call")
        // window.location.href = `tel:${phoneNumbers[0]}`;
    };

    const changefaq = (faqkey) => {
        const cloned_faqs = [...faqs_to_display]
        cloned_faqs[faqkey].show_answer = cloned_faqs[faqkey].show_answer ? false : true
        setfaqs_to_display(cloned_faqs)
    }


    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"lg"} sx={{ backgroundColor: 'white', mt: 2, borderRadius: 2, pb: 3, pt: 1 }}>
                <Stack mt={2} flexDirection={"row"} justifyContent={"right"} >
                    <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Box height={"80vh"} sx={muistyles.noscrollbar}>
                    <Stack flexDirection={"column"} alignItems={"center"}>
                        <Sitelogo height={50} />
                        <Typography fontFamily={"boldfont"} fontSize={30}>Contact Our friendly Team</Typography>
                        <Typography>Let us know how we can help</Typography>
                    </Stack>
                    <Stack flexDirection={"row"} mt={5} justifyContent={"space-between"}>
                        <Box width={"23%"} margin={"1%"} sx={{ border: boxborder, borderRadius: 2 }} padding={2}>
                            <Box width={30} height={30} border={boxborder} borderRadius={1} display={"flex"} alignItems={'center'} justifyContent={"center"}>
                                <IconButton size='small'><InsertEmoticon fontSize="small" /></IconButton>
                            </Box>
                            <Box mt={6}>
                                <Typography fontFamily={'boldfont'} fontSize={16}>Chat to Sales</Typography>
                                <Typography mt={1} fontSize={14}>Speak to our friendly team</Typography>
                                <Typography onClick={() => handleChatToSales()} mt={1} fontSize={12} fontFamily={"boldfont"} sx={{ textDecorationLine: "underline", cursor: "pointer" }}>{companiesemail[0]}</Typography>
                            </Box>
                        </Box>
                        <Box width={"23%"} margin={"1%"} sx={{ border: boxborder, borderRadius: 2 }} padding={2}>
                            <Box width={30} height={30} border={boxborder} borderRadius={1} display={"flex"} alignItems={'center'} justifyContent={"center"}>
                                <img src={require("./../../assets/icons/chat.png")} style={{ height: "70%" }} />
                            </Box>
                            <Box mt={6}>
                                <Typography fontFamily={'boldfont'} fontSize={16}>Chat to Support</Typography>
                                <Typography mt={1} fontSize={14}>We are here to help</Typography>
                                <Typography onClick={handleChatToSupport} mt={1} fontSize={12} fontFamily={"boldfont"} sx={{ textDecorationLine: "underline", cursor: "pointer" }}>{companiesemail[1]}</Typography>
                            </Box>
                        </Box>
                        <Box width={"23%"} margin={"1%"} sx={{ border: boxborder, borderRadius: 2 }} padding={2}>
                            <Box width={30} height={30} border={boxborder} borderRadius={1} display={"flex"} alignItems={'center'} justifyContent={"center"}>
                                <IconButton size='small'><LocationOnOutlined fontSize="small" /></IconButton>
                            </Box>
                            <Box mt={6}>
                                <Typography fontFamily={'boldfont'} fontSize={16}>Visit Us</Typography>
                                <Typography mt={1} fontSize={14}>Visit our office HQ</Typography>
                                <Typography mt={1} onClick={handleVisitUs} fontSize={12} fontFamily={"boldfont"} sx={{ textDecorationLine: "underline", cursor: "pointer" }}>View on Google Maps</Typography>
                            </Box>
                        </Box>
                        <Box width={"23%"} margin={"1%"} sx={{ border: boxborder, borderRadius: 2 }} padding={2}>
                            <Box width={30} height={30} border={boxborder} borderRadius={1} display={"flex"} alignItems={'center'} justifyContent={"center"}>
                                <IconButton size='small'><LocalPhone fontSize="small" /></IconButton>
                            </Box>
                            <Box mt={6}>
                                <Typography fontFamily={'boldfont'} fontSize={16}>Call Us</Typography>
                                <Typography mt={1} fontSize={14}>Mon-Fri From 8am - 5pm</Typography>
                                <Typography onClick={handleCallUs} mt={1} fontSize={12} fontFamily={"boldfont"} sx={{ textDecorationLine: "underline", cursor: "pointer" }}>Schedule a Call</Typography>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack mb={2} mt={2} flexDirection={"column"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={25}>Frequently asked Questions</Typography>
                    </Stack>
                    <Box width={"90%"} margin={"auto"}>
                        {faqs_to_display.map((faq, key) => {

                            return (
                                <Box onClick={() => changefaq(key)} mb={2} key={key} sx={{ border: boxborder, borderRadius: 2, cursor: "pointer" }} padding={2}>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography fontSize={13} fontFamily={'boldfont'}>{faq.question}</Typography>
                                        <ExpandMore />
                                    </Stack>
                                    {faq?.show_answer &&
                                        <Typography fontSize={15}>{faq.answer}</Typography>
                                    }
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Container>
        </Modal>
    )
}

