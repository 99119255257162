import React, { useEffect, useMemo, useRef, useState } from "react"
import { Box, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import JoditEditor from 'jodit-react';
import { setNewassessment } from "../../../../../store/newassessment";




const Assessmentbuilder4 = () => {
    const dispatch = useDispatch()
    const editor = useRef(null);
    const { newassessment } = useSelector((state) => state.newassessment);

    const [instructions, setinstructions] = useState('');


    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(setNewassessment({
                ...newassessment,
                instructions: instructions
            }));
        }, 5000); // Update every 5 seconds
        return () => clearInterval(interval);
    }, [instructions]);

    const config = {
        height: 400, 
    };


    return (
        <Box width={"100%"}>
            <Typography mb={2} fontFamily={"boldfont"}>Assessment Instructions</Typography>
            <Box>
               {editor &&
                <JoditEditor
                    ref={editor}
                    value={instructions}
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => setinstructions(newContent)}
                    onChange={newContent => { }}
                />
               }
            </Box>
        </Box >
    )
}

export default Assessmentbuilder4