import { useState } from "react";
import { Sitenamelogo } from "../../../Common/sitelogo"
import muistyles from "../../../Utils/muistyles"
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../../store/user";
import { Customsectiontextinput, Customselectinput } from "../../../Components/textinputs.";
import Loadingspinner from "../../../Components/loading";
import { Erroralert, Successalert } from "../../../Components/popups";
import Popupdialog from "../../../Components/popupcomponent";
import { companysizes, companyTypes } from "../../../Utils/arrays";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { Updateuserinfourl } from "../../../Utils/urls";


const Clientonboarding3 = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);

    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [companysize, setcompanysize] = useState('');
    const [companyname, setcompanyname] = useState('');
    const [companyemail, setcompanyemail] = useState('');
    const [company_type, setcompany_type] = useState('');
    const [jobtitle, setjobtitle] = useState('');

    //booleans
    const [loading, setloading] = useState(false);
    const [confirmsave, setconfirmsave] = useState(false);


    const handleSubmit = () => {
        setconfirmsave(false)
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            company_name: companyname,
            company_size: companysize,
            company_email: companyemail,
            job_title: jobtitle,
            company_type: company_type
        }).then((response) => {
            setloading(false)
            if (response.data.success) {
                setsuccess('Posted Sucessfully. Redirecting,,')
                setTimeout(() => {
                    setsuccess('')
                    const updateddata = { ...user, companyname, companysize, companyemail, jobtitle, company_type };
                    dispatch(setUser(updateddata));
                    navigate("/clientaccount");
                }, 2000);
            } else {
                seterror('Try again later')
            }
        }).catch((error) => {
            setloading(false)
        })
    }





    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" };
    return (
        <Box>
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Submission!!"}
                    question={"Are you sure you want to submit these details?"}
                    successtext={"Submit"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            <Stack height={"100vh"} flexDirection={"row"} sx={{ overflowY: 'hidden' }}>
                <Box width={"40%"} sx={muistyles.onboardingleft}>
                    <Sitenamelogo type="transparentwhite" />
                    <Box mt={10}>
                        <Typography color={'white'} fontFamily={'boldfont'} fontSize={40}>Find your perfect <br /> Match</Typography>
                        <Typography color={'white'}>Answer some short questions to help us understand your needs.</Typography>
                    </Box>
                </Box>
                <Box component={'form'} onSubmit={(e) => {
                    e.preventDefault()
                    setconfirmsave(true)
                }} width={{ xs: "100%", lg: "60%" }} padding={{ xs: 3, lg: 8 }} position={"relative"} height={"90%"}>
                    <Box display={{ xs: "block", lg: "none" }}>
                        <Sitenamelogo />
                    </Box>
                    <Box width={"95%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mb={5} fontStyle={18} fontSize={18} fontFamily={"boldfont"}>Lets capture your company Info</Typography>
                        <Box mt={5}>
                            <Customsectiontextinput
                                fieldname={'Company Name'}
                                width={"100%"}
                                autoComplete={"organization"}
                                required={true}
                                type={'text'}
                                value={companyname}
                                onChange={setcompanyname}
                            />
                            {/* <Customsectiontextinput
                                fieldname={'Job Title'}
                                width={"100%"}
                                autoComplete={"organization-title"}
                                required={true}
                                type={'text'}
                                value={jobtitle}
                                onChange={setjobtitle}
                            /> */}
                            <Customsectiontextinput
                                fieldname={'Company Email'}
                                width={"100%"}
                                required={true}
                                type={'email'}
                                autoComplete={'email'}
                                value={companyemail}
                                onChange={setcompanyemail}
                            />
                            <Customselectinput
                                fieldname={'Company Size'}
                                width={"100%"}
                                options={companysizes}
                                required={true}
                                value={companysize}
                                onChange={setcompanysize}
                            /><br />
                            <Customselectinput
                                fieldname={'Company Type'}
                                width={"100%"}
                                options={companyTypes}
                                required={true}
                                value={company_type}
                                onChange={setcompany_type}
                            />
                        </Box>

                    </Box>
                    <Stack sx={{ backgroundColor: 'white' }} width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button type="submit" sx={buttonstyle} variant="contained">Finish</Button>
                    </Stack>
                </Box>
            </Stack>

        </Box>
    )
}

export default Clientonboarding3