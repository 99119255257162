import { useEffect, useRef, useState } from "react"
import { KeyboardBackspace, PlayArrow } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, Stack, TextField, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { boxborder } from "../../../Utils/colors"
import Popupdialog from "../../../Components/popupcomponent"
import { assessment_questions } from "../../../Utils/dummy"
import { CustomStopwatch, navigateto } from "../../../Utils/functions"
import { alphabets, editorsupportedlanguages, editorthemes, phpSuggestions, Questiontypes } from "../../../Utils/arrays"
import { Customcheckbox, Customreactselectinput, Customselectinput } from "../../../Components/textinputs."
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';
import { Erroralert } from "../../../Components/popups"
import { useSelector } from "react-redux"
import axios from "axios"
import { rapidapikey } from "../../../Utils/defaults"
import useAuthAxios from "../../../../hooks/useAuthAxios"
import { Executecodeurl, Fetchassessmentsquestionsurl, Gradeassessmenturl, idebase_url, Postquestionanswerurl } from "../../../Utils/urls"
import Loadingspinner from "../../../Components/loading"
import Successspinner from "../../../Components/successspinner"
var base64 = require('base-64');


const Assessmentmodule = () => {
    const axiosInstance = useAuthAxios()
    const navigate = useNavigate()
    const location = useLocation()
    const assessmentinfo = location.state?.assessmentinfo
    const [loading, setloading] = useState(true);
    const [success, setsuccess] = useState('');
    const [error, seterror] = useState('');

    const [all_questions, setall_questions] = useState([]);


    const [screen, setscreen] = useState(1);

    const sampleinstructions = [
        { instruction: "There's no option to pause. Make sure you will not be interrupted for 60 minutes" },
        { instruction: "If you accidentally close your browser, use the invitation link to get back to your test" }
    ]

    // const instructions = assessmentinfo?.instructions ? JSON.parse(assessmentinfo?.instructions) : sampleinstructions
    const instructions = assessmentinfo?.instructions

    // Video Recording State
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recordedChunks, setRecordedChunks] = useState([]);

    const [error_title, seterror_title] = useState('');
    const [videoerror, setvideoerror] = useState('');
    const [videoallowed, setvideoallowed] = useState(false);


    useEffect(() => {
        if (screen === 2) {
            // Start video recording when the timer starts 
            startVideoRecording();
        }
        return () => {
            // stop recording and upload video when component unmounts or assessment ends
            stopVideoRecording();
        };
    }, [screen]);


    useEffect(() => {
        fetchquestions()
    }, []);

    const fetchquestions = () => {
        axiosInstance.get(`${Fetchassessmentsquestionsurl}/${assessmentinfo.id}/questions`).then((response) => {
            setloading(false)
            if (response.data.success) {
                setall_questions(response.data.data.questions)
            }
        }).catch((error) => {
            seterror(error)
            setloading(false)
        })
    }

    // Initialize video recording with stream monitoring
    const startVideoRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            const recorder = new MediaRecorder(stream);

            // Monitor for stream interruption (e.g., camera/mic disabled in browser settings)
            stream.getTracks().forEach((track) => {
                track.onended = () => {
                    handleStreamInterruption();
                };
                track.oninactive = () => {
                    handleStreamInterruption();
                };
            });

            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    setRecordedChunks((prev) => [...prev, e.data]);
                }
            };
            recorder.start();
            setMediaRecorder(recorder);
            setvideoallowed(true); // Permission granted
        } catch (err) {
            handleMediaAccessError(err);
        }
    };

    // Handle media access errors
    const handleMediaAccessError = (err) => {
        if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
            // User denied permission, show instructions to manually enable permissions
            seterror_title("Camera and Microphone Access Denied");
            setvideoerror(
                "You have denied access to the camera and microphone. " +
                "Please enable camera and microphone access in your browser settings, " +
                "and then click Retry."
            );
        } else if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
            // No device found
            seterror_title("No camera or microphone found.");
            setvideoerror("No camera or microphone detected. Please connect a device and try again.");
        } else {
            // Other errors
            seterror_title("Error accessing media devices!!");
            setvideoerror("An error occurred while trying to access your camera and microphone.");
        }
    };

    // Handle stream interruption (e.g., user disables permissions mid-test)
    const handleStreamInterruption = () => {
        setvideoallowed(false)
        seterror_title("Camera or Microphone Disabled");
        setvideoerror(
            "Your camera or microphone has been disabled during the test. " +
            "Please enable camera and microphone access in your browser settings and click Retry."
        );

        // Stop the media recorder
        stopVideoRecording();
    };


    const stopVideoRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
        }

        // Stop all media tracks (camera and microphone)
        if (mediaRecorder?.stream) {
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
    };




    const gottonext = () => {
        setscreen((prev) => prev + 1)
    }

    const ontimeup = () => {

    }

    // Retry the permission request
    const retryVideoRecording = () => {
        setvideoerror(''); // Clear the error first
        startVideoRecording(); // Retry requesting the permissions
    };

    return (
        <Box width={"100%"}>
            {loading && <Loadingspinner />}
            {videoerror?.length > 0 &&
                <Popupdialog
                    toptext={error_title}
                    question={videoerror}
                    successtext={'Retry'}
                    uncancellable={true}
                    onsuccess={() => retryVideoRecording()}
                    oncancel={() => {
                        setscreen(prev => prev - 1)
                        setvideoerror('')
                    }}
                />
            }
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"}>
                    <KeyboardBackspace onClick={() => screen === 2 ? seterror('Cannot go back in between a test') : navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                    <Typography fontFamily={'boldfont'}>{assessmentinfo?.name}</Typography>
                </Stack>
                <Typography fontFamily={'boldfont'}>
                    {screen === 2 && <CustomStopwatch pause={!videoallowed} minutes={parseInt(assessmentinfo?.duration || 120)} ontimeup={ontimeup} />}
                </Typography>
            </Stack>
            <Box mt={2} width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                {screen === 1 && <Screenone questions={all_questions} instructions={instructions} gottonext={gottonext} />}
                {screen === 2 && <Questionscomponent recordedChunks={recordedChunks} onendAssessment={() => stopVideoRecording()} questions={all_questions} />}
            </Box>
        </Box>
    )
}


export default Assessmentmodule


const Questionscomponent = ({ questions, assessment_id, onendAssessment, recordedChunks }) => {
    const navigate = useNavigate()
    const axiosInstance = useAuthAxios()
    const [runningcode, setrunningcode] = useState(false);
    const [codeoutput, setcodeoutput] = useState(false);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState(false);
    const [loading, setloading] = useState(false);


    const [allquestions, setallquestions] = useState(questions || []);
    const [currentquestion, setcurrentquestion] = useState(questions[0]);

    const currentid = (allquestions.findIndex((obj) => obj.id === currentquestion.id)) + 1
    const question_type = currentquestion.question_type

    const [currenttheme, setcurrenttheme] = useState(editorthemes[1]);
    const [currentlanguage, setcurrentlanguage] = useState(editorsupportedlanguages[0]);

    useEffect(() => {
        if (currentlanguage && currentquestion.question_type === Questiontypes[2]) {
            setcurrentquestion({ ...currentquestion, questionanswer: currentlanguage.sampleCode })
        }
    }, [currentlanguage]);

    useEffect(() => {
        if (codeoutput) {
            ///scroll to the output box
            navigateto('boxend')
        }
    }, [codeoutput]);


    const gotonext = () => {
        setloading(true)
        const clonedquestions = [...allquestions]
        clonedquestions[currentid - 1] = currentquestion
        const nextquestion = allquestions[currentid]

        axiosInstance.post(Postquestionanswerurl, {
            ...currentquestion,
            selected_option: currentquestion.question_type === Questiontypes[0] ? currentquestion.selectedoptionid : '',
            answer_text: currentquestion.questionanswer,
            question_id: currentquestion.id
        }).then((response) => {
            if (response.data.success) {
                if (currentid == allquestions.length) {//if its the last question
                    onendAssessment()
                    gradeassessment()

                } else {
                    setloading(false)
                    setallquestions(clonedquestions)
                    setcurrentquestion({ ...nextquestion, questionanswer: nextquestion.question_type === Questiontypes[2] ? currentlanguage.sampleCode : '' })
                }
            }
        }).catch(error => {
            seterror(error)
            setloading(false)
        })
    }



    const gradeassessment = () => {
        if (question_type !== Questiontypes[0]) {
            setsuccess(true);
            setTimeout(() => {
                setsuccess(false);
                navigate("/candidateaccount/candidateassessments", { replace: true });
            }, 3000);
            return
        }

        console.log('prceed')
        const videoBlob = new Blob(recordedChunks, { type: "video/mp4" });
        const formData = new FormData();
        formData.append("file", videoBlob, "assessment_video.mp4");
        formData.append("assessment_id", currentquestion.assessment_id);

        axiosInstance.post(Gradeassessmenturl, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).then((response) => {
            console.log(response.data)
            setloading(false);
            if (response.data.success) {
                setsuccess(true);
                setTimeout(() => {
                    setsuccess(false);
                    navigate("/candidateaccount/candidateassessments", { replace: true });
                }, 3000);
            }
        }).catch((error) => {
            console.log(error)
            setloading(false);
            seterror(error);
        });
    }



    const gotback = () => {
        setcurrentquestion(allquestions[currentid - 2])
    }

    // onchnge multiple selection
    const selectoption = (option) => {
        setcurrentquestion({ ...currentquestion, selectedoptionid: option.id })
    }
    //onchange code input and text input
    const handleCodeeditorchange = (value, event) => {
        setcurrentquestion({ ...currentquestion, questionanswer: value })
    }

    //check if question has been answered
    const isquestionansered = () => {
        if (!currentquestion?.selectedoptionid && !currentquestion?.questionanswer) {
            return false
        }
        return true
    }



    //run code
    const runcode = async () => {
        setcodeoutput('');
        setrunningcode(true);

        await axios.post(Executecodeurl, {
            language_id: currentlanguage?.id,
            source_code: currentquestion?.questionanswer,
            stdin: ''
        }).then((response) => {
            const submission_token = response.data.token
            if (submission_token) {
                checkSubmissionStatus(response.data.token);
            } else {
                setrunningcode(false);
                seterror('An error occurred executing your code')
            }
        }).catch((error) => {
            setrunningcode(false);
            seterror('Compiler Unreachable');
            console.error(error);
        })
    };



    const checkSubmissionStatus = async (submission_token) => {
        await axios.get(`${Executecodeurl}/${submission_token}`, {
            params: {
                base64_encoded: 'true',
                fields: '*'
            }
        }).then((response) => {
            const { status, stdout, stderr, message } = response.data;

            if (status.description === 'Processing' || status.description === 'In Queue') {
                // Check status again after 1 second
                setTimeout(() => checkSubmissionStatus(submission_token), 1000);
            } else {
                setrunningcode(false);
                if (!stderr) {
                    setcodeoutput(stdout ? base64.decode(stdout) : 'Code executed successfully with no output');
                } else {
                    seterror(base64.decode(message));
                }
            }
        }).catch(error => {
            setrunningcode(false);
            seterror('Error compiling your code');
        })
    };






    const buttonstyle = { borderRadius: 10, height: 40, width: 100, fontFamily: "boldfont" }

    return (
        <Box width={"100%"} height={"70vh"} >
            {success && < Successspinner message={"Congratulations. You've completed your test"} />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Box height={"90%"} sx={{ overflowY: "scroll", overflowX: 'hidden' }} position={"relative"}>
                <Stack flexDirection={"row"}>
                    <Typography fontFamily={"boldfont"}>{currentid}/{questions?.length}</Typography>
                    <Typography ml={1}>
                        <span dangerouslySetInnerHTML={{ __html: currentquestion?.question_text }} />
                    </Typography>
                </Stack>
                <Box mt={2} height={"100%"}>
                    {question_type === Questiontypes[1] &&
                        <Box>
                            <TextField
                                label="Type your answer"
                                multiline
                                rows={8}
                                variant="outlined"
                                value={currentquestion?.questionanswer || ''}
                                onChange={(e) => handleCodeeditorchange(e.target.value)}
                                onPaste={(e) => {
                                    e.preventDefault()
                                    seterror('Pasting not allowed')
                                }}
                                fullWidth
                            />
                        </Box>}
                    {question_type === Questiontypes[0] &&
                        <Box padding={2}>
                            {currentquestion.options.map((option, optionkey) => {
                                const isselected = option.id === currentquestion?.selectedoptionid

                                return (
                                    <Stack width={"100%"} onClick={() => selectoption(option)} key={optionkey} mb={3} flexDirection={'row'} alignItems={"center"} justifyContent={"space-between"} sx={{ cursor: "pointer" }}>
                                        <Stack flexDirection={"row"}>
                                            <Typography>{alphabets[optionkey]})</Typography>
                                            <Typography ml={1}>{option.option_text}</Typography>
                                        </Stack>
                                        <Customcheckbox type={'radio'} value={isselected} />
                                    </Stack>
                                )
                            })}
                        </Box>
                    }
                    {question_type === Questiontypes[2] &&
                        <Box height={"100%"}>
                            <Stack mb={3} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Customreactselectinput
                                    fieldname={'Language'}
                                    options={editorsupportedlanguages}
                                    width={'40%'}
                                    value={currentlanguage}
                                    onChange={setcurrentlanguage}
                                />
                                <Customselectinput
                                    options={editorthemes}
                                    fieldname={'Theme'}
                                    width={'40%'}
                                    value={currenttheme}
                                    onChange={setcurrenttheme}
                                />
                            </Stack>
                            <Editor
                                theme={currenttheme}
                                height="80%"
                                defaultLanguage={'javascript'}
                                language={currentlanguage?.value}
                                defaultValue={currentlanguage?.sampleCode}
                                value={currentquestion?.questionanswer}
                                onChange={handleCodeeditorchange}
                            />

                        </Box>
                    }
                    {question_type === Questiontypes[2] && codeoutput &&
                        <Box>
                            <Typography fontFamily={'boldfont'}>Output</Typography>
                            <Box mt={2}>
                                {codeoutput}
                            </Box>
                            <div id="boxend" style={{ marginTop: 100 }} />
                        </Box>
                    }

                </Box>

            </Box>
            <Stack mt={2} width={"100%"} sx={{ backgroundColor: "white" }} flexDirection={"row"} justifyContent={"space-between"}>
                <Button disabled={currentid === 1} sx={buttonstyle} onClick={() => gotback()} variant="outlined">Back</Button>
                <Stack flexDirection={"row"}>
                    {question_type === Questiontypes[2] && currentlanguage?.id !== 0 &&
                        <Button disabled={!currentquestion?.questionanswer} sx={{ ...buttonstyle, width: 150 }} startIcon={<PlayArrow />}
                            onClick={() => runcode()} variant="outlined">
                            {runningcode ? <CircularProgress color="primary" size={20} /> : 'RUN CODE'}
                        </Button>}&nbsp;&nbsp;
                    <Button disabled={!isquestionansered()} onClick={() => gotonext()} sx={buttonstyle} variant="contained">
                        {loading ? <CircularProgress size={15} sx={{ color: "white" }} /> : currentid === questions?.length ? 'Finish' : 'Next'}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}



//Screen One
const Screenone = ({ instructions, gottonext, questions }) => {


    const [confirmproceed, setconfirmproceed] = useState(false);

    return (
        <Box>
            {confirmproceed &&
                <Popupdialog
                    toptext={"Confirm Assessment Start"}
                    question={"Are you sure you want to begin this assesment?"}
                    successtext={"Proceed"}
                    oncancel={() => setconfirmproceed(false)}
                    onsuccess={() => {
                        gottonext()
                        setconfirmproceed(false)
                    }}
                />
            }
            <Typography fontFamily={'boldfont'}>Before you begin</Typography>
            <Box mt={2}>
                <Typography ml={1}>
                    <span dangerouslySetInnerHTML={{ __html: instructions }} />
                </Typography>
                {/* {instructions?.map((instruction, key) => {

                    return (
                        <Stack flexDirection={"row"}>
                            <Typography fontFamily={'boldfont'}>{key + 1}.</Typography>
                            <Typography ml={1}>{instruction?.instruction}</Typography>
                        </Stack>
                    )
                })} */}
                <Box on mt={2}>
                    <Button disabled={!questions?.length} onClick={() => setconfirmproceed(true)} variant="outlined" >Start Assessment</Button>
                </Box>
            </Box>
        </Box>
    )
}


