import { Box, Button, Container, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Customreactselectinput, Customsectiontextinput } from "../Components/textinputs.";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Sitelogo from "./sitelogo";
import Popupdialog from "../Components/popupcomponent";
import Loadingspinner from "../Components/loading";
import { Erroralert, Successalert } from "../Components/popups";
import useAuthAxios from "../../hooks/useAuthAxios";
import { Manageduseracceptinviteurl, Registeruserurl } from "../Utils/urls";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/user";
import Verifyregistercode from "./verifyregistercode";
import { isMobile } from "react-device-detect";



const Clientsignup = () => {
    const usertype = 'CLIENT';
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const invitationCode = queryParams.get('invite_token');

    const dispatch = useDispatch()
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [email, setemail] = useState('');
    const [fname, setfname] = useState('');
    const [lname, setlname] = useState('');
    const [password, setpassword] = useState('');


    const [loading, setloading] = useState(false);
    const [showPassword, setshowPassword] = useState(false);

    //modals
    const [confirmsave, setconfirmsave] = useState(false);
    const [showentercode, setshowentercode] = useState(false);


    // Regex for password validation
    const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    // Check if new password meets the rules
    const isValidPass = passwordRules.test(password);



    const handleSubmit = () => {
        setconfirmsave(false)
        setloading(true)
        seterror(null)


        // Check if email is a business email (no common domains like gmail, yahoo, etc.)
        const commonDomains = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com"];
        const emailDomain = email.split('@')[1];

        if (commonDomains.includes(emailDomain)) {
            seterror("Please use a business email address (not a personal one like Gmail, Yahoo, etc.)");
            setloading(false);
            return;
        }

        if (!isValidPass) {
            seterror('Password does not meet minimum requirements')
            return
        }

        const fullname = `${fname} ${lname}`
        const predata = {
            name: fullname,
            email: email,
            token: invitationCode,
            password: password,
            account_type: usertype
        }
        seterror(null)
        axiosInstance.post(invitationCode ? Manageduseracceptinviteurl : Registeruserurl, predata).then(response => {
            setloading(false)
            if (response.data.success) {
                if (invitationCode) {
                    setsuccess("Registered successfully")
                    setTimeout(() => {
                        onsuccess()
                    }, 3000);
                } else {
                    setshowentercode(true)
                }
            }
        }).catch(error => {
            setloading(false)
            seterror(error || "Sorry an error occurred while submitting your details");
        });

    };

    const onsuccess = () => {
        setshowentercode(false)
        navigate('/login', { state: { usertype: usertype } })
    }



    return (
        <Box position={'relative'}>
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Details!!"}
                    question={"Are you sure the details are correct?"}
                    successtext={"Submit"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {showentercode && <Verifyregistercode useremail={email} onsuccess={onsuccess} ondismissmodals={() => setshowentercode(false)} />}
            <Stack flexDirection={"row"} height={"100%"}>
                <Box width={{ lg: "50%", sm: "100%", xs: "100%" }} sx={{ backgroundColor: 'white', height: "100%", paddingLeft: "2%" }}>
                    <Container>
                        <Sitelogo height={isMobile && "50px"} type={'white'} />
                        <Typography mt={5} fontSize={{ lg: 35, sm: 25, xs: 25 }} fontFamily={'boldfont'}>Create Account</Typography>
                        <Typography color={"gray"} fontSize={{ lg: 18, sm: 14, xs: 14 }} mt={1}>We’ll personalize your setup experience accordingly.</Typography>
                        <Box component="form" onSubmit={(e) => {
                            e.preventDefault()
                            setconfirmsave(true)
                        }} width={{ lg: "80%", sm: "100%", xs: "100%" }} mt={2}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Customsectiontextinput
                                    fieldname={'First Name'}
                                    value={fname}
                                    onChange={setfname}
                                    type={'text'}
                                    width={"48%"}
                                    required={true}
                                    autoComplete={"given-name"}
                                />
                                <Customsectiontextinput
                                    fieldname={'Last Name'}
                                    value={lname}
                                    onChange={setlname}
                                    autoComplete={'family-name'}
                                    type={'text'}
                                    width={"48%"}
                                    required={true}
                                />
                            </Stack>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                {!invitationCode &&
                                    <Customsectiontextinput
                                        fieldname={'Business Email'}
                                        value={email}
                                        onChange={setemail}
                                        type={'email'}
                                        required={true}
                                        autoComplete={'email'}
                                        width={"48%"}
                                    />
                                }
                                <Customsectiontextinput
                                    fieldname={'Password'}
                                    value={password}
                                    onChange={setpassword}
                                    required={true}
                                    width={"48%"}
                                    type={showPassword ? 'text' : 'password'}
                                    inputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setshowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        ),
                                    }}
                                    helperText={!isValidPass && password && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                                />
                            </Stack>
                            <br />
                            <Button type="submit" variant="contained" fullWidth>Start Trial</Button>
                            <Typography mt={1} fontSize={"12px"} fontFamily={"lightfont"} fontWeight={"100"}>
                                By signing up, you agree to our <Link>Data Privacy Policy </Link>  and <Link>Terms & Conditions </Link>
                            </Typography>
                        </Box>
                    </Container>
                </Box>
                <Box display={{ lg: "block", sm: "none", xs: "none" }} sx={{ backgroundColor: '#161616', width: "50%", height: "100vh" }}>
                    <img className="testimonyimage" src={require('./../../assets/images/testimony.png')} />
                    <div className="testimonytext">
                        "Working with Kontorva has been <br />
                        a game-changer.The experience<br />
                        has been nothing short<br />
                        of transformative
                    </div>
                </Box>
            </Stack>
        </Box>
    );
};

export default Clientsignup;



